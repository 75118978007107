import actionTypes, {
  resetPasswordRequestAction,
  resetPasswordPendingAction,
  resetPasswordSeccessAction,
  resetPasswordClearAction,
  resetPasswordErrorAction
} from './types';

const resetPasswordRequest: resetPasswordRequestAction = (password, token, uid) => ({
  type: actionTypes.RESET_PASSWORD_REQUEST,
  password,
  token,
  uid
});

const resetPasswordPending: resetPasswordPendingAction = () => ({
  type: actionTypes.RESET_PASSWORD_REQUEST_PENDING
});
const resetPasswordSeccess: resetPasswordSeccessAction = () => ({
  type: actionTypes.RESET_PASSWORD_REQUEST_SUCCESS
});
const resetPasswordClear: resetPasswordClearAction = () => ({
  type: actionTypes.RESET_PASSWORD_REQUEST_CLEAR
});
const resetPasswordError: resetPasswordErrorAction = (error) => ({
  type: actionTypes.RESET_PASSWORD_REQUEST_ERROR,
  error
});
export default {
  resetPasswordRequest,
  resetPasswordPending,
  resetPasswordSeccess,
  resetPasswordClear,
  resetPasswordError
};
