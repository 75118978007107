import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { routes } from 'main/router';
import useLocal from 'localization';
import useStyles from './style';
import CancelIcon from '@material-ui/icons/Cancel';
import { Box } from '@material-ui/core';
import useGetParameter from 'components/Popup/hooks/useGetParameter';
import confirmationMail from 'assets/confirmationMail.svg';
import { useNavigate } from 'react-router';

const RegisterThankYouPopup: React.FC = () => {
  const navigate = useNavigate();

  const signupForFree = useLocal('signup_for_free');
  const backToLogin = useLocal('back_to_login');
  const thankYouContent1 = useLocal('thank_you_content_1');
  const thankYouContent2 = useLocal('thank_you_content_2');

  const email = useGetParameter('email');

  const classes = useStyles();

  return (
    <form>
      <Dialog maxWidth="xs" open={true} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography className={classes.title} id="form-dialog-title">
          <h2>{signupForFree}</h2>
          <IconButton data-testid="api-settings-close-btn" onClick={() => navigate(routes.LOGIN)}>
            <CancelIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Box>
            <img src={confirmationMail} alt={confirmationMail} />
          </Box>

          <Box className={classes.messagesWrap}>
            <Typography color="textPrimary">{thankYouContent1}</Typography>
            <Typography color="primary">{email}</Typography>
            <Typography color="textPrimary">{thankYouContent2}</Typography>
          </Box>

          <DialogActions className={classes.dialogActions}>
            <Button
              type="submit"
              color="primary"
              data-testid="login-submit"
              variant="outlined"
              onClick={() => navigate(routes.LOGIN)}
            >
              {backToLogin}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default RegisterThankYouPopup;
