import actionTypes, {
  signupTrialAction,
  signupTrialPendingAction,
  signupTrialErrorAction,
  signupTrialSuccessAction
} from './types';

const signupTrial: signupTrialAction = (email, password, hashcode) => ({
  type: actionTypes.SIGNUP_TRIAL,
  email,
  password,
  hashcode
});

const signupTrialPending: signupTrialPendingAction = () => ({
  type: actionTypes.SIGNUP_TRIAL_PENDING
});

const signupTrialError: signupTrialErrorAction = (msg) => ({
  type: actionTypes.SIGNUP_TRIAL_ERROR,
  msg
});

const signupTrialSuccess: signupTrialSuccessAction = (msg) => ({
  type: actionTypes.SIGNUP_TRIAL_SUCCESS,
  msg
});

export default {
  signupTrial,
  signupTrialPending,
  signupTrialError,
  signupTrialSuccess
};
