import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import useLocal from 'localization';
import Props from './types';
import useStyles from './style';

const AddNewModelPopup: React.FC<Props> = ({
  isOpened,
  hasError,
  error,
  isLoading,
  onSubmit,
  onClose
}: Props) => {
  const classes = useStyles();

  const addNewModelTitle = useLocal('add_new_model_title');
  const addNewModelMsg = useLocal('add_new_model_mag');
  const tickerNameFieldLabel = useLocal('ticker_name_label');

  const companyNameFieldLabel = useLocal('company_name_label');
  const submitBtnLabel = useLocal('submit_btn_label');

  const [ticker, setTicker] = useState('');
  const [companyName, setCompanyName] = useState('');

  return (
    <>
      <Dialog
        fullWidth
        open={isOpened}
        onClose={onClose}
        data-testid="add-new-model-popup"
        maxWidth="sm"
      >
        <DialogTitle disableTypography className={classes.title}>
          <h2>{addNewModelTitle}</h2>
          <IconButton onClick={onClose} data-testid="add-new-model-close-btn">
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.contant}>
          {hasError && (
            <Alert data-testid="add-new-model-error" severity="error">
              {error}
            </Alert>
          )}
          <p>{addNewModelMsg}</p>
          <p>
            <TextField
              fullWidth
              label={tickerNameFieldLabel}
              value={ticker}
              data-testid="company-ticket-input"
              onChange={(e) => setTicker(e.target.value.toUpperCase())}
            />
          </p>
          <p>
            <TextField
              fullWidth
              data-testid="company-name-input"
              label={companyNameFieldLabel}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </p>

          <DialogActions className={classes.action}>
            <Button
              size="large"
              color="primary"
              variant="contained"
              data-testid="add-new-model-submit"
              disabled={!ticker.length || !companyName.length || isLoading}
              onClick={() => onSubmit(ticker, companyName)}
            >
              {submitBtnLabel}
              {isLoading && <CircularProgress size={24} />}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(AddNewModelPopup);
