const actionTypes = {
  GENERATE_DOWNLOAD_MSI_TOKEN: 'marketplace/ExcelPlugin/GENERATE_DOWNLOAD_MSI_TOKEN',
  GENERATE_DOWNLOAD_MSI_TOKEN_PENDING:
    'marketplace/ExcelPlugin/GENERATE_DOWNLOAD_MSI_TOKEN_PENDING',
  GENERATE_DOWNLOAD_MSI_TOKEN_SUCCESS:
    'marketplace/ExcelPlugin/GENERATE_DOWNLOAD_MSI_TOKEN_SUCCESS',
  GENERATE_DOWNLOAD_MSI_TOKEN_ERROR: 'marketplace/ExcelPlugin/GENERATE_DOWNLOAD_MSI_TOKEN_ERROR',

  GENERATE_DOWNLOAD_EXE_TOKEN: 'marketplace/ExcelPlugin/GENERATE_DOWNLOAD_EXE_TOKEN',
  GENERATE_DOWNLOAD_EXE_TOKEN_PENDING:
    'marketplace/ExcelPlugin/GENERATE_DOWNLOAD_EXE_TOKEN_PENDING',
  GENERATE_DOWNLOAD_EXE_TOKEN_SUCCESS:
    'marketplace/ExcelPlugin/GENERATE_DOWNLOAD_EXE_TOKEN_SUCCESS',
  GENERATE_DOWNLOAD_EXE_TOKEN_ERROR: 'marketplace/ExcelPlugin/GENERATE_DOWNLOAD_EXE_TOKEN_ERROR',

  GENERATE_EXCEL_VERSION: 'marketplace/ExcelPlugin/GENERATE_EXCEL_VERSION',
  GENERATE_EXCEL_VERSION_SUCCESS: 'marketplace/ExcelPlugin/GENERATE_EXCEL_VERSION_SUCCESS'
};
export type ExcelPluginProps = {
  isOpened: boolean;
  onClose: () => void;
};
export type ExcelPluginState = {
  //Add reducer state type here
  isLoadingMsi: boolean;
  isLoadingExe: boolean;
  isError: boolean;
  error: string | null;
  excelVersion: string | null;
  isSuccessMsi: boolean;
  isSuccessExe: boolean;
};

export type getDownloadMsiTokenAction = (
  excelVersion: string | null
) => {
  type: typeof actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN;
  excelVersion: string | null;
};
export type getDownloadMsiTokenPendingAction = () => {
  type: typeof actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN_PENDING;
};
export type getDownloadMsiTokenSuccessAction = () => {
  type: typeof actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN_SUCCESS;
};
export type getDownloadMsiTokenErrorAction = (
  error: string
) => {
  type: typeof actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN_ERROR;
  error: string;
};

export type getDownloadExeTokenAction = (
  excelVersion: string | null
) => {
  type: typeof actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN;
  excelVersion: string | null;
};
export type getDownloadExeTokenPendingAction = () => {
  type: typeof actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN_PENDING;
};
export type getDownloadExeTokenSuccessAction = () => {
  type: typeof actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN_SUCCESS;
};
export type getDownloadExeTokenErrorAction = (
  error: string
) => {
  type: typeof actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN_ERROR;
  error: string;
};
export type getExcelVersion = () => {
  type: typeof actionTypes.GENERATE_EXCEL_VERSION;
};
export type getExcelVersionSuccess = (
  version: string
) => {
  type: typeof actionTypes.GENERATE_EXCEL_VERSION_SUCCESS;
  version: string;
};
export type actionCreatorTypes = {
  type: string;
  error?: string;
  version?: string;
};

export default actionTypes;
