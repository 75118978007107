import { makeStyles, Theme } from '@material-ui/core/styles';
import { styled } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  iconLink: {
    minWidth: '20px'
  },
  container: {
    [theme.breakpoints.down('xl')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2em'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '5em'
    }
  }
}));

export const Wrapper = styled('div')<{ v2BannerClosed: boolean }>(({ theme, v2BannerClosed }) => ({
  paddingTop: v2BannerClosed ? '152px' : '192px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: v2BannerClosed ? '136px' : '176px'
  }
}));
