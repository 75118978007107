import React from 'react';
import { authState } from 'store/slices';
import { useAppSelector } from 'store/hooks';
import Login from 'pages/Login';
import { HomePagePlaceholder, UserBanner } from './components';
import { Container, Wrapper, SplashText, AuthorizedContainer } from './HomePage.styles';
import { ModelsTable } from 'components/ModelsTableV2/ModelsTable';

export const HomePage = () => {
  const { isLoggedIn } = useAppSelector(authState);

  if (!isLoggedIn) {
    return (
      <Container>
        <Wrapper>
          <HomePagePlaceholder />
          <SplashText>
            Once you login, you&apos;ll be able to view all your followed Data Sheets here and see
            their earnings update status. We&apos;ll notify you when an earnings update starts and
            completes.
            <br />
            Discover and follow Data Sheets by searching!
          </SplashText>
        </Wrapper>
        <Login useDialog={false} />
      </Container>
    );
  }
  return (
    <AuthorizedContainer>
      <UserBanner />
      <ModelsTable subscribedOnly />
    </AuthorizedContainer>
  );
};

export default HomePage;
