import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { init } from 'commandbar';
import { gqlClient } from 'infra/http';
import { router, routerV1 } from 'main/router';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { generalState } from 'store/slices';
import { makeStore } from 'store';

init('3b514a4d');

const DATA_GRID_LICENSE = process.env.REACT_APP_DATA_GRID_LICENSE;
if (DATA_GRID_LICENSE) {
  LicenseInfo.setLicenseKey(DATA_GRID_LICENSE);
}

// TODO
// Needed until we remove the fallback to V1
// After removing the fallback, we can remove the routerV1 and this logic
const AppInner = () => {
  const {
    featureFlags: { isMarketplaceV2Enabled }
  } = useAppSelector(generalState);

  const versionedRouter = isMarketplaceV2Enabled ? router : routerV1;

  return <RouterProvider router={versionedRouter} />;
};

export const App = () => {
  const store = makeStore();
  return (
    <React.StrictMode>
      <Suspense fallback={<CircularProgress size={24} style={{ margin: '48px auto' }} />}>
        <Provider store={store}>
          <ApolloProvider client={gqlClient}>
            <AppInner />
          </ApolloProvider>
        </Provider>
      </Suspense>
    </React.StrictMode>
  );
};
