import React from 'react';
import { USE_SSO_LOGIN } from 'core/modules/auth.utils';
import { routes } from '../routes';
import { RouteObject } from 'react-router';
import SignUp from 'pages/SignUp/SignUp';
import Login from 'pages/Login';
import ThankyouVerifyEmail from 'pages/ThankyouVerifyEmail/ThankyouVerifyEmail';
import ForgetPassword from 'pages/ForgetPassword/ForgetPassword';
import ResetPassword from 'pages/ResetPassword/ResetPassword';

export const authRoutes: RouteObject[] = USE_SSO_LOGIN
  ? []
  : [
      {
        path: routes.SIGN_UP,
        element: <SignUp />
      },
      {
        path: routes.LOGIN,
        element: <Login />
      },
      {
        path: routes.VERIFY_EMAIL,
        element: <ThankyouVerifyEmail />
      },
      {
        path: routes.FORGET_PASSWORD,
        element: <ForgetPassword />
      },
      {
        path: routes.RESET_PASSWORD,
        element: <ResetPassword />
      }
    ];
