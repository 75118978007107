const actionTypes = {
  LOGIN_REQUEST: 'marketplace/LoginPage/LOGIN_REQUEST',
  LOGOUT: 'marketplace/LoginPage/LOGOUT',
  LOGIN_SUCCESS: 'marketplace/LoginPage/LOGIN_SUCCESS',
  LOGIN_ERORR: 'marketplace/LoginPage/LOGIN_ERORR',
  LOGIN_REQUEST_PENDING: 'marketplace/LoginPage/LOGIN_REQUEST_PENDING',
  ADD_ACTION_QUEUE: 'marketplace/LoginPage/ADD_ACTION_QUEUE',
  CLEAR_LOGIN_STATE: 'marketplace/LoginPage/CLEAR_LOGIN_STATE',

  RESEND_EMAIL_REQUEST: 'marketplace/LoginPage/RESEND_EMAIL_REQUEST',
  RESEND_EMAIL_PENDING: 'marketplace/LoginPage/RESEND_EMAIL_PENDING',
  RESEND_EMAIL_ERROR: 'marketplace/LoginPage/RESEND_EMAIL_ERROR',
  RESEND_EMAIL_SUCCESS: 'marketplace/LoginPage/RESEND_EMAIL_SUCCESS',
  SET_SHOULD_RESET_PASSWORD: 'marketplace/LoginPage/SET_SHOULD_RESET_PASSWORD'
};

export type actionQueueType = null | {
  type: string;
  payload: {
    [key: string]: string | number;
  };
};

export type LoginPageProps = {
  // Add Props type here
};
export type LoginPageState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  erorr: string | null;
  showReSendEmail: boolean;
  shouldResetPassword: boolean;
  actionQueue: actionQueueType;
  data: {
    // add data types here
    refreshToken?: string;
    token?: string;
  } | null;
  isLoggedIn: boolean;
};
export type LoginRequestActionCreatorType = {
  type: typeof actionTypes.LOGIN_REQUEST;
  email: string;
  password: string;
  redirectTo?: string | null;
};
export type LoginPendingActionCreatorType = {
  type: typeof actionTypes.LOGIN_REQUEST_PENDING;
};

export type LoginErrorActionCreatorType = {
  type: typeof actionTypes.LOGIN_ERORR;
  error: string;
  showReSendEmail?: boolean;
  shouldResetPassword?: boolean;
};

export type LoginSuccessActionCreatorType = {
  type: typeof actionTypes.LOGIN_SUCCESS;
  payload: {
    refreshToken: string;
    token: string;
    email: string;
  };
};
export type addActionQueueAction = (
  actionToQueue: actionQueueType
) => {
  type: typeof actionTypes.ADD_ACTION_QUEUE;
  actionToQueue: actionQueueType;
};

export type LoginResponseType = {
  data: {
    tokenAuth: {
      refreshToken: string;
      token: string;
    } | null;
  };
  errors:
    | [
        {
          message: string;
          locations: [];
          path: [];
        }
      ]
    | null;
};

export type LogoutActionCreatorType = {
  type: typeof actionTypes.LOGOUT;
};

export type clearLoginStateActionType = {
  type: typeof actionTypes.CLEAR_LOGIN_STATE;
};

export type loginFormField = {
  email: string;
  password: string;
  redirectTo?: string | null;
};

export type actionCreatorTypes = {
  type: string;
  actionToQueue?: actionQueueType;

  payload?: {
    token: string;
    refreshToken: string;
    email: string;
  };
  loginFormField?: loginFormField;
  error?: string;
  showReSendEmail?: boolean;
  shouldResetPassword?: boolean;
};

// Resend Email

export type ResendEmailRequestActionCreatorType = {
  type: typeof actionTypes.RESEND_EMAIL_REQUEST;
  email: string;
};
export type ResendEmailResponseType = {
  data: { resendVerificationEmail: { success: string; errors: string[] } };
  errors:
    | [
        {
          message: string;
          locations: [];
          path: [];
        }
      ]
    | null;
};

export type RequestEmailPendingActionCreatorType = {
  type: typeof actionTypes.RESEND_EMAIL_PENDING;
};

export type RequestEmailSuccessActionCreatorType = {
  type: typeof actionTypes.RESEND_EMAIL_SUCCESS;
};

export type SetShouldResetPassword = {
  type: typeof actionTypes.SET_SHOULD_RESET_PASSWORD;
  shouldResetPassword: boolean;
};

export type ResendEmailErrorActionCreatorType = {
  type: typeof actionTypes.LOGIN_ERORR;
  error: string;
};
export default actionTypes;
