import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApiSettingsSelectors, actions } from './duck';
import { ApiSettingsTypes } from './duck';

import ApiSettingsPopup from 'components/ApiSettingsPopup';

const ApiSettings: React.FC<ApiSettingsTypes.ApiSettingsProps> = ({
  isOpened,
  onClose
}: ApiSettingsTypes.ApiSettingsProps) => {
  const dispatch = useDispatch();

  const hasError = useSelector(ApiSettingsSelectors.hasError);
  const error = useSelector(ApiSettingsSelectors.Error);
  const isLoading = useSelector(ApiSettingsSelectors.isLoading);
  const { user, apiKeyById } = useSelector(ApiSettingsSelectors.getApiSettingsData);

  useEffect(() => {
    dispatch(actions.getApiSettings());
  }, [dispatch]);

  return (
    <ApiSettingsPopup
      isOpened={isOpened}
      hasError={hasError}
      error={error}
      isLoading={isLoading}
      user={user}
      apiKeyById={apiKeyById}
      onClose={onClose}
    />
  );
};

export default ApiSettings;
