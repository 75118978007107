import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    background: theme.palette.headingBG.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'lighter',
    paddingTop: 0,
    paddingBottom: 0
  },
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: '100%'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  dialogActions: {
    marginBottom: theme.spacing(1),
    padding: 0,
    justifyContent: 'flex-start'
  }
}));
