import actionTypes, { ModelsState, actionCreatorTypes, updateNotification } from './types';
import { allModelId } from 'components/ModelSidebar/types';
import { cloneDeep } from '@apollo/client/utilities';

const initialState: ModelsState = {
  isSectorLoading: false,
  isSectorError: false,
  sectorError: null,
  sectors: null,
  seletedSectorId: allModelId,
  isModelsLoading: false,
  modelsError: null,
  isModelsError: false,
  modelList: null,
  isUpdating: false,
  totalNumberOfModels: null,
  isIndustryModelLoading: null,
  totalNumberOfIndustries: 0,
  myModel: null,
  subscriptionPending: null,
  stateName: null,
  isUnsubscribeLoading: false,
  isUnsubscribeError: false,
  unsubscrbieError: null,
  selectedCompany: null,
  unsubscribed: false,
  companyUpdateNotifications: {},
  isUpdatedFundamentalsGroupsLoading: false,
  updatedFundamentalGroups: { document: null, fundamentalGroups: [] },
  notificationCompany: null,
  updatedFundamentalsByGroupId: {},
  updatedFundamentalsByGroupIdLoading: {},
  earningUpdateNotificationsConfig: null,
  isUpdateNotificationsLoading: true,
  downloadingModelType: null,
  isMarkingAllNotificationsLoading: false,
  markingNotificationSeenCompanyId: null,
  downloadingModelId: null
};

const reducer = (state: ModelsState = initialState, action: actionCreatorTypes): ModelsState => {
  const caseName = `actionTypes.DOWNLOAD_MODELS_${action.modelStatus}`;

  switch (action.type) {
    case actionTypes.GET_SECTORS_PENDING:
      return {
        ...state,
        isSectorLoading: true
      };
    case actionTypes.GET_SECTORS_ERROR:
      return {
        ...state,
        isSectorLoading: false,
        sectorError: action.error ? action.error : null,
        isSectorError: true
      };
    case actionTypes.SET_SECTORS:
      return {
        ...state,
        isSectorLoading: false,
        isSectorError: false,
        sectorError: null,
        sectors: action.sectors?.sectorList ?? null,
        totalNumberOfModels: action?.sectors?.totalNumberOfModels ?? null
      };
    case actionTypes.SET_SELECTED_SECTOR:
      return {
        ...state,
        seletedSectorId: action.sectorId ? action.sectorId : allModelId
      };

    case actionTypes.GET_MODELS_PENDING:
      return {
        ...state,
        isModelsLoading: true
      };
    case actionTypes.UPDATE_MODEL_PENDING:
      return {
        ...state,
        isUpdating: true
      };
    case actionTypes.GET_MODELS_ERROR:
      return {
        ...state,
        isModelsLoading: false,
        modelsError: action.error ? action.error : null,
        isModelsError: true
      };
    case actionTypes.SET_MODELS:
      return {
        ...state,
        isModelsLoading: false,
        isModelsError: false,
        modelsError: null,
        modelList: action.models ?? null,
        totalNumberOfIndustries: action.totalNumberOfIndustries ?? 0
      };
    case actionTypes.SUBSCRIPTION_PENDING:
      return {
        ...state,
        subscriptionPending: action.modelId ?? null
      };
    case actionTypes.UNSUBSCRIBE_PENDING:
      return {
        ...state,
        isUnsubscribeLoading: true
      };
    case actionTypes.UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        isUnsubscribeLoading: false,
        isUnsubscribeError: false,
        unsubscrbieError: null,
        unsubscribed: true
      };
    case actionTypes.UNSUBSCRIBE_ERROR:
      return {
        ...state,
        isUnsubscribeLoading: false,
        isUnsubscribeError: true,
        unsubscrbieError: action.error ?? null,
        unsubscribed: false
      };
    case actionTypes.SELECT_COMPANY:
      return {
        ...state,
        selectedCompany: action.company ?? null
      };
    case actionTypes.SET_MY_MODELS:
      return {
        ...state,
        isModelsLoading: false,
        isModelsError: false,
        modelsError: null,
        myModel: action.clientModels ?? null
      };
    case actionTypes.GET_MODELS_FROM_INDUSTRY_PENDING:
      return {
        ...state,
        isIndustryModelLoading: action?.industryIdLoadMore ?? null
      };
    case actionTypes.SET_MODELS_FROM_INDUSTRY:
      return {
        ...state,
        isIndustryModelLoading: null,
        modelList: action.models ?? null
      };
    case actionTypes.UPDATE_MODELS:
      return {
        ...state,
        isModelsLoading: false,
        isModelsError: false,
        modelsError: null,
        isUpdating: false,
        modelList: action.models
          ? state.modelList
            ? [...state.modelList, ...action.models]
            : action.models
          : null
      };
    case actionTypes.RESET:
      return {
        ...state,
        isUnsubscribeLoading: false,
        isUnsubscribeError: false,
        unsubscrbieError: null,
        unsubscribed: false
      };
    case actionTypes.GET_UPDATE_NOTIFICATIONS_SUCCESS:
      let companyUpdateNotifications: { [key: string]: updateNotification } = {};
      if (action.updateNotifications) {
        companyUpdateNotifications = action.updateNotifications.reduce(
          (collector: { [key: string]: updateNotification }, notification) => {
            collector[notification.company.id] = notification;
            return collector;
          },
          {}
        );
      }
      return {
        ...state,
        companyUpdateNotifications,
        isUpdateNotificationsLoading: false
      };
    case actionTypes.GET_UPDATE_NOTIFICATIONS_PENDING:
      return {
        ...state,
        isUpdateNotificationsLoading: true
      };
    case actionTypes.GET_UPDATE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        isUpdateNotificationsLoading: false
      };
    case actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS_PENDING:
      return {
        ...state,
        isUpdatedFundamentalsGroupsLoading: true
      };
    case actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS_SUCCESS:
      return {
        ...state,
        updatedFundamentalGroups: {
          fundamentalGroups: action.updatedFundamentalGroups?.fundamentalGroups ?? [],
          document: action.updatedFundamentalGroups?.document ?? null
        },
        isUpdatedFundamentalsGroupsLoading: false
      };
    case actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS_ERROR:
      return {
        ...state,
        isUpdatedFundamentalsGroupsLoading: false
      };
    case actionTypes.SELECT_NOTIFICATION_COMPANY:
      return { ...state, notificationCompany: action.company ?? null };

    case actionTypes.GET_UPDATED_FUNDAMENTALS_PENDING:
      return {
        ...state,
        updatedFundamentalsByGroupId: {
          ...state.updatedFundamentalsByGroupId,
          [action.groupId]: []
        },
        updatedFundamentalsByGroupIdLoading: {
          ...state.updatedFundamentalsByGroupIdLoading,
          [action.groupId]: true
        }
      };
    case actionTypes.GET_UPDATED_FUNDAMENTALS_SUCCESS:
      return {
        ...state,
        updatedFundamentalsByGroupId: {
          ...state.updatedFundamentalsByGroupId,
          [action.groupId]: action.updatedFundamentals ?? []
        },
        updatedFundamentalsByGroupIdLoading: {
          ...state.updatedFundamentalsByGroupIdLoading,
          [action.groupId]: false
        }
      };
    case actionTypes.GET_UPDATED_FUNDAMENTALS_ERROR:
      return {
        ...state,
        updatedFundamentalsByGroupId: {
          ...state.updatedFundamentalsByGroupId,
          [action.groupId]: []
        },
        updatedFundamentalsByGroupIdLoading: {
          ...state.updatedFundamentalsByGroupIdLoading,
          [action.groupId]: false
        }
      };
    case actionTypes.SET_EARNING_UPDATE_NOTIFICATION_CONFIG:
      return {
        ...state,
        earningUpdateNotificationsConfig: action.config
      };

    case actionTypes.MARK_ALL_NOTIFICATIONS_SEEN_PENDING:
      return { ...state, isMarkingAllNotificationsLoading: true };
    case actionTypes.MARK_ALL_NOTIFICATIONS_SEEN_ERROR:
      return { ...state, isMarkingAllNotificationsLoading: false };
    case actionTypes.MARK_ALL_NOTIFICATIONS_SEEN_SUCCESS:
      const newCompanyUpdateNotifications: { [key: string]: updateNotification } = {};
      Object.keys(state.companyUpdateNotifications).forEach((companyId) => {
        newCompanyUpdateNotifications[companyId] = {
          ...state.companyUpdateNotifications[companyId],
          seen: true
        };
      });
      return {
        ...state,
        isMarkingAllNotificationsLoading: false,
        companyUpdateNotifications: newCompanyUpdateNotifications
      };

    case actionTypes.MARK_COMPANY_NOTIFICATION_SEEN_PENDING:
      return { ...state, markingNotificationSeenCompanyId: action.companyId };
    case actionTypes.MARK_COMPANY_NOTIFICATION_SEEN_ERROR:
      return { ...state, markingNotificationSeenCompanyId: null };
    case actionTypes.MARK_COMPANY_NOTIFICATION_SEEN_SUCCESS:
      const companyNotifications = cloneDeep(state.companyUpdateNotifications);
      if (action.companyId) {
        companyNotifications[action.companyId] = {
          ...companyNotifications[action.companyId],
          seen: true
        };
      }
      return {
        ...state,
        markingNotificationSeenCompanyId: null,
        companyUpdateNotifications: companyNotifications
      };
    case caseName:
      return {
        ...state,
        stateName: `${action.modelStatus}`,
        downloadingModelType: action.modelType,
        downloadingModelId: action.modelId ?? null
      };

    default:
      return state;
  }
};

export default reducer;
