import { createSelector } from 'reselect';

import { SignUpState } from './types';
import { RootState } from 'store';

const getSignUpState = (state: RootState): SignUpState => state.SignUpReducer;

const hasError = createSelector(
  [getSignUpState],
  (SignUpState: SignUpState): boolean => SignUpState.isError
);
const getMsg = createSelector(
  [getSignUpState],
  (SignUpState: SignUpState): string | null => SignUpState.msg
);

const isSuccess = createSelector(
  [getSignUpState],
  (SignUpState: SignUpState): boolean => SignUpState.success
);
const isLoading = createSelector(
  [getSignUpState],
  (SignUpState: SignUpState): boolean => SignUpState.isLoading
);

export default {
  hasError,
  getMsg,
  isLoading,
  isSuccess
};
