import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  title: {
    background: theme.palette.headingBG.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'lighter',
    paddingTop: 0,
    paddingBottom: 0
  },
  annualHeading: {
    fontSize: '1.3em',
    fontWeight: 500,
    lineHeight: '1em',
    marginBottom: theme.spacing(1)
  },
  rightBoxWrapper: {
    padding: theme.spacing(2),
    border: '1px solid',
    borderColor: theme.palette.border.main,
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  contant: {
    padding: theme.spacing(4)
  },
  buttons: {
    marginRight: '1em'
  },
  download: {
    background: theme.palette.success.main
  },
  unSubscribe: {
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.success.main,
      color: theme.palette.white.main,
      fontWeight: 'bold'
    }
  },
  action: {
    padding: 0,
    paddingTop: theme.spacing(3),
    justifyContent: 'center'
  },
  decoration: {
    textDecoration: 'none'
  },
  spinner: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    height: '1.8em !important',
    width: '1.8em !important'
  }
}));
