import React from 'react';

import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import Props from './types';
import useStyles from './style';

const SearchField: React.FC<Props> = ({ variant = 'outlined', ...rest }: Props) => {
  const classes = useStyles();

  return (
    <TextField
      variant={variant}
      {...rest}
      InputProps={{
        ...rest.InputProps,
        startAdornment: <SearchIcon className={classes.searchIcon} />
      }}
    />
  );
};

export default React.memo(SearchField);
