import { styled } from '@mui/material';

export const Container = styled('a')({
  display: 'flex',
  width: '100%',
  padding: '10px 30px 10px 10px',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#263B54',
  cursor: 'pointer',
  textDecoration: 'none',

  '& > button': {
    padding: 0,
    minWidth: 'fit-content',
    color: 'white',
    fontSize: '16px'
  }
});

export const MessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '&::after': {
    content: '"Click here to try the new Marketplace!"',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [theme.breakpoints.up('sm')]: {
      content:
        '"Click here to try the new Marketplace to track update statuses and see when the data sheet is ready!"'
    }
  }
}));
