import actionTypes, { ThankyouVerifyEmailState, actionCreatorTypes } from './types';

const initialState: ThankyouVerifyEmailState = {
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false
};

const reducer = (
  state: ThankyouVerifyEmailState = initialState,
  action: actionCreatorTypes
): ThankyouVerifyEmailState => {
  switch (action.type) {
    case actionTypes.VERIFY_EMAIL_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.VERIFY_EMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action?.error ?? null
      };
    case actionTypes.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: action?.error ?? null,
        isSuccess: true
      };
    default:
      return state;
  }
};

export default reducer;
