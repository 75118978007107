import { put, takeLatest } from 'redux-saga/effects';
import actionTypes, { resetPasswordRequestAction } from './types';
import actions from './actions';
import { gqlClient } from 'infra/http';
import { gql } from '@apollo/client';

export function* resetPasswordRequestSaga({
  password,
  uid,
  token
}: ReturnType<resetPasswordRequestAction>) {
  yield put(actions.resetPasswordPending());

  try {
    const {
      data: {
        resetUserPasswordConfirm: { success, errors }
      }
    } = yield gqlClient.mutate({
      mutation: gql`
        mutation resetUserPasswordWithToken($uid: String!, $token: String!, $password: String!) {
          resetUserPasswordConfirm(uid: $uid, token: $token, password: $password) {
            success
            errors
          }
        }
      `,
      variables: { password, uid, token }
    });

    if (success) {
      yield put(actions.resetPasswordSeccess());
    }
    if (errors) {
      yield put(actions.resetPasswordError(errors.join(',')));
    }
  } catch (error) {
    yield put(actions.resetPasswordError((error as { message: string })?.message));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.RESET_PASSWORD_REQUEST, resetPasswordRequestSaga);
}
