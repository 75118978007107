import React, { useState, useRef, useEffect } from 'react';
import Box from '@material-ui/core/Box';

import useLocal from 'localization';

import Props from './types';
import SearchField from 'components/SearchField';

const ModelSearch: React.FC<Props> = ({
  onSearchModel,
  deleyTime = 300,
  throttle,
  clearSearch,
  setClearSearch
}: Props) => {
  const modelSearchPlaceholders = useLocal('model_search_placeholders');

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [keyword, setKeyword] = useState('');

  const handleChange = (keyword: string) => {
    setKeyword(keyword);

    if (throttle) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        onSearchModel(keyword);
      }, deleyTime);
    } else {
      onSearchModel(keyword);
    }
  };

  useEffect(() => {
    if (clearSearch) {
      setKeyword('');
    }
  }, [clearSearch]);

  return (
    <Box flexGrow={1} data-testid="model-search">
      <SearchField
        fullWidth
        placeholder={modelSearchPlaceholders}
        variant="outlined"
        value={keyword}
        onFocus={() => setClearSearch(false)}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        autoFocus
      />
    </Box>
  );
};

export default React.memo(ModelSearch);
