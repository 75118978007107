import actionTypes, {
  verifyEmailActionType,
  verifyEmailPendingActionType,
  verifyEmailErrorActionType,
  verifyEmailSuccessActionType
} from './types';

const verifyEmail: verifyEmailActionType = (uid, token) => ({
  type: actionTypes.VERIFY_EMAIL,
  uid,
  token
});

const verifyEmailPending: verifyEmailPendingActionType = () => ({
  type: actionTypes.VERIFY_EMAIL_PENDING
});

const verifyEmailError: verifyEmailErrorActionType = (error) => ({
  type: actionTypes.VERIFY_EMAIL_ERROR,
  error
});

const verifyEmailSuccess: verifyEmailSuccessActionType = () => ({
  type: actionTypes.VERIFY_EMAIL_SUCCESS
});

export default {
  verifyEmail,
  verifyEmailPending,
  verifyEmailError,
  verifyEmailSuccess
};
