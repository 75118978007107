import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

const Demo: React.FC<{ isOpened: boolean; onClose: () => void }> = ({
  isOpened,
  onClose
}: {
  isOpened: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog maxWidth="xs" open={isOpened} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Demo title</DialogTitle>
      <IconButton onClick={onClose} data-testid="add-new-model-close-btn">
        <CancelIcon />
      </IconButton>
      <DialogContent>
        <TextField
          id="outlined-password-input"
          label="Name"
          type="text"
          autoComplete="current-password"
          variant="outlined"
          fullWidth
          style={{ width: '400px', marginBottom: '10px' }}
        />
        <TextField
          id="outlined-password-input"
          label="Address"
          type="text"
          autoComplete="current-password"
          variant="outlined"
          fullWidth
          style={{ width: '400px', marginBottom: '10px' }}
        />
        <TextField
          id="outlined-password-input"
          label="Phone"
          type="text"
          autoComplete="current-password"
          variant="outlined"
          fullWidth
          style={{ width: '400px', marginBottom: '10px' }}
        />

        <DialogActions>
          <Button type="submit" color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(Demo);
