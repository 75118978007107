import React, { forwardRef } from 'react';

export const LeftArrowIcon = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M25.3332 16.0001H6.6665M6.6665 16.0001L15.9998 25.3334M6.6665 16.0001L15.9998 6.66675"
        stroke="#1E1E1E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
);
