import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';

import useLocal from 'localization';

import ApiSettingsSkeleton from 'components/ApiSettingsSkeleton';

import Props from './types';
import useStyles from './style';

const ApiSettingsPopup: React.FC<Props> = ({
  isOpened,
  isLoading,
  hasError,
  error,
  user,
  apiKeyById,
  onClose
}: Props) => {
  const classes = useStyles();

  const apiSettingsTitle = useLocal('api_settings');
  const username = useLocal('username');
  const apiKey = useLocal('api_key');
  const noApiKey = useLocal('no_api_key');

  const clickHere = useLocal('click_here');
  const apiDocumentation = useLocal('api_documentation');
  return (
    <Dialog
      fullWidth
      open={isOpened}
      onClose={onClose}
      maxWidth="sm"
      data-testid="api-settings-popup"
    >
      <DialogTitle disableTypography className={classes.title}>
        <h2>{apiSettingsTitle}</h2>
        <IconButton data-testid="api-settings-close-btn" onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contant}>
        {hasError && (
          <Alert data-testid="api-settings-error" severity="error">
            {error}
          </Alert>
        )}
        {isLoading && <ApiSettingsSkeleton />}
        {!hasError &&
          !isLoading &&
          (user && apiKeyById ? (
            <>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel htmlFor="userid">{username}</InputLabel>
                <OutlinedInput
                  id="userid"
                  type="text"
                  data-testid="api-settings-userid"
                  value={user ?? ''}
                  labelWidth={75}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => navigator.clipboard.writeText(user ?? '')}
                        edge="end"
                      >
                        <FileCopyOutlined />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel htmlFor="apiKey">{apiKey}</InputLabel>
                <OutlinedInput
                  id="apiKey"
                  type="text"
                  data-testid="api-settings-apikey"
                  value={apiKeyById ?? ''}
                  labelWidth={75}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => navigator.clipboard.writeText(apiKeyById ?? '')}
                        edge="end"
                      >
                        <FileCopyOutlined />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <a target="_blank" rel="noreferrer" href="https://docs.daloopa.com/docs/api">
                {clickHere}
              </a>{' '}
              {apiDocumentation}
            </>
          ) : (
            <Alert severity="info">{noApiKey}</Alert>
          ))}
      </DialogContent>
    </Dialog>
  );
};

export default ApiSettingsPopup;
