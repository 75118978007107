const actionTypes = {
  REQUES_RESET_PASSWORD: 'marketplace/ForgetPassword/REQUES_RESET_PASSWORD',
  REQUES_RESET_PASSWORD_PENDING: 'marketplace/ForgetPassword/REQUES_RESET_PASSWORD_PENDING',
  REQUES_RESET_PASSWORD_SUCCESS: 'marketplace/ForgetPassword/REQUES_RESET_PASSWORD_SUCCESS',
  REQUES_RESET_PASSWORD_FAILURE: 'marketplace/ForgetPassword/REQUES_RESET_PASSWORD_FAILURE',
  REQUES_RESET_PASSWORD_ERROR: 'marketplace/ForgetPassword/REQUES_RESET_PASSWORD_ERROR',
  REQUES_RESET_PASSWORD_CLEAR: 'marketplace/ForgetPassword/REQUES_RESET_PASSWORD_CLEAR'
};

export type ForgetPasswordField = {
  email: string;
};

export type ForgetPasswordState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  success: boolean;
  failure: boolean;
};

export type requestResetPasswordAction = (
  email: string
) => {
  type: typeof actionTypes.REQUES_RESET_PASSWORD;
  email: string;
};

export type requestResetPasswordPendingAction = () => {
  type: typeof actionTypes.REQUES_RESET_PASSWORD_PENDING;
};

export type requestResetPasswordSuccessAction = () => {
  type: typeof actionTypes.REQUES_RESET_PASSWORD_SUCCESS;
};

export type requestResetPasswordFailureAction = () => {
  type: typeof actionTypes.REQUES_RESET_PASSWORD_FAILURE;
};

export type requestResetPasswordErrorAction = (
  error: string
) => {
  type: typeof actionTypes.REQUES_RESET_PASSWORD_ERROR;
  error: string;
};

export type requestResetPasswordClearAction = () => {
  type: typeof actionTypes.REQUES_RESET_PASSWORD_CLEAR;
};

export type actionCreatorTypes = {
  type: string;
  error?: string;
};

export default actionTypes;
