import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  pageContainer: {
    marginTop: 72,
    padding: theme.spacing(3),
    overflowY: 'auto',
    maxHeight: '100vh',
    height: '100vh'
  },
  tableWrapper: {
    maxWidth: window.innerWidth * 0.8,
    margin: 'auto',
    marginTop: 16
  },
  progressWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));
