import InquireHerePopupReducer from './reducer';
import InquireHerePopupSelectors from './selector';
import InquireHerePopupSaga from './saga';
import * as InquireHerePopupTypes from './types';
import actionTypes from './types';
import actions from './actions';

export {
  InquireHerePopupSelectors,
  InquireHerePopupSaga,
  InquireHerePopupTypes,
  actionTypes,
  actions
};

export default InquireHerePopupReducer;
