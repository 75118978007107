import React from 'react';
import { MenuItem, Select, SelectChangeEvent, SelectProps, styled } from '@mui/material';
import { MODEL_STATUS } from 'domain/models/client-view-models';

const StyledSelect = styled<(props: SelectProps<MODEL_STATUS>) => JSX.Element>(Select)({
  overflow: 'hidden',
  minWidth: '100px',

  '& .MuiSelect-select': {
    textTransform: 'uppercase',
    padding: '4px 32px 4px 10px',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '22px'
  }
});
const StyledMenuItem = styled(MenuItem)({
  margin: '0 8px',
  padding: '6px 8px',
  textTransform: 'uppercase',
  fontSize: '12px',
  fontWeight: 'bold',

  '&.Mui-selected': {
    backgroundColor: 'transparent'
  },
  '&:hover, &.Mui-focusVisible, &:hover.Mui-selected': {
    backgroundColor: '#F2F2F2'
  }
});

type Props = {
  value?: MODEL_STATUS;
  onChange: (value: MODEL_STATUS) => void;
};

export const ModelStatusSelect = ({ value, onChange }: Props) => {
  const handleChange = (event: SelectChangeEvent<MODEL_STATUS>) => {
    onChange(event.target.value as MODEL_STATUS);
  };

  const handleRenderValue = () => {
    if (!value) {
      return 'All update States';
    }
    return value;
  };

  return (
    <StyledSelect
      value={value ?? ''}
      onChange={handleChange}
      renderValue={handleRenderValue}
      displayEmpty
    >
      <StyledMenuItem value="">All update States</StyledMenuItem>
      {Object.values(MODEL_STATUS).map((status) => (
        <StyledMenuItem key={status} value={status}>
          {status}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};
