import actionTypes, {
  getApiSettingsAction,
  setApiSettingsLoadingAction,
  setApiSettingsErrorAction,
  setApiSettingsAction
} from './types';

const getApiSettings: getApiSettingsAction = () => ({
  type: actionTypes.GET_API_SETTINGS
});

const setApiSettingsLoading: setApiSettingsLoadingAction = () => ({
  type: actionTypes.SET_API_SETTINGS_LOADING
});
const setApiSettingsError: setApiSettingsErrorAction = (error) => ({
  type: actionTypes.SET_API_SETTINGS_ERROR,
  error
});
const setApiSettings: setApiSettingsAction = (user, apiKeyById) => ({
  type: actionTypes.SET_API_SETTINGS,
  user,
  apiKeyById
});

export default {
  getApiSettings,
  setApiSettingsLoading,
  setApiSettingsError,
  setApiSettings
};
