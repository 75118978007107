import React from 'react';

export const UnsubscribedIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00016 1.33325L10.0602 5.50659L14.6668 6.17992L11.3335 9.42659L12.1202 14.0133L8.00016 11.8466L3.88016 14.0133L4.66683 9.42659L1.3335 6.17992L5.94016 5.50659L8.00016 1.33325Z"
      stroke="#BDBDBD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
