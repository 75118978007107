import actionTypes, { UserDetailState, actionCreatorTypes } from './types';

const initialState: UserDetailState = {
  isLoading: false,
  isError: false,
  error: null,
  tier: null,
  maxSubscriptions: 0,
  allowedSectors: null,
  firstName: null,
  lastName: null,
  email: null,
  phoneNumber: null,
  subscribedCount: 0,
  receiveModelUpdateNotification: false
};

const reducer = (
  state: UserDetailState = initialState,
  action: actionCreatorTypes
): UserDetailState => {
  switch (action.type) {
    case actionTypes.SET_USER_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
        error: null,
        isError: false
      };

    case actionTypes.SET_USER_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error ?? null,
        isError: true
      };

    case actionTypes.SET_USER_DETAILS:
      return {
        ...state,
        ...(action.userDetail ?? {}),
        isLoading: false,
        error: null,
        isError: false
      };
    default:
      return state;
  }
};

export default reducer;
