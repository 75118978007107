import { styled } from '@mui/material';
import { MODEL_STATUS } from 'domain/models/client-view-models';
import React from 'react';

const Pill = styled('div')<{ background: string; color: string }>(({ background, color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px 10px',
  borderRadius: '63px',
  height: '28px',
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  color,
  background
}));

type Props = {
  status: MODEL_STATUS;
};

export const ModelStatusPill = ({ status }: Props) => {
  const background = {
    [MODEL_STATUS.READY_FOR_UPDATE]: '#00B3EE1E',
    [MODEL_STATUS.NO_PENDING_UPDATES]: '#EEEEEE',
    [MODEL_STATUS.IN_PROGRESS]: '#00C39633'
  }[status];
  const color = {
    [MODEL_STATUS.READY_FOR_UPDATE]: '#397194',
    [MODEL_STATUS.NO_PENDING_UPDATES]: '#000000DE',
    [MODEL_STATUS.IN_PROGRESS]: '#337975'
  }[status];

  return (
    <Pill background={background} color={color}>
      {status}
    </Pill>
  );
};
