import actionTypes, {
  getDownloadMsiTokenAction,
  getDownloadMsiTokenPendingAction,
  getDownloadMsiTokenSuccessAction,
  getDownloadMsiTokenErrorAction,
  getDownloadExeTokenAction,
  getDownloadExeTokenPendingAction,
  getDownloadExeTokenSuccessAction,
  getDownloadExeTokenErrorAction,
  getExcelVersion,
  getExcelVersionSuccess
} from './types';

const getDownloadMsiToken: getDownloadMsiTokenAction = (excelVersion) => ({
  type: actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN,
  excelVersion
});

const getDownloadMsiTokenPending: getDownloadMsiTokenPendingAction = () => ({
  type: actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN_PENDING
});

const getDownloadMsiTokenSuccess: getDownloadMsiTokenSuccessAction = () => ({
  type: actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN_SUCCESS
});

const getDownloadMsiTokenError: getDownloadMsiTokenErrorAction = (error) => ({
  type: actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN_ERROR,
  error
});

const getDownloadExeToken: getDownloadExeTokenAction = (excelVersion) => ({
  type: actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN,
  excelVersion
});

const getDownloadExeTokenPending: getDownloadExeTokenPendingAction = () => ({
  type: actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN_PENDING
});

const getDownloadExeTokenSuccess: getDownloadExeTokenSuccessAction = () => ({
  type: actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN_SUCCESS
});

const getDownloadExeTokenError: getDownloadExeTokenErrorAction = (error) => ({
  type: actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN_ERROR,
  error
});

const getExcelVersionLatest: getExcelVersion = () => ({
  type: actionTypes.GENERATE_EXCEL_VERSION
});

const getExcelVersionLatestSuccess: getExcelVersionSuccess = (version: string) => ({
  type: actionTypes.GENERATE_EXCEL_VERSION_SUCCESS,
  version
});

export default {
  getDownloadMsiToken,
  getDownloadMsiTokenPending,
  getDownloadMsiTokenSuccess,
  getDownloadMsiTokenError,

  getDownloadExeToken,
  getDownloadExeTokenPending,
  getDownloadExeTokenSuccess,
  getDownloadExeTokenError,

  getExcelVersionLatest,
  getExcelVersionLatestSuccess
};
