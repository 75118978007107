import { CheckedFilingType, FilingTypeFiltersProps } from './types';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Popover } from '@material-ui/core';
import useStyles from './style';
import { FILING_TYPES_OPTIONS } from './constants';

export const FilingTypeFilters = ({ onFilter }: FilingTypeFiltersProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [draftCheckedFilingTypes, setDraftCheckedFilingTypes] = useState<CheckedFilingType>({});
  const [finalCheckedFilingTypes, setFinalCheckedFilingTypes] = useState<CheckedFilingType>({});
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDraftCheckedFilingTypes(finalCheckedFilingTypes);
  };

  const clearCheckedFilingTypes = () => {
    setDraftCheckedFilingTypes(
      Object.keys(FILING_TYPES_OPTIONS).reduce((collector, filingType) => {
        collector[filingType] = false;
        return collector;
      }, {} as CheckedFilingType)
    );
  };

  useEffect(clearCheckedFilingTypes, []);

  const onChangeChecked = (filingType: string, isChecked: boolean) => {
    setDraftCheckedFilingTypes((prevCheckedFilingTypes) => ({
      ...prevCheckedFilingTypes,
      [filingType]: isChecked
    }));
  };

  const selectedFilingTypes = useMemo(() => {
    const values: string[] = [];
    Object.keys(draftCheckedFilingTypes)
      .filter((filingType) => draftCheckedFilingTypes[filingType])
      .forEach((filingType) => values.push(...FILING_TYPES_OPTIONS[filingType]));
    return values;
  }, [draftCheckedFilingTypes]);

  const selectedFilingTypeCount = useMemo(
    () =>
      Object.keys(draftCheckedFilingTypes).filter(
        (filingType) => draftCheckedFilingTypes[filingType]
      ).length,
    [draftCheckedFilingTypes]
  );

  const open = Boolean(anchorEl);

  const onApply = () => {
    setAnchorEl(null);
    setFinalCheckedFilingTypes(draftCheckedFilingTypes);
    onFilter(selectedFilingTypes);
  };

  return (
    <div>
      <Button
        variant="text"
        color={'primary'}
        onClick={handleClick}
        className={classes.filingTypeFilterButton}
      >
        Filing Types {selectedFilingTypeCount > 0 ? `(${selectedFilingTypeCount})` : ''}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box className={classes.filingTypeFilterContainer}>
          <Button
            variant="text"
            color={'primary'}
            onClick={clearCheckedFilingTypes}
            className={classes.filingTypeClearFilters}
          >
            Clear Filters
          </Button>
          <Box className={classes.filingTypeFormGroup}>
            {Object.entries(FILING_TYPES_OPTIONS).map(([label, value]) => (
              <FormControlLabel
                key={label}
                control={
                  <Checkbox
                    color={'primary'}
                    checked={draftCheckedFilingTypes[label]}
                    onChange={(event, checked) => onChangeChecked(label, checked)}
                  />
                }
                label={label}
              />
            ))}
          </Box>
          <Button variant="contained" color={'primary'} onClick={onApply}>
            Apply
          </Button>
        </Box>
      </Popover>
    </div>
  );
};
