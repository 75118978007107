import { HttpClient, HttpMethod } from 'application/protocols';
import { RequestResponse } from 'application/protocols/http-response/http-response';
import { error, success } from 'domain/either';
import { ServiceCommand } from 'domain/service-command';

export class UserIPValidation implements ServiceCommand<UserIPValidation.Response> {
  constructor(
    private readonly httpClient: HttpClient<UserIPValidation.Response>,
    private readonly url: string
  ) {}

  async execute(): Promise<ServiceCommand.Response<UserIPValidation.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.GET,
      url: this.url
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }

    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace UserIPValidation {
  export type Response = void;
}
