import actionTypes, {
  LoginRequestActionCreatorType,
  LoginPendingActionCreatorType,
  LoginErrorActionCreatorType,
  LoginSuccessActionCreatorType,
  LogoutActionCreatorType,
  addActionQueueAction,
  clearLoginStateActionType,
  ResendEmailRequestActionCreatorType,
  RequestEmailPendingActionCreatorType,
  ResendEmailErrorActionCreatorType,
  RequestEmailSuccessActionCreatorType,
  SetShouldResetPassword
} from './types';

const loginRequest = (
  email: string,
  password: string,
  redirectTo?: string | null
): LoginRequestActionCreatorType => ({
  type: actionTypes.LOGIN_REQUEST,
  email,
  password,
  redirectTo
});
const loginRequestPending = (): LoginPendingActionCreatorType => ({
  type: actionTypes.LOGIN_REQUEST_PENDING
});
const loginRequestErorr = (
  error: string,
  showReSendEmail?: boolean,
  shouldResetPassword?: boolean
): LoginErrorActionCreatorType => {
  return {
    type: actionTypes.LOGIN_ERORR,
    error,
    showReSendEmail,
    shouldResetPassword
  };
};
const addActionQueue: addActionQueueAction = (actionToQueue) => ({
  type: actionTypes.ADD_ACTION_QUEUE,
  actionToQueue
});

const loginRequestSuccess = (
  token: string,
  refreshToken: string,
  email: string
): LoginSuccessActionCreatorType => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: {
    token,
    refreshToken,
    email
  }
});

const logout = (): LogoutActionCreatorType => ({
  type: actionTypes.LOGOUT
});

const clearLoginState = (): clearLoginStateActionType => ({
  type: actionTypes.CLEAR_LOGIN_STATE
});

// Resend Email

const resendEmailRequest = (email: string): ResendEmailRequestActionCreatorType => ({
  type: actionTypes.RESEND_EMAIL_REQUEST,
  email
});

const resendEmailRequestPending = (): RequestEmailPendingActionCreatorType => ({
  type: actionTypes.RESEND_EMAIL_PENDING
});
const resendEmailRequestErorr = (error: string): ResendEmailErrorActionCreatorType => {
  return {
    type: actionTypes.RESEND_EMAIL_ERROR,
    error
  };
};
const resendEmailRequestSuccess = (): RequestEmailSuccessActionCreatorType => ({
  type: actionTypes.RESEND_EMAIL_SUCCESS
});

const setShouldResetPassword = (shouldResetPassword: boolean): SetShouldResetPassword => ({
  type: actionTypes.SET_SHOULD_RESET_PASSWORD,
  shouldResetPassword
});
export default {
  loginRequest,
  loginRequestPending,
  loginRequestErorr,
  loginRequestSuccess,
  logout,
  addActionQueue,
  clearLoginState,
  resendEmailRequest,
  resendEmailRequestPending,
  resendEmailRequestErorr,
  resendEmailRequestSuccess,
  setShouldResetPassword
};
