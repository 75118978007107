import React from 'react';

export const MenuCollapseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 18.7929V13.2071C5 12.7617 4.46143 12.5386 4.14645 12.8536L1.35355 15.6464C1.15829 15.8417 1.15829 16.1583 1.35355 16.3536L4.14645 19.1464C4.46143 19.4614 5 19.2383 5 18.7929Z"
      fill="#F2F2F2"
    />
    <path
      d="M11 9H26M11 16H26M11 23H26"
      stroke="#F2F2F2"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
