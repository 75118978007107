import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import RequestModelPopup from 'components/RequestModelPopup';

import { RequestModelSelectors } from './duck';
import { RequestModelTypes } from './duck';
import { actions } from './duck';
import Mixpanel from '../../mixPanel';

const RequestModel: React.FC<RequestModelTypes.RequestModelProps> = ({
  isOpened,
  onClose
}: RequestModelTypes.RequestModelProps) => {
  const dispatch = useDispatch();
  const hasError = useSelector(RequestModelSelectors.hasError);
  const error = useSelector(RequestModelSelectors.getError);
  const isLoading = useSelector(RequestModelSelectors.isLoading);
  const companyList = useSelector(RequestModelSelectors.companyList);
  const selectedCompanyId = useSelector(RequestModelSelectors.selectedCompanyId);

  useEffect(() => {
    Mixpanel.track('marketplace:request-model:view');
  });

  return (
    <RequestModelPopup
      isOpened={isOpened}
      hasError={hasError}
      error={error}
      isLoading={isLoading}
      companyList={companyList}
      searchCompany={(keyword) => dispatch(actions.getCompanyList(keyword))}
      onSelect={(id) => dispatch(actions.setSelectedCompanyId(id))}
      onSubmit={(id) => dispatch(actions.modelRequest(id))}
      selectedCompanyId={selectedCompanyId}
      onClose={onClose}
    />
  );
};

export default RequestModel;
