import { createSelector } from 'reselect';

import { ResetPasswordState } from './types';
import { RootState } from 'store';

const getResetPasswordState = (state: RootState): ResetPasswordState => state.ResetPasswordReducer;

const hasError = createSelector(
  [getResetPasswordState],
  (ResetPasswordState: ResetPasswordState): boolean => ResetPasswordState.isError
);
const getError = createSelector(
  [getResetPasswordState],
  (ResetPasswordState: ResetPasswordState): string | null => ResetPasswordState.error
);

const isLoading = createSelector(
  [getResetPasswordState],
  (ResetPasswordState: ResetPasswordState): boolean => ResetPasswordState.isLoading
);

const isSuccess = createSelector(
  [getResetPasswordState],
  (ResetPasswordState: ResetPasswordState): boolean => ResetPasswordState.success
);

export default {
  hasError,
  getError,
  isLoading,
  isSuccess
};
