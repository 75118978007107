import { createSelector } from 'reselect';

import { TagViewerState, FundamentalInfo, CompositeFundamentals } from './types';
import { RootState } from 'store';

const getTagViewerState = (state: RootState): TagViewerState => state.TagViewerReducer;

const hasError = createSelector(
  [getTagViewerState],
  (TagViewerState: TagViewerState): boolean => TagViewerState.isError
);
const getError = createSelector(
  [getTagViewerState],
  (TagViewerState: TagViewerState): string | null => TagViewerState.error
);

const isLoading = createSelector(
  [getTagViewerState],
  (TagViewerState: TagViewerState): boolean => TagViewerState.isLoading
);

const getFundamentlInfo = createSelector(
  [getTagViewerState],
  (TagViewerState: TagViewerState): FundamentalInfo | null => TagViewerState.fundamentalInfo
);

const getCompositeFundamental = createSelector(
  [getTagViewerState],
  (TagViewerState: TagViewerState): CompositeFundamentals | null =>
    TagViewerState.compositeFundamentals
);

export default {
  hasError,
  getError,
  isLoading,
  getFundamentlInfo,
  getCompositeFundamental
};
