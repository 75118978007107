import { Either, Error, Success } from 'domain/either';
import { HttpStatusCode } from '../http-client';

type Predicate = (value: HttpStatusCode) => boolean;

export type CombinedPredicated<E> = Array<[Predicate, E]>;

export const combinedPredicates = <E, S extends HttpStatusCode>({
  value,
  predicatePairs
}: {
  value: S;
  predicatePairs: CombinedPredicated<E>;
}): Either<E, S> => {
  for (const [verifier, error] of predicatePairs) {
    if (verifier(value)) {
      return new Error(error);
    }
  }

  return new Success(value);
};
