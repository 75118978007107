import React from 'react';

import Props from './types';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { createGenerateClassName } from '@material-ui/core/styles';
import { createTheme as createThemeV5 } from '@mui/material/styles';
import { DaloopaTheme } from 'ui-kit';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';

createGenerateClassName({
  disableGlobal: true,
  seed: 'mui-jss'
});

const themeV5 = createThemeV5({
  palette: {
    primary: DaloopaTheme.palette.primary,
    secondary: DaloopaTheme.palette.secondary,
    text: DaloopaTheme.palette.text
  }
});

const AppDataGrid: React.FC<Props> = (props: Props) => {
  return (
    <ThemeProviderV5 theme={themeV5}>
      <GlobalStyles
        styles={{
          body: { '-ms-overflow-style': 'none', 'scrollbar-width': 'none', 'overflow-y': 'scroll' },
          'body::-webkit-scrollbar': { display: 'none' }
        }}
      />
      <DataGridPro {...props} />
    </ThemeProviderV5>
  );
};

export default AppDataGrid;
