import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  contant: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  action: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  spinner: {
    color: 'white',
    height: '1.8em !important',
    width: '1.8em !important'
  }
}));
