import { put, takeLatest } from 'redux-saga/effects';
import actionTypes, { getDownloadExeTokenAction, getDownloadMsiTokenAction } from './types';
import actions from './actions';

import { getLocalValue } from 'localization';
import { gqlClient } from 'infra/http';
import { gql } from '@apollo/client';
import { selectState } from 'store/helpers';

export function* generateExcelVersion() {
  try {
    const {
      data: { getConfigurationSetting }
    } = yield gqlClient.query({
      query: gql`
        query getConfigurationSettingQuery($key: String!) {
          getConfigurationSetting(key: $key)
        }
      `,
      variables: { key: 'EXCEL_ADDIN_VERSION' },
      fetchPolicy: 'no-cache'
    });
    yield put(actions.getExcelVersionLatestSuccess(getConfigurationSetting));
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getDownloadExeTokenError(errorMsg));
  }
}

export function* generateDownloadMsiTokenSaga({
  excelVersion
}: ReturnType<getDownloadMsiTokenAction>) {
  yield put(actions.getDownloadMsiTokenPending());

  try {
    const {
      data: {
        generateExcelAddinToken: { token }
      }
    } = yield gqlClient.mutate({
      mutation: gql`
        mutation generateExcelAddinToken($name: String!, $version: String!, $fileFormat: String!) {
          generateExcelAddinToken(name: $name, version: $version, fileFormat: $fileFormat) {
            token
          }
        }
      `,
      variables: {
        name: 'excel_addin',
        version: excelVersion,
        fileFormat: 'msi'
      },
      fetchPolicy: 'no-cache'
    });

    if (token) {
      yield put(actions.getDownloadMsiTokenSuccess());
      yield window.location.assign(
        `${process.env.REACT_APP_BACKEND_URL}/excel_addin?x-signed-token=${token}`
      );
    }
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(
      actions.getDownloadMsiTokenError((error as { massage: string })?.massage ?? errorMsg)
    );
  }
}

export function* generateDownloadExeTokenSaga({
  excelVersion
}: ReturnType<getDownloadExeTokenAction>) {
  yield put(actions.getDownloadExeTokenPending());

  try {
    const {
      data: {
        generateExcelAddinToken: { token }
      }
    } = yield gqlClient.mutate({
      mutation: gql`
        mutation generateExcelAddinToken($name: String!, $version: String!, $fileFormat: String!) {
          generateExcelAddinToken(name: $name, version: $version, fileFormat: $fileFormat) {
            token
          }
        }
      `,
      variables: {
        name: 'excel_addin',
        version: excelVersion,
        fileFormat: 'exe'
      },
      fetchPolicy: 'no-cache'
    });

    if (token) {
      yield put(actions.getDownloadExeTokenSuccess());
      yield window.location.assign(
        `${process.env.REACT_APP_BACKEND_URL}/excel_addin?x-signed-token=${token}`
      );
    }
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(
      actions.getDownloadExeTokenError((error as { massage: string })?.massage ?? errorMsg)
    );
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN, generateDownloadMsiTokenSaga);
  yield takeLatest(actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN, generateDownloadExeTokenSaga);
  yield takeLatest(actionTypes.GENERATE_EXCEL_VERSION, generateExcelVersion);
}
