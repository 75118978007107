const actionTypes = {
  RESET_PASSWORD_REQUEST: 'marketplace/ResetPassword/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_REQUEST_PENDING: 'marketplace/ResetPassword/RESET_PASSWORD_REQUEST_PENDING',
  RESET_PASSWORD_REQUEST_ERROR: 'marketplace/ResetPassword/RESET_PASSWORD_REQUEST_ERROR',
  RESET_PASSWORD_REQUEST_SUCCESS: 'marketplace/ResetPassword/RESET_PASSWORD_REQUEST_SUCCESS',
  RESET_PASSWORD_REQUEST_CLEAR: 'marketplace/ResetPassword/RESET_PASSWORD_REQUEST_CLEAR'
};

export type resetPasswordField = {
  password: string;
  confirmPassword: string;
};
export type ResetPasswordState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  success: boolean;
};

export type resetPasswordRequestAction = (
  password: string,
  token: string | null,
  uid: string | null
) => {
  type: typeof actionTypes.RESET_PASSWORD_REQUEST;
  password: string;
  token: string | null;
  uid: string | null;
};

export type resetPasswordPendingAction = () => {
  type: typeof actionTypes.RESET_PASSWORD_REQUEST_PENDING;
};
export type resetPasswordSeccessAction = () => {
  type: typeof actionTypes.RESET_PASSWORD_REQUEST_SUCCESS;
};

export type resetPasswordClearAction = () => {
  type: typeof actionTypes.RESET_PASSWORD_REQUEST_CLEAR;
};

export type resetPasswordErrorAction = (
  error: string
) => {
  type: typeof actionTypes.RESET_PASSWORD_REQUEST_ERROR;
  error: string;
};
export type actionCreatorTypes = {
  type: string;
  error?: string;
};

export default actionTypes;
