import React, { memo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Login from 'pages/Login';
import RegisterThankYouPopup from 'components/RegisterThankYouPopup';
import Models from 'pages/Models';
import Dashboard from 'pages/Dashboard';
import ThankyouVerifyEmail from 'pages/ThankyouVerifyEmail';
import ForgetPassword from 'pages/ForgetPassword';
import ErrorPage from 'components/ErrorPage';

import ResetPassword from 'pages/ResetPassword';
import TagViewer from 'pages/TagViewer';
import InquireHerePopup from 'pages/InquireHerePopup';
import TextFundamental from 'pages/TextFundamental';
import SignUp from 'pages/SignUp';
import LiveModelPreview from 'pages/LiveModelPreview';
import RolledUpNumbers from '../../pages/RolledUpNumbers';
import { getProtectedRoute } from 'main/router/ProtectedRoute';
import { isLoggedIn, USE_SSO_LOGIN } from 'infra/http';
import { routes } from './routes';

export const RouterV1 = memo(() => (
  <Routes>
    <Route path={routes.ERROR_404} element={<ErrorPage errorType="404" />} />
    <Route path={routes.ERROR_500} element={<ErrorPage errorType="500" />} />
    <Route path={routes.ERROR_401} element={<ErrorPage errorType="401" />} />
    <Route path={routes.ERROR_403} element={<ErrorPage errorType="403" />} />
    {!USE_SSO_LOGIN && <Route path={routes.SIGN_UP} element={<SignUp />} />}
    {!USE_SSO_LOGIN && <Route path={routes.VERIFY_EMAIL} element={<ThankyouVerifyEmail />} />}
    {!USE_SSO_LOGIN && <Route path={routes.FORGET_PASSWORD} element={<ForgetPassword />} />}
    {!USE_SSO_LOGIN && <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />}
    {getProtectedRoute({ path: routes.LIVE_PREVIEW, element: <LiveModelPreview /> })}
    {getProtectedRoute({ ipWhitelisted: true, path: routes.TAG_VIEWER, element: <TagViewer /> })}
    {getProtectedRoute({
      ipWhitelisted: true,
      path: routes.DOC_VIEWER,
      element: <TagViewer isDocument />
    })}
    {getProtectedRoute({
      ipWhitelisted: true,
      path: routes.TEXT_FUNDAMENTAL,
      element: <TextFundamental />
    })}
    {getProtectedRoute({ path: routes.DASHBOARD, element: <Dashboard /> })}
    {getProtectedRoute({ path: routes.ROLLUP_NUMBERS, element: <RolledUpNumbers /> })}
    {!USE_SSO_LOGIN && !isLoggedIn().isAuthenticated && (
      <Route path={routes.LOGIN} element={<Login />} />
    )}
    <Route path={routes.INQUIRE_HERE_POPUP} element={<InquireHerePopup />} />
    <Route path={routes.REGISTER_THANKYOU_POPUP} element={<RegisterThankYouPopup />} />
    <Route path={routes.MODELS} element={<Models />} />
    <Route path={routes.HOME} element={<Models />} />
    <Route path="*" element={<Navigate to={routes.HOME} replace />} />
  </Routes>
));
