import TagViewerReducer from './reducer';
import TagViewerSelectors from './selector';
import TagViewerSaga from './saga';
import * as TagViewerTypes from './types';
import actionTypes from './types';
import actions from './actions';

export { TagViewerSelectors, TagViewerSaga, TagViewerTypes, actionTypes, actions };

export default TagViewerReducer;
