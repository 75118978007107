import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import useLocal from 'localization';

import Props from './types';

const Credits: React.FC<Props> = ({ purchased, used, available }: Props) => {
  const creditsText = useLocal('credits');
  const creditsPurchase = useLocal('credits_purchase');
  const creditsUsed = useLocal('credits_used');
  const creditsAvailable = useLocal('credits_available');
  const buyCredits = useLocal('buy_credits');

  return (
    <>
      <Box border={1} mb={2} borderColor="border.main">
        <Box
          fontWeight="fontWeightBold"
          fontSize={16}
          p={1}
          m={0}
          pl={3}
          py={2}
          bgcolor="headingBG.main"
        >
          {creditsText}
        </Box>
        <Box m={0} p={3}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Box>{creditsPurchase}</Box>
              <Box fontSize={26}>{purchased}</Box>
            </Grid>
            <Grid item xs={3}>
              <Box>{creditsUsed}</Box>
              <Box fontSize={26}>{used}</Box>
            </Grid>
            <Grid item xs={3}>
              <Box>{creditsAvailable}</Box>
              <Box color={available < 10 ? 'error.main' : ''} fontSize={26}>
                {available}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box textAlign="right">
                <Button variant="contained" size="large" color="primary">
                  {buyCredits}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(Credits);
