const actionTypes = {
  GET_API_SETTINGS: 'marketplace/ApiSettings/GET_API_SETTINGS',
  SET_API_SETTINGS: 'marketplace/ApiSettings/SET_API_SETTINGS',
  SET_API_SETTINGS_LOADING: 'marketplace/ApiSettings/SET_API_SETTINGS_LOADING',
  SET_API_SETTINGS_ERROR: 'marketplace/ApiSettings/SET_API_SETTINGS_ERROR'
};
export type ApiSettingsProps = {
  isOpened: boolean;
  onClose: () => void;
};

export type ApiSettingsState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  user: string | null;
  apiKeyById: string | null;
};

export type getApiSettingsAction = () => {
  type: typeof actionTypes.GET_API_SETTINGS;
};

export type setApiSettingsLoadingAction = () => {
  type: typeof actionTypes.SET_API_SETTINGS_LOADING;
};
export type setApiSettingsErrorAction = (
  error: string
) => {
  type: typeof actionTypes.SET_API_SETTINGS_ERROR;
  error: string;
};

export type setApiSettingsAction = (
  user: string,
  apiKeyById: string
) => {
  type: typeof actionTypes.SET_API_SETTINGS;
  user: string;
  apiKeyById: string;
};

export type actionCreatorTypes = {
  type: string;
  error?: string;
  user?: string;
  apiKeyById?: string;
};

export default actionTypes;
