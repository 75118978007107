import { ActionCreatorPayload, ReducerFunction } from 'store/types';
import { GENERAL_REDUCER_ACTIONS, GeneralState } from '../generalTypes';

type Props = GeneralState['featureFlags'];

export const setFeatureFlags = (featureFlags: Props): ActionCreatorPayload<GeneralState> => ({
  type: GENERAL_REDUCER_ACTIONS.SET_FEATURE_FLAG,
  value: { featureFlags }
});

export const setFeatureFlagsReducer: ReducerFunction<GeneralState, Props> = (state, action) => ({
  ...state,
  featureFlags: {
    ...state.featureFlags,
    ...action.payload
  }
});
