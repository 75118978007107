import actionTypes, { LoginPageState, actionCreatorTypes } from './types';

const initialState: LoginPageState = {
  isLoading: false,
  isError: false,
  showReSendEmail: false,
  erorr: null,
  actionQueue: null,
  data: {},
  isLoggedIn: false,
  shouldResetPassword: false
};

const reducer = (
  state: LoginPageState = initialState,
  action: actionCreatorTypes
): LoginPageState => {
  switch (action.type) {
    case actionTypes.ADD_ACTION_QUEUE:
      return {
        ...state,
        actionQueue: action.actionToQueue ?? null
      };
    case actionTypes.LOGIN_REQUEST_PENDING:
      return {
        ...state,
        isLoading: true,
        isLoggedIn: false
      };
    case actionTypes.LOGIN_ERORR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        erorr: action?.error ?? null,
        showReSendEmail: action?.showReSendEmail ?? false,
        shouldResetPassword: action?.shouldResetPassword ?? false,
        isLoggedIn: false
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        erorr: null,
        data: action.payload ?? null,
        isLoggedIn: true
      };

    case actionTypes.RESEND_EMAIL_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.RESEND_EMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        erorr: action?.error ?? null,
        showReSendEmail: false
      };
    case actionTypes.RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        erorr: null
      };
    case actionTypes.CLEAR_LOGIN_STATE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        erorr: null
      };
    case actionTypes.SET_SHOULD_RESET_PASSWORD:
      return {
        ...state,
        shouldResetPassword: action.shouldResetPassword ?? false
      };
    case actionTypes.LOGOUT:
      return { ...state, isLoggedIn: false };
    default:
      return state;
  }
};

export default reducer;
