import React from 'react';
import useLocal from 'localization';
import Props from './types';
import useStyles from './style';
import wrong from '../../../src/assets/wrong.svg';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router';

const VerifyEmailFailed: React.FC<Props> = ({ error }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/register');
  };

  return (
    <>
      <img
        alt={useLocal('download_excel_file')}
        className={classes.thankyouVerifyEmail}
        src={wrong}
      />
      <Typography component="h1" className={classes.h1}>
        {useLocal('thankyou_verified_failed')}
      </Typography>
      <Typography className={classes.h2}>{error}</Typography>
      <Button
        type="submit"
        color="primary"
        onClick={handleLogin}
        data-testid="login-submit"
        variant="outlined"
      >
        {useLocal('register')}
      </Button>
    </>
  );
};

export default VerifyEmailFailed;
