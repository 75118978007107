import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

type Props = {
  title: string;
  children?: React.ReactNode;
  v2BannerClosed?: boolean;
};

const SubHeader = ({ title, children, v2BannerClosed = true }: Props) => {
  const top = v2BannerClosed ? 73 : 112;
  return (
    <Box position="fixed" width="100vw" zIndex={1} top={top} bgcolor="white.main">
      <AppBar position="static" color="transparent">
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={5} md={2} key="page-title">
              <h2 data-testid="page-title">{title}</h2>
            </Grid>
            <Grid item xs={12} sm={7} md={10} key="action-box">
              {children && (
                <Box
                  py={0}
                  mt={{ xs: 0, sm: 1 }}
                  mb={{ xs: 1, sm: 1 }}
                  flexGrow={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  {children}
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </Box>
  );
};

export default React.memo(SubHeader);
