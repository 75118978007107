import actionTypes, {
  getSectorAction,
  setSectorAction,
  getSectorPendingAction,
  getSectorErrorAction,
  setSelectedSectorAction,
  getModelAction,
  setModelAction,
  getModelPendingAction,
  getModelErrorAction,
  activateAndDownloadModelAction,
  downloadModelAction,
  downloadModelStatusAction,
  downloadModelErrorAction,
  updateModelAction,
  updateModelPendingAction,
  getModelFromIndustryAction,
  setModelFromIndustryAction,
  getModelFromIndustryPendingAction,
  setMyModelAction,
  getMyModelAction,
  setSubscribeModelPendingAction,
  unSubscribePendingAction,
  unSubscribeAction,
  unSubscribeSuccess,
  unSubscribeErrorAction,
  selectCompanyAction,
  resetStateAction,
  getUpdateNotificationSuccessAction,
  getUpdatedFundamentalGroupsAction,
  getUpdatedFundamentalGroupsSuccessAction,
  getUpdatedFundamentalGroupsPendingAction,
  getUpdatedFundamentalGroupsErrorAction,
  selectNotificationCompanyAction,
  getUpdatedFundamentalsAction,
  getUpdatedFundamentalsSuccessAction,
  getUpdatedFundamentalsPendingAction,
  getUpdatedFundamentalsErrorAction,
  getEarningUpdateNotificationConfigAction,
  setEarningUpdateNotificationConfigAction,
  pollUpdateNotificationsAction,
  getUpdateNotificationsAction,
  getUpdateNotificationPendingAction,
  getUpdateNotificationErrorAction,
  markAllNotificationSeenErrorAction,
  markAllNotificationsSeenPendingAction,
  markAllNotificationsSeenSuccessAction,
  markAllNotificationsSeenAction,
  markCompanyNotificationSeenAction,
  markCompanyNotificationSeenErrorAction,
  markCompanyNotificationSeenPendingAction,
  markCompanyNotificationSeenSuccessAction
} from './types';

/**
 * Sectors
 */
const getSectors: getSectorAction = () => ({
  type: actionTypes.GET_SECTORS
});

const getSectorsPending: getSectorPendingAction = () => ({
  type: actionTypes.GET_SECTORS_PENDING
});

const getSectorsError: getSectorErrorAction = (error) => ({
  type: actionTypes.GET_SECTORS_ERROR,
  error
});

const setSectors: setSectorAction = (sectors) => ({
  type: actionTypes.SET_SECTORS,
  sectors
});

const setSelectedSectors: setSelectedSectorAction = (sectorId) => ({
  type: actionTypes.SET_SELECTED_SECTOR,
  sectorId
});

/**
 * Models
 */

const getModels: getModelAction = (params) => ({
  type: actionTypes.GET_MODELS,
  params
});

const getModelsFromIndustry: getModelFromIndustryAction = (industryId, indexToInsert) => ({
  type: actionTypes.GET_MODELS_FROM_INDUSTRY,
  industryId: parseInt(industryId),
  indexToInsert
});

const getModelsFromIndustryPending: getModelFromIndustryPendingAction = (industryIdLoadMore) => ({
  type: actionTypes.GET_MODELS_FROM_INDUSTRY_PENDING,
  industryIdLoadMore
});
const getModelsPending: getModelPendingAction = () => ({
  type: actionTypes.GET_MODELS_PENDING
});

const updateModelsPending: updateModelPendingAction = () => ({
  type: actionTypes.UPDATE_MODEL_PENDING
});

const getModelsError: getModelErrorAction = (error) => ({
  type: actionTypes.GET_MODELS_ERROR,
  error
});

const setModels: setModelAction = (models, totalNumberOfIndustries) => ({
  type: actionTypes.SET_MODELS,
  models,
  totalNumberOfIndustries
});

const setModelsFromIndustry: setModelFromIndustryAction = (models) => ({
  type: actionTypes.SET_MODELS_FROM_INDUSTRY,
  models
});
const updateModels: updateModelAction = (models) => ({
  type: actionTypes.UPDATE_MODELS,
  models
});

const getMyModels: getMyModelAction = () => ({
  type: actionTypes.GET_MY_MODELS
});

const setMyModels: setMyModelAction = (clientModels) => ({
  type: actionTypes.SET_MY_MODELS,
  clientModels
});

const setSubscribeModelPending: setSubscribeModelPendingAction = (modelId) => ({
  type: actionTypes.SUBSCRIPTION_PENDING,
  modelId
});

const activateAndDownloadModel: activateAndDownloadModelAction = (
  companyId,
  modelId,
  modelIndex,
  industryIndex,
  ticker
) => ({
  type: actionTypes.ACTIVATE_AND_DOWNLOAD_MODELS,
  payload: { companyId, modelId, modelIndex, industryIndex, ticker }
});

export const selectCompany: selectCompanyAction = (company) => ({
  type: actionTypes.SELECT_COMPANY,
  company
});

const downloadModel: downloadModelAction = (modelId, modelType, ticker) => ({
  type: actionTypes.DOWNLOAD_MODELS,
  payload: { modelId, modelType, ticker }
});

const downloadModelStatus: downloadModelStatusAction = (modelStatus, modelType, modelId) => ({
  type: `actionTypes.DOWNLOAD_MODELS_${modelStatus}`,
  modelStatus: modelStatus,
  modelType,
  modelId
});

const downloadModelError: downloadModelErrorAction = (error, modelStatus) => ({
  type: `actionTypes.DOWNLOAD_MODELS_${modelStatus}`,
  modelStatus: modelStatus,
  error
});

const unSubscribeModelPending: unSubscribePendingAction = () => ({
  type: actionTypes.UNSUBSCRIBE_PENDING
});

const unSubscribeModel: unSubscribeAction = (companyId) => ({
  type: actionTypes.UNSUBSCRIBE,
  companyId
});

const unSubscribeModelSuccess: unSubscribeSuccess = () => ({
  type: actionTypes.UNSUBSCRIBE_SUCCESS
});

const unSubscribeModelError: unSubscribeErrorAction = (error) => ({
  type: actionTypes.UNSUBSCRIBE_ERROR,
  error
});

const resetState: resetStateAction = () => ({
  type: actionTypes.RESET
});

const getUpdateNotifications: getUpdateNotificationsAction = () => ({
  type: actionTypes.GET_UPDATE_NOTIFICATIONS
});

const pollUpdateNotifications: pollUpdateNotificationsAction = () => ({
  type: actionTypes.POLL_UPDATE_NOTIFICATIONS
});

const getUpdateNotificationPending: getUpdateNotificationPendingAction = () => ({
  type: actionTypes.GET_UPDATE_NOTIFICATIONS_PENDING
});

// mark seen all notifications
const markAllNotificationsSeen: markAllNotificationsSeenAction = (companyIds) => ({
  type: actionTypes.MARK_ALL_NOTIFICATIONS_SEEN,
  companyIds
});

const markAllNotificationsSeenError: markAllNotificationSeenErrorAction = () => ({
  type: actionTypes.MARK_ALL_NOTIFICATIONS_SEEN_ERROR
});

const markAllNotificationsSeenPending: markAllNotificationsSeenPendingAction = () => ({
  type: actionTypes.MARK_ALL_NOTIFICATIONS_SEEN_PENDING
});

const markAllNotificationsSeenSuccess: markAllNotificationsSeenSuccessAction = () => ({
  type: actionTypes.MARK_ALL_NOTIFICATIONS_SEEN_SUCCESS
});

// company specific mark seen notification
const markCompanyNotificationSeen: markCompanyNotificationSeenAction = (companyId) => ({
  type: actionTypes.MARK_COMPANY_NOTIFICATION_SEEN,
  companyId
});

const markCompanyNotificationSeenError: markCompanyNotificationSeenErrorAction = () => ({
  type: actionTypes.MARK_COMPANY_NOTIFICATION_SEEN_ERROR
});

const markCompanyNotificationSeenPending: markCompanyNotificationSeenPendingAction = (
  companyId: string
) => ({
  type: actionTypes.MARK_COMPANY_NOTIFICATION_SEEN_PENDING,
  companyId
});

const markCompanyNotificationSeenSuccess: markCompanyNotificationSeenSuccessAction = (
  companyId: string
) => ({
  type: actionTypes.MARK_COMPANY_NOTIFICATION_SEEN_SUCCESS,
  companyId
});

//
const getUpdateNotificationError: getUpdateNotificationErrorAction = () => ({
  type: actionTypes.GET_UPDATE_NOTIFICATIONS_ERROR
});

const getUpdateNotificationSuccess: getUpdateNotificationSuccessAction = (updateNotifications) => ({
  type: actionTypes.GET_UPDATE_NOTIFICATIONS_SUCCESS,
  updateNotifications
});

const getUpdatedFundamentalGroups: getUpdatedFundamentalGroupsAction = (companyId: string) => ({
  type: actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS,
  companyId
});

const getUpdatedFundamentalGroupsSuccess: getUpdatedFundamentalGroupsSuccessAction = (
  updatedFundamentalGroups
) => ({
  type: actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS_SUCCESS,
  updatedFundamentalGroups
});

const getUpdatedFundamentalGroupsPending: getUpdatedFundamentalGroupsPendingAction = () => ({
  type: actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS_PENDING
});

const getUpdatedFundamentalGroupsError: getUpdatedFundamentalGroupsErrorAction = () => ({
  type: actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS_ERROR
});

const selectNotificationCompany: selectNotificationCompanyAction = (company) => ({
  type: actionTypes.SELECT_NOTIFICATION_COMPANY,
  company
});

const getUpdatedFundamentals: getUpdatedFundamentalsAction = (
  companyId: string,
  groupId: string
) => ({
  type: actionTypes.GET_UPDATED_FUNDAMENTALS,
  companyId,
  groupId
});

const getUpdatedFundamentalsSuccess: getUpdatedFundamentalsSuccessAction = (
  updatedFundamentals,
  groupId
) => ({
  type: actionTypes.GET_UPDATED_FUNDAMENTALS_SUCCESS,
  updatedFundamentals,
  groupId
});

const getUpdatedFundamentalsPending: getUpdatedFundamentalsPendingAction = (groupId) => ({
  type: actionTypes.GET_UPDATED_FUNDAMENTALS_PENDING,
  groupId
});

const getUpdatedFundamentalsError: getUpdatedFundamentalsErrorAction = () => ({
  type: actionTypes.GET_UPDATED_FUNDAMENTALS_ERROR
});

const getEarningUpdateNotificationConfig: getEarningUpdateNotificationConfigAction = () => ({
  type: actionTypes.GET_EARNING_UPDATE_NOTIFICATION_CONFIG
});

const setEarningUpdateNotificationConfig: setEarningUpdateNotificationConfigAction = (config) => ({
  type: actionTypes.SET_EARNING_UPDATE_NOTIFICATION_CONFIG,
  config
});

const stopPolling = () => ({ type: actionTypes.STOP_POLLING });

export default {
  getSectors,
  getSectorsPending,
  getSectorsError,
  setSectors,
  setSelectedSectors,
  getModels,
  getModelsPending,
  getModelsError,
  setModels,
  updateModels,
  activateAndDownloadModel,
  downloadModel,
  downloadModelStatus,
  downloadModelError,
  updateModelsPending,
  setModelsFromIndustry,
  getModelsFromIndustry,
  getModelsFromIndustryPending,
  getMyModels,
  setMyModels,
  setSubscribeModelPending,
  unSubscribeModel,
  unSubscribeModelPending,
  unSubscribeModelSuccess,
  unSubscribeModelError,
  selectCompany,
  resetState,
  getUpdateNotificationSuccess,
  getUpdatedFundamentalGroups,
  getUpdatedFundamentalGroupsSuccess,
  getUpdatedFundamentalGroupsPending,
  getUpdatedFundamentalGroupsError,
  selectNotificationCompany,
  getUpdatedFundamentals,
  getUpdatedFundamentalsSuccess,
  getUpdatedFundamentalsPending,
  getUpdatedFundamentalsError,
  getEarningUpdateNotificationConfig,
  setEarningUpdateNotificationConfig,
  pollUpdateNotifications,
  getUpdateNotificationPending,
  getUpdateNotificationError,
  getUpdateNotifications,
  markAllNotificationsSeenError,
  markAllNotificationsSeenPending,
  markAllNotificationsSeenSuccess,
  markAllNotificationsSeen,
  markCompanyNotificationSeen,
  markCompanyNotificationSeenError,
  markCompanyNotificationSeenPending,
  markCompanyNotificationSeenSuccess,
  stopPolling
};
