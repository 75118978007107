import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  modelItem: {
    paddingBottom: '2em'
  },
  modelName: {
    fontSize: '1.2em',
    padding: '.4em 0',
    color: theme.palette.text.primary
  },
  dateTime: {
    marginLeft: '0.5em',
    marginRight: '1em'
  },
  buttons: {
    marginRight: '1em'
  },
  download: {
    background: theme.palette.success.main
  },
  unSubscribe: {
    color: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.white.main,
      fontWeight: 'bold'
    }
  },
  modelPreviewLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    verticalAlign: 'text-top',
    marginRight: theme.spacing(1)
  },
  spinner: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    height: '1.8em !important',
    width: '1.8em !important'
  },
  priority: {
    color: theme.palette.error.main,
    fontWeight: 500
  }
}));
