import { createSelector } from 'reselect';

import { UserDetailState } from './types';
import { RootState } from 'store';

const getUserDetails = (state: RootState): UserDetailState => state.UserDetailReducer;

const hasError = createSelector(
  [getUserDetails],
  (UserDetailState: UserDetailState): boolean => UserDetailState.isError
);
const Error = createSelector(
  [getUserDetails],
  (UserDetailState: UserDetailState): string | null => UserDetailState.error
);

const isLoading = createSelector(
  [getUserDetails],
  (UserDetailState: UserDetailState): boolean => UserDetailState.isLoading
);

const isFreeTier = createSelector(
  [getUserDetails],
  (UserDetailState: UserDetailState): boolean => UserDetailState.tier === 'free'
);

const isTrialTier = createSelector(
  [getUserDetails],
  (UserDetailState: UserDetailState): boolean => UserDetailState.tier === 'trial'
);

const isEnterpriseTier = createSelector(
  [getUserDetails],
  (UserDetailState: UserDetailState): boolean => UserDetailState.tier === 'enterprise'
);

export default {
  hasError,
  Error,
  isLoading,
  isFreeTier,
  getUserDetails,
  isTrialTier,
  isEnterpriseTier
};
