import { takeLatest } from 'redux-saga/effects';
import { selectState } from 'store/helpers';
import actionTypes, { requestNewModelAction } from './types';

import { getLocalValue } from 'localization';
import { routes } from 'main/router';
import { gqlClient } from 'infra/http';
import { gql } from '@apollo/client';

function* newModelRequestSaga({ ticker, companyName }: ReturnType<requestNewModelAction>) {
  try {
    const {
      data: {
        createNewCompanyModelRequest: { success }
      }
    } = yield gqlClient.mutate({
      mutation: gql`
        mutation createNewModelRequestMutation($companyName: String!, $ticker: String!) {
          createNewCompanyModelRequest(companyName: $companyName, ticker: $ticker) {
            success
          }
        }
      `,
      variables: {
        ticker,
        companyName
      },
      fetchPolicy: 'no-cache'
    });
    if (success) {
      window.location.assign(routes.HOME);
      const msg: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'request_success')
      );
      alert(msg);
    }
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    window.location.assign(routes.HOME);
    alert(errorMsg);
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.MODEL_NEW_REQUEST, newModelRequestSaga);
}
