import { createSelector } from 'reselect';

import { ThankyouVerifyEmailState } from './types';
import { RootState } from 'store';

const getThankyouVerifyEmailState = (state: RootState): ThankyouVerifyEmailState =>
  state.ThankyouVerifyEmailReducer;

const hasError = createSelector(
  [getThankyouVerifyEmailState],
  (ThankyouVerifyEmailState: ThankyouVerifyEmailState): boolean => ThankyouVerifyEmailState.isError
);
const errorMsg = createSelector(
  [getThankyouVerifyEmailState],
  (ThankyouVerifyEmailState: ThankyouVerifyEmailState): string | null =>
    ThankyouVerifyEmailState.error
);

const isLoading = createSelector(
  [getThankyouVerifyEmailState],
  (ThankyouVerifyEmailState: ThankyouVerifyEmailState): boolean =>
    ThankyouVerifyEmailState.isLoading
);

const isSuccess = createSelector(
  [getThankyouVerifyEmailState],
  (ThankyouVerifyEmailState: ThankyouVerifyEmailState): boolean =>
    ThankyouVerifyEmailState.isSuccess
);

export default {
  hasError,
  errorMsg,
  isLoading,
  isSuccess
};
