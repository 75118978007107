import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export const getLocalValue = (state: RootState, key: string): string | undefined =>
  state.localizationReducer.local[key] && !state.localizationReducer.showTranslationKeys
    ? state.localizationReducer.local[key]
    : `[${key}]`;

const getLocalizedText = createSelector([getLocalValue], (text: string | undefined): string =>
  text ? text : ''
);

const useLocal = (translationKey: string): string =>
  useSelector((state: RootState): string => getLocalizedText(state, translationKey));
export default useLocal;
