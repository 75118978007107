import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import FormControl from '@material-ui/core/FormControl';

import Props from './types';
import useStyles from './style';

const ApiSettingsSkeleton: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <Skeleton variant="rect" height={56} />
      </FormControl>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <Skeleton variant="rect" height={56} />
      </FormControl>
    </>
  );
};

export default ApiSettingsSkeleton;
