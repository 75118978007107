import React from 'react';

import useLocal from 'localization';

import Props from './types';
import Box from '@material-ui/core/Box';

const NotSubscribed: React.FC<Props> = () => {
  const notSubscribed = useLocal('not_subscribed');

  return (
    <Box py={10} border={1} borderColor="border.main">
      <Box fontSize={24} fontWeight="lighter" textAlign="center">
        {notSubscribed}
      </Box>
    </Box>
  );
};

export default NotSubscribed;
