import { createSelector } from 'reselect';

import { InquireHerePopupState } from './types';
import { RootState } from 'store';

const getInquireHerePopupState = (state: RootState): InquireHerePopupState =>
  state.InquireHerePopupReducer;

const hasError = createSelector(
  [getInquireHerePopupState],
  (InquireHerePopupState: InquireHerePopupState): boolean => InquireHerePopupState.isError
);
const Error = createSelector(
  [getInquireHerePopupState],
  (InquireHerePopupState: InquireHerePopupState): string | null => InquireHerePopupState.error
);

const isLoading = createSelector(
  [getInquireHerePopupState],
  (InquireHerePopupState: InquireHerePopupState): boolean => InquireHerePopupState.isLoading
);

export default {
  hasError,
  Error,
  isLoading
};
