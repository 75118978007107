import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    background: theme.palette.headingBG.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'lighter',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: theme.spacing(2)
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: '100%'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  dialogActions: {
    marginBottom: theme.spacing(1),
    padding: 0,
    justifyContent: 'space-between'
  },
  messagesWrap: {
    fontSize: '0.8em',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    lineHeight: '1.8em'
  },
  resendButton: {
    background: 'none',
    border: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'blue',
    fontSize: '1.12em'
  }
}));
