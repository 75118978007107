import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useStatsigClient } from '@statsig/react-bindings';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Popup from 'components/Popup/Popup';
import { queryClient } from 'core';
import { DatadogProvider } from 'infra/datadog';
import { StatsigProvider } from 'infra/statsig';
import InitializeIntercom from 'intercom';
import { SET_LANGUAGE } from 'localization/reducer';
import { themeV2 } from 'main/config/theme';
import { isLoggedIn } from 'infra/http';
import Mixpanel from 'mixPanel';
import { ToastContextProvider } from 'presentation/contexts';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { generalState } from 'store/slices';
import { DaloopaTheme } from 'ui-kit';
import { actions as userDetailAction } from 'userDetail';

const ContentWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();

  const { client } = useStatsigClient();
  const enableGuidedTour = client.checkGate('enable-guided-tour-commandai');

  const { isAuthenticated, tokenData } = isLoggedIn();

  useEffect(() => {
    dispatch({
      type: SET_LANGUAGE,
      lang: 'en'
    });
    if (isAuthenticated) {
      dispatch(userDetailAction.getUserDetails());
    }
    if (process.env.REACT_APP_ENABLE_INTERCOM === 'true' && isAuthenticated) {
      InitializeIntercom();
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (enableGuidedTour && isAuthenticated) {
      void window.CommandBar.boot(tokenData?.email, { email: tokenData?.email });
      void window.CommandBar.addEventSubscriber((eventName, eventData) => {
        Mixpanel.track('commandai:marketplace:'.concat(eventName), eventData);
      });
    } else {
      window.CommandBar.shutdown();
    }
    return () => {
      window.CommandBar.shutdown();
    };
  }, [isAuthenticated, enableGuidedTour, tokenData?.email]);

  return <>{children}</>;
};

type Props = {
  children: React.ReactNode;
};

export const ProviderComposition = ({ children }: Props) => {
  const {
    featureFlags: { isMarketplaceV2Enabled }
  } = useAppSelector(generalState);

  const theme = isMarketplaceV2Enabled ? themeV2 : DaloopaTheme;

  return (
    <StatsigProvider>
      <DatadogProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <ToastContextProvider>
              <ReactQueryDevtools initialIsOpen={false} />
              <CssBaseline />
              <ContentWrapper>{children}</ContentWrapper>
              <Popup />
            </ToastContextProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </DatadogProvider>
    </StatsigProvider>
  );
};
