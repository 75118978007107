import React from 'react';

import useLocal from 'localization';

import Container from '@material-ui/core/Container';

import SubHeader from 'components/SubHeader';
import Credits from 'components/Credits';
import ModelRqStatus from 'components/ModelRqStatus';

// import { DashboardSelectors } from './duck';
// import { actionTypes } from './duck';
import { DashboardTypes } from './duck';

import useStyles from './style';

const Dashboard: React.FC<DashboardTypes.DashboardProps> = () => {
  const classes = useStyles();
  const requestedModelList = [
    {
      id: '1',
      companyName: 'Apple & Company .Inc',
      date: '10/6/2021'
    },
    {
      id: '2',
      companyName: 'Alphabet Corporation',
      date: '12/8/2021'
    },
    {
      id: '3',
      companyName: 'Daloopa .inc',
      date: '12/6/2020'
    },
    {
      id: '4',
      companyName: 'Jhonson & Jhonson .inc',
      date: '12/6/2021'
    }
  ];

  const dashboadText = useLocal('dashboad');

  return (
    <>
      <SubHeader title={dashboadText} />
      <Container className={classes.container} maxWidth="lg">
        <Credits available={9} used={41} purchased={50} />
        <ModelRqStatus requestedModelList={requestedModelList} />
      </Container>
    </>
  );
};

export default Dashboard;
