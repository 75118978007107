import React from 'react';
import { MenuV2 } from 'components/MenuV2';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { Outlet } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { generalState } from 'store/slices';
import { Container } from './DefaultLayout.styles';
import { ProviderComposition } from 'main/providers/ProviderComposition';

export const DefaultLayout = () => {
  const { menuExpanded } = useAppSelector(generalState);

  return (
    <ProviderComposition>
      <MenuV2 />
      <Container menuExpanded={menuExpanded}>
        <SearchInput />
        <Outlet />
      </Container>
    </ProviderComposition>
  );
};
