import { createSelector } from 'reselect';

import { ApiSettingsState } from './types';
import { RootState } from 'store';

const getApiSettingsState = (state: RootState): ApiSettingsState => state.ApiSettingsReducer;

const hasError = createSelector(
  [getApiSettingsState],
  (ApiSettingsState: ApiSettingsState): boolean => ApiSettingsState.isError
);
const Error = createSelector(
  [getApiSettingsState],
  (ApiSettingsState: ApiSettingsState): string | null => ApiSettingsState.error
);

const isLoading = createSelector(
  [getApiSettingsState],
  (ApiSettingsState: ApiSettingsState): boolean => ApiSettingsState.isLoading
);

const getApiSettingsData = createSelector(
  [getApiSettingsState],
  (
    ApiSettingsState: ApiSettingsState
  ): {
    user: string | null;
    apiKeyById: string | null;
  } => ({
    user: ApiSettingsState.user,
    apiKeyById: ApiSettingsState.apiKeyById
  })
);
export default {
  hasError,
  Error,
  isLoading,
  getApiSettingsData
};
