import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  logoLink: {
    color: theme.palette.white.main,
    textDecoration: 'none',
    alignSelf: 'center',
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    width: '132px',
    height: '32px'
  }
}));
