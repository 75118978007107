import React from 'react';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useLocal from 'localization';
import { ReactComponent as Error404Icon } from '../../assets/404.svg';
import { ReactComponent as Error401Icon } from '../../assets/401.svg';
import { ReactComponent as Error403Icon } from '../../assets/403.svg';
import { ReactComponent as Error500Icon } from '../../assets/500.svg';
import useStyles from './style';
import { useNavigate } from 'react-router';

const errorTypeObj: {
  [key: string]: {
    errorIcon: React.FC;
    errorCode: string;
    errorTitle: string;
    errorText: string;
  };
} = {
  '404': {
    errorIcon: Error404Icon,
    errorCode: '404_code',
    errorTitle: '404_title',
    errorText: '404_text'
  },
  '401': {
    errorIcon: Error401Icon,
    errorCode: '401_code',
    errorTitle: '401_title',
    errorText: '401_text'
  },
  '403': {
    errorIcon: Error403Icon,
    errorCode: '403_code',
    errorTitle: '403_title',
    errorText: '403_text'
  },
  '500': {
    errorIcon: Error500Icon,
    errorCode: '500_code',
    errorTitle: '500_title',
    errorText: '500_text'
  }
};

const ErrorPage: React.FC<{ errorType: string }> = ({ errorType }: { errorType: string }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { errorIcon, errorCode, errorTitle, errorText } = errorTypeObj[errorType];

  const localizedErrortitle = useLocal(errorTitle);
  const localizedErrorText = useLocal(errorText);
  const localizedErrorCode = useLocal(errorCode);
  const localizedGoToHome = useLocal('go_to_home');

  return (
    <Box paddingTop="15%" textAlign="center">
      <SvgIcon component={errorIcon} viewBox="" />
      <h2 className={classes.errorCode}>{localizedErrorCode}</h2>
      <Typography gutterBottom className={classes.errorHeading}>
        {localizedErrortitle}
      </Typography>
      <Typography className={classes.errorText} variant="body2" color="textSecondary">
        {localizedErrorText}
      </Typography>
      <Button onClick={() => navigate('/')} type="submit" color="primary" variant="contained">
        {localizedGoToHome}
      </Button>
    </Box>
  );
};
export default React.memo(ErrorPage);
