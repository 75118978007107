import actionTypes, { ResetPasswordState, actionCreatorTypes } from './types';

const initialState: ResetPasswordState = {
  isLoading: false,
  isError: false,
  error: null,
  success: false
};

const reducer = (
  state: ResetPasswordState = initialState,
  action: actionCreatorTypes
): ResetPasswordState => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_REQUEST_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null,
        success: false
      };
    case actionTypes.RESET_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
        isError: true,
        error: action.error ?? null
      };
    case actionTypes.RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null,
        success: true
      };

    case actionTypes.RESET_PASSWORD_REQUEST_CLEAR:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null,
        success: false
      };

    default:
      return state;
  }
};

export default reducer;
