export const GENERAL_REDUCER_ACTIONS = {
  GENERAL_STATE_CLEARED: '@general/stateCleared',
  SET_FEATURE_FLAG: '@general/setFeatureFlag',
  SET_MENU_STATE: '@general/setMenuState',
  SET_V2_BANNER_CLOSED: '@general/setV2BannerClosed'
} as const;

export const MENU_STATE_KEY = 'marketplace-menu-expanded';

const menuInitialStateExpanded = () =>
  localStorage.getItem(MENU_STATE_KEY) ? localStorage.getItem(MENU_STATE_KEY) === 'true' : true;

export const GENERAL_REDUCER_INITIAL_STATE: GeneralState = {
  featureFlags: {
    marketplaceV2Validated: false,
    isMarketplaceV2Enabled: localStorage.getItem('isMarketplaceV2Enabled') === 'true'
  },
  menuExpanded: menuInitialStateExpanded(),
  v2BannerClosed: true
};

export type GeneralState = {
  featureFlags: {
    [key: string]: boolean | undefined;
    isMarketplaceV2Enabled?: boolean;
    marketplaceV2Validated: boolean;
  };
  menuExpanded: boolean;
  v2BannerClosed: boolean;
};

export type GeneralActionTypes = {
  type: typeof GENERAL_REDUCER_ACTIONS[keyof typeof GENERAL_REDUCER_ACTIONS];
  error?: string;
  value?: Partial<GeneralState>;
};
