import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    background: theme.palette.headingBG.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'lighter',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: theme.spacing(2)
  },
  dialogActions: {
    display: 'block',
    textAlign: 'center',
    paddingBottom: theme.spacing(4)
  },
  h1: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    fontSize: '2em'
  },
  h2: {
    marginBottom: theme.spacing(2),
    fontSize: '1.2em'
  },
  thankyouVerifyEmail: {
    maxWidth: '100%'
  },
  buttonProgress: {
    marginBottom: theme.spacing(2)
  }
}));
