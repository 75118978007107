import React from 'react';
import DownloadModelPopup from 'components/DownloadModelPopup';

import { DownloadModelTypes } from './duck';

const DownloadModel: React.FC<DownloadModelTypes.DownloadModelProps> = ({
  isOpened
}: DownloadModelTypes.DownloadModelProps) => {
  return <DownloadModelPopup isOpened={isOpened} />;
};

export default DownloadModel;
