import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  title: {
    background: theme.palette.headingBG.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'lighter',
    paddingTop: 0,
    paddingBottom: 0
  },
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: '100%'
  },
  formFirstName: {
    paddingRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    width: '48%'
  },
  formLastName: {
    marginBottom: theme.spacing(1),
    width: '48%'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  dialogActions: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: 0,
    justifyContent: 'flex-start'
  },
  formNames: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));
