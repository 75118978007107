import actionTypes, { TagViewerState, actionCreatorTypes } from './types';

const initialState: TagViewerState = {
  isLoading: false,
  isError: false,
  error: null,
  fundamentalInfo: null,
  compositeFundamentals: null
};

const reducer = (
  state: TagViewerState = initialState,
  action: actionCreatorTypes
): TagViewerState => {
  switch (action.type) {
    case actionTypes.SET_FUNDAMENTAL_INFO_PENDING:
      return {
        ...state,
        isLoading: true,
        error: null,
        isError: false
      };
    case actionTypes.SET_FUNDAMENTAL_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error ?? null,
        isError: true
      };
    case actionTypes.SET_FUNDAMENTAL_INFO:
      return {
        ...state,
        isLoading: false,
        error: null,
        isError: false,
        compositeFundamentals: null,
        fundamentalInfo: action.fundamentalInfo ?? null
      };

    case actionTypes.SET_COMPOSITE_FUNDAMENTAL:
      return {
        ...state,
        isLoading: false,
        error: null,
        isError: false,
        compositeFundamentals: action.compositeFundamentals ?? null
      };
    default:
      return state;
  }
};

export default reducer;
