import { gql } from '@apollo/client';
import { MODEL_STATUS } from 'domain/models/client-view-models';

export const LIST_MODELS_SERVICE = gql`
  query marketplaceModels(
    $page: Int!
    $pageSize: Int!
    $isSubscribed: Boolean
    $keyword: String
    $status: String
  ) {
    marketplaceModels(
      page: $page
      pageSize: $pageSize
      isSubscribed: $isSubscribed
      keyword: $keyword
      status: $status
    ) {
      page
      pageSize
      pageCount
      totalCount
      models {
        companyId
        companyName
        companyTicker
        modelId
        priority
        isSubscribed
        startingQuarter
        updatedThrough
        updatedAt
        status
      }
    }
  }
`;

export type ListModelsServiceResponse = {
  marketplaceModels: {
    page: number;
    pageSize: number;
    pageCount: number;
    totalCount: number;
    models: Array<{
      companyId: number;
      companyName: string;
      companyTicker: string;
      modelId: number;
      priority: string;
      isSubscribed: boolean;
      startingQuarter: string;
      updatedThrough: string;
      updatedAt: string;
      status: MODEL_STATUS;
    }>;
  };
};
