import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/styles/withStyles';
import { Theme } from '@material-ui/core/styles';

const WhiteTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    padding: theme.spacing(2),
    fontSize: '1em'
  }
}))(Tooltip);

export default WhiteTooltip;
