import { UserData } from 'domain/models/user-models';

export const AUTH_REDUCER_ACTIONS = {
  AUTH_STATE_CLEARED: '@auth/stateCleared',
  SET_USER_DATA: '@auth/setUserData'
} as const;

export const AUTH_REDUCER_INITIAL_STATE: AuthState = {};

export type AuthState = {
  userData?: Partial<UserData>;
};

export type AuthActionTypes = {
  type: typeof AUTH_REDUCER_ACTIONS[keyof typeof AUTH_REDUCER_ACTIONS];
  error?: string;
  value?: Partial<AuthState>;
};
