import React from 'react';

export const DaloopaPlusLogoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="126"
    height="41"
    viewBox="0 0 126 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.519 30.5933C10.2841 30.5933 9.16166 30.312 8.17931 29.7201C7.19696 29.156 6.41164 28.3677 5.82195 27.3538C5.26001 26.3677 4.97974 25.2409 4.97974 24.0014C4.97974 22.7618 5.26001 21.6351 5.82195 20.649C6.38389 19.663 7.14146 18.8747 8.09467 18.2827C9.04927 17.7187 10.144 17.4095 11.3497 17.4095C12.3876 17.4095 13.3422 17.6351 14.2122 18.085C15.0544 18.5362 15.7564 19.1267 16.2615 19.8593V12.929C16.2615 12.6476 16.3461 12.422 16.514 12.2535C16.6819 12.085 16.8789 12 17.1592 12C17.4395 12 17.6365 12.085 17.8321 12.2535C18 12.422 18.0847 12.6476 18.0847 12.929V24.085C18.0569 25.3245 17.7475 26.4234 17.187 27.4095C16.625 28.3955 15.8397 29.1838 14.8573 29.7479C13.8472 30.312 12.7525 30.5933 11.5176 30.5933H11.519ZM11.519 28.9304C12.4167 28.9304 13.2312 28.7047 13.961 28.2827C14.6631 27.8607 15.2528 27.2688 15.6454 26.5084C16.0659 25.7758 16.2629 24.9304 16.2629 23.9721C16.2629 23.0139 16.0659 22.1978 15.6454 21.4652C15.225 20.7047 14.6631 20.1407 13.961 19.6908C13.2589 19.2688 12.4459 19.0432 11.519 19.0432C10.5922 19.0432 9.80685 19.2688 9.10477 19.6908C8.37495 20.1128 7.81301 20.7047 7.3926 21.4652C6.97219 22.2256 6.74741 23.0432 6.74741 23.9721C6.74741 24.9011 6.97219 25.7758 7.3926 26.5084C7.81301 27.2409 8.4027 27.8329 9.10477 28.2827C9.8346 28.7047 10.6199 28.9304 11.519 28.9304Z"
      fill="white"
    />
    <path
      d="M28.1344 30.5933C26.9273 30.5933 25.8326 30.312 24.8793 29.72C23.9247 29.156 23.1672 28.3677 22.6066 27.3537C22.0447 26.3677 21.7644 25.2409 21.7644 24.0014C21.7644 22.7618 22.0447 21.6072 22.6344 20.6211C23.1963 19.6351 23.9816 18.8468 24.9917 18.2549C25.9741 17.6908 27.0966 17.3816 28.3314 17.3816C29.5663 17.3816 30.6888 17.6629 31.6712 18.2549C32.6535 18.8189 33.4388 19.6072 34.0008 20.6211C34.5627 21.6072 34.8707 22.734 34.8707 24.0014L34.1409 24.5654C34.1409 25.6922 33.8884 26.7354 33.3556 27.6365C32.8228 28.5376 32.1207 29.2702 31.223 29.7771C30.2961 30.312 29.286 30.5947 28.1358 30.5947L28.1344 30.5933ZM28.3314 28.9303C29.2292 28.9303 30.0436 28.7047 30.7734 28.2827C31.4755 27.8607 32.0652 27.2688 32.4579 26.5083C32.8783 25.7479 33.0753 24.9303 33.0753 24.0014C33.0753 23.0724 32.8783 22.1977 32.4579 21.4652C32.0375 20.7326 31.4755 20.1406 30.7734 19.6908C30.0714 19.2688 29.2583 19.0432 28.3314 19.0432C27.4046 19.0432 26.6193 19.2688 25.9172 19.6908C25.1874 20.1128 24.6254 20.7047 24.205 21.4652C23.7846 22.1977 23.5598 23.0432 23.5598 24.0014C23.5598 24.9596 23.7846 25.7757 24.205 26.5083C24.6254 27.2688 25.2151 27.8328 25.9172 28.2827C26.6193 28.7047 27.4323 28.9303 28.3314 28.9303ZM33.9453 30.4805C33.665 30.4805 33.468 30.3955 33.2723 30.227C33.1044 30.0585 33.0198 29.8328 33.0198 29.5794V25.5223L33.4694 23.6629L34.8721 24.0292V29.5794C34.8721 29.8607 34.7875 30.0585 34.6196 30.227C34.4517 30.3955 34.2269 30.4805 33.9467 30.4805H33.9453Z"
      fill="white"
    />
    <path
      d="M41.3266 30.4804C40.7092 30.4804 40.1472 30.3119 39.6422 29.9735C39.1371 29.635 38.7722 29.1559 38.4919 28.5361C38.2117 27.9164 38.0715 27.2116 38.0715 26.3955V12.8996C38.0715 12.6183 38.1562 12.4205 38.3241 12.252C38.4919 12.0835 38.7167 11.9985 38.9692 11.9985C39.2495 11.9985 39.4465 12.0835 39.6144 12.252C39.7823 12.4205 39.867 12.6462 39.867 12.8996V26.3662C39.867 27.0417 40.0071 27.6058 40.2874 28.0278C40.5676 28.4498 40.9048 28.6754 41.3252 28.6754H41.9149C42.1397 28.6754 42.3353 28.7604 42.4768 28.9289C42.617 29.0974 42.7016 29.323 42.7016 29.5765C42.7016 29.8579 42.617 30.0556 42.4213 30.2242C42.2243 30.3927 41.9718 30.4776 41.6638 30.4776H41.3266V30.4804Z"
      fill="white"
    />
    <path
      d="M50.5036 30.5934C49.241 30.5934 48.1185 30.3121 47.1362 29.748C46.1538 29.1839 45.3685 28.3956 44.8066 27.4096C44.2446 26.4235 43.9644 25.2968 43.9644 24.0293C43.9644 22.7619 44.2446 21.606 44.8066 20.6199C45.3685 19.6338 46.1538 18.8455 47.1362 18.2814C48.1185 17.7174 49.241 17.436 50.5036 17.436C51.7663 17.436 52.8888 17.7174 53.8711 18.2814C54.8535 18.8455 55.6388 19.6338 56.2007 20.6199C56.7627 21.606 57.0429 22.7605 57.0707 24.0293C57.0707 25.2968 56.7904 26.4235 56.2007 27.4096C55.6388 28.3956 54.8535 29.1839 53.8711 29.748C52.861 30.3121 51.7385 30.5934 50.5036 30.5934ZM50.5036 28.9305C51.4014 28.9305 52.2436 28.7048 52.9456 28.2828C53.6755 27.8608 54.2374 27.2689 54.6301 26.5363C55.0505 25.8037 55.2475 24.9583 55.2475 24.0001C55.2475 23.0419 55.0505 22.1965 54.6301 21.4639C54.2097 20.7034 53.6477 20.1394 52.9456 19.6895C52.2158 19.2675 51.4014 19.0419 50.5036 19.0419C49.6059 19.0419 48.7637 19.2675 48.0616 19.6895C47.3318 20.1115 46.7699 20.7034 46.3495 21.4639C45.9291 22.2243 45.732 23.0697 45.732 24.0001C45.732 24.9305 45.9291 25.8037 46.3495 26.5363C46.7699 27.2689 47.3318 27.8608 48.0616 28.2828C48.7637 28.7048 49.5768 28.9305 50.5036 28.9305Z"
      fill="white"
    />
    <path
      d="M66.5862 30.5934C65.3235 30.5934 64.2011 30.3121 63.2187 29.748C62.2364 29.1839 61.451 28.3956 60.8891 27.4096C60.3272 26.4235 60.0469 25.2968 60.0469 24.0293C60.0469 22.7619 60.3272 21.606 60.8891 20.6199C61.451 19.6338 62.2364 18.8455 63.2187 18.2814C64.2011 17.7174 65.3235 17.436 66.5862 17.436C67.8488 17.436 68.9713 17.7174 69.9536 18.2814C70.936 18.8455 71.7213 19.6338 72.2832 20.6199C72.8452 21.606 73.1254 22.7605 73.1532 24.0293C73.1532 25.2968 72.8729 26.4235 72.2832 27.4096C71.7213 28.3956 70.936 29.1839 69.9536 29.748C68.9713 30.3121 67.8488 30.5934 66.5862 30.5934ZM66.5862 28.9305C67.4839 28.9305 68.3261 28.7048 69.0282 28.2828C69.758 27.8608 70.3199 27.2689 70.7126 26.5363C71.133 25.8037 71.33 24.9583 71.33 24.0001C71.33 23.0419 71.133 22.1965 70.7126 21.4639C70.2922 20.7034 69.7302 20.1394 69.0282 19.6895C68.2983 19.2675 67.4839 19.0419 66.5862 19.0419C65.6885 19.0419 64.8462 19.2675 64.1442 19.6895C63.4143 20.1115 62.8524 20.7034 62.432 21.4639C62.0116 22.2243 61.8146 23.0697 61.8146 24.0001C61.8146 24.9305 62.0116 25.8037 62.432 26.5363C62.8524 27.2689 63.4143 27.8608 64.1442 28.2828C64.8462 28.7048 65.6593 28.9305 66.5862 28.9305Z"
      fill="white"
    />
    <path
      d="M77.7292 36.0013C77.4489 36.0013 77.2241 35.9163 77.0562 35.7478C76.8883 35.5793 76.8037 35.3536 76.8037 35.0723V23.9163C76.8315 22.6768 77.1131 21.5779 77.6737 20.5918C78.2342 19.6057 79.0209 18.8174 80.0033 18.2534C80.9856 17.6893 82.0804 17.408 83.3152 17.408C84.5501 17.408 85.7004 17.6893 86.6827 18.2812C87.6651 18.8453 88.4504 19.6336 89.0401 20.6475C89.602 21.6336 89.91 22.7603 89.91 24.0277C89.91 25.2951 89.6298 26.394 89.0678 27.3801C88.5059 28.3662 87.7483 29.1545 86.7937 29.7464C85.8391 30.3105 84.7444 30.6197 83.5386 30.6197C82.5008 30.6197 81.5462 30.394 80.6762 29.9442C79.8063 29.4929 79.1319 28.9024 78.6269 28.1698V35.128C78.6269 35.4094 78.5422 35.635 78.3744 35.8035C78.2065 35.9163 77.9817 36.0013 77.7292 36.0013ZM83.343 28.9302C84.2407 28.9302 85.0829 28.7046 85.785 28.2826C86.5148 27.8606 87.0768 27.2687 87.4972 26.5082C87.9176 25.7478 88.1146 24.9302 88.1146 24.0013C88.1146 23.0723 87.9176 22.1977 87.4972 21.4651C87.0768 20.7325 86.5148 20.1405 85.785 19.6907C85.0552 19.2687 84.2407 19.0431 83.343 19.0431C82.4453 19.0431 81.6308 19.2687 80.9288 19.6907C80.1989 20.1127 79.637 20.7046 79.2443 21.4651C78.8239 22.1977 78.6269 23.0431 78.6269 24.0013C78.6269 24.9595 78.8239 25.7756 79.2443 26.5082C79.6647 27.2687 80.2267 27.8328 80.9288 28.2826C81.6308 28.7046 82.4439 28.9302 83.343 28.9302Z"
      fill="white"
    />
    <path
      d="M99.284 30.5933C98.0769 30.5933 96.9821 30.312 96.0289 29.72C95.0743 29.156 94.3167 28.3677 93.7548 27.3537C93.1929 26.3677 92.9126 25.2409 92.9126 24.0014C92.9126 22.7618 93.1929 21.6072 93.7826 20.6211C94.3445 19.6351 95.1298 18.8468 96.1399 18.2549C97.1223 17.6908 98.2448 17.3816 99.4796 17.3816C100.715 17.3816 101.837 17.6629 102.819 18.2549C103.802 18.8189 104.587 19.6072 105.149 20.6211C105.711 21.6072 106.019 22.734 106.019 24.0014L105.289 24.5654C105.289 25.6922 105.037 26.7354 104.504 27.6365C103.971 28.5376 103.269 29.2702 102.371 29.7771C101.444 30.312 100.434 30.5947 99.284 30.5947V30.5933ZM99.481 28.9303C100.379 28.9303 101.193 28.7047 101.923 28.2827C102.625 27.8607 103.215 27.2688 103.607 26.5083C104.028 25.7479 104.225 24.9303 104.225 24.0014C104.225 23.0724 104.028 22.1977 103.607 21.4652C103.187 20.7326 102.625 20.1406 101.923 19.6908C101.221 19.2688 100.408 19.0432 99.481 19.0432C98.5542 19.0432 97.7688 19.2688 97.0668 19.6908C96.3369 20.1128 95.775 20.7047 95.3546 21.4652C94.9342 22.1977 94.7094 23.0432 94.7094 24.0014C94.7094 24.9596 94.9342 25.7757 95.3546 26.5083C95.775 27.2688 96.3647 27.8328 97.0668 28.2827C97.7688 28.7047 98.5819 28.9303 99.481 28.9303ZM105.095 30.4805C104.815 30.4805 104.618 30.3955 104.422 30.227C104.254 30.0585 104.169 29.8328 104.169 29.5794V25.5223L104.619 23.6629L106.022 24.0292V29.5794C106.022 29.8607 105.937 30.0585 105.769 30.227C105.601 30.3955 105.377 30.4805 105.096 30.4805H105.095Z"
      fill="white"
    />
    <path
      d="M121 11H115V5C115 4.73478 114.895 4.48043 114.707 4.29289C114.52 4.10536 114.265 4 114 4C113.735 4 113.48 4.10536 113.293 4.29289C113.105 4.48043 113 4.73478 113 5V11H107C106.735 11 106.48 11.1054 106.293 11.2929C106.105 11.4804 106 11.7348 106 12C106 12.2652 106.105 12.5196 106.293 12.7071C106.48 12.8946 106.735 13 107 13H113V19C113 19.2652 113.105 19.5196 113.293 19.7071C113.48 19.8946 113.735 20 114 20C114.265 20 114.52 19.8946 114.707 19.7071C114.895 19.5196 115 19.2652 115 19V13H121C121.265 13 121.52 12.8946 121.707 12.7071C121.895 12.5196 122 12.2652 122 12C122 11.7348 121.895 11.4804 121.707 11.2929C121.52 11.1054 121.265 11 121 11Z"
      fill="#70B5B0"
    />
  </svg>
);
