import {
  ACCESS_TOKEN_COOKIE_NAME,
  CSRFTOKEN,
  REFRESH_TOKEN_COOKIE_NAME
} from 'application/protocols';
import { TokenData } from 'domain/models';
import Cookies from 'js-cookie';
import { router, routerV1, routes } from 'main/router';

export const USE_SSO_LOGIN = process.env.REACT_APP_USE_SSO_LOGIN === 'true';

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const isLoggedIn = () => {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  const refreshToken = Cookies.get(REFRESH_TOKEN_COOKIE_NAME);
  let cognitoSessionToken;
  if (process.env.REACT_APP_COGNITO_SESSION_TOKEN_COOKIE_NAME) {
    cognitoSessionToken = Cookies.get(process.env.REACT_APP_COGNITO_SESSION_TOKEN_COOKIE_NAME);
  }
  const currentTime = new Date().getTime();
  const tokenData: TokenData | null = refreshToken && accessToken ? parseJwt(accessToken) : null;
  // Token expiration time is in seconds, so we need to multiply by 1000 to get milliseconds
  const isAuthenticated =
    (tokenData && tokenData.exp * 1000 > currentTime) || !!cognitoSessionToken;

  return { isAuthenticated, tokenData };
};

export const logout = (redirectTo?: string) => {
  Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
  Cookies.remove(REFRESH_TOKEN_COOKIE_NAME);
  Cookies.remove(CSRFTOKEN);
  let cognitoSessionToken;

  if (process.env.REACT_APP_COGNITO_SESSION_TOKEN_COOKIE_NAME) {
    cognitoSessionToken = Cookies.get(process.env.REACT_APP_COGNITO_SESSION_TOKEN_COOKIE_NAME);
  }

  if (
    USE_SSO_LOGIN &&
    window &&
    process.env.REACT_APP_COGNITO_LOGOUT_URL &&
    process.env.REACT_APP_COGNITO_LOGIN_REDIRECT_URL
  ) {
    // session token found logout
    if (Boolean(cognitoSessionToken)) {
      window.location.replace(process.env.REACT_APP_COGNITO_LOGOUT_URL);
    } else {
      // no session token redirect to login again
      window.location.replace(process.env.REACT_APP_COGNITO_LOGIN_REDIRECT_URL);
    }
    return;
  }

  if (redirectTo) {
    window.location.assign(redirectTo);
    return;
  }

  window.location.reload();
};

export const getUserInfo = () => {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  if (accessToken) return parseJwt(accessToken);
  return null;
};

const getLastVisitedPath = () => {
  const currentPath = window.location.pathname;
  const currentSearch = window.location.search;

  return encodeURIComponent(`${currentPath}${currentSearch}`);
};

export const redirectToSSOUrl = () => {
  if (window && process.env.REACT_APP_COGNITO_LOGIN_REDIRECT_URL) {
    const port = window.location.port;
    let fullDomainUrl = `${window.location.protocol}//${window.location.hostname}`;
    if (Boolean(port)) {
      fullDomainUrl = `${fullDomainUrl}:${port}`;
    }
    const statePath = `${fullDomainUrl}${getLastVisitedPath()}`;
    window.location.href = `${process.env.REACT_APP_COGNITO_LOGIN_REDIRECT_URL}&state=${encodeURI(
      statePath
    )}`;
  }
};

export const redirectToLoginUrl = () => {
  if (USE_SSO_LOGIN) {
    redirectToSSOUrl();
  } else {
    router.navigate(routes.LOGIN);
    routerV1.navigate(routes.LOGIN);
  }
};

export const getTokenExpiration = (token: string) => {
  const parsedData = parseJwt(token);
  const expiresTime = parsedData.exp;
  const currentEpochTime = Math.floor(Date.now() / 1000);

  const differenceInDays = Math.ceil(Math.abs(currentEpochTime - expiresTime) / (60 * 60 * 24));

  return differenceInDays;
};

export const getUserEmail = () => {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  if (accessToken) {
    const payload: {
      email: string;
      exp: number;
    } = parseJwt(accessToken);
    return payload.email;
  }
  return null;
};
