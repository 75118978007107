import React from 'react';

import useLocal from 'localization';
import Box from '@material-ui/core/Box';

import Props from './types';

const NoModelFound: React.FC<Props> = () => {
  const noItemFound = useLocal('no_item_found');

  return (
    <Box py={10} border={1} borderColor="border.main">
      <Box fontSize={24} fontWeight="lighter" textAlign="center">
        {noItemFound}
      </Box>
    </Box>
  );
};

export default React.memo(NoModelFound);
