import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ExcelPluginPopup from 'components/ExcelPluginPopup';

import { ExcelPluginSelectors, ExcelPluginTypes, actions } from './duck';

const ExcelPlugin: React.FC<ExcelPluginTypes.ExcelPluginProps> = ({
  isOpened,
  onClose
}: ExcelPluginTypes.ExcelPluginProps) => {
  const dispatch = useDispatch();
  const isLoadingMsi = useSelector(ExcelPluginSelectors.isLoadingMsi);
  const isSuccessMsi = useSelector(ExcelPluginSelectors.isSuccessMsi);
  const isSuccessExe = useSelector(ExcelPluginSelectors.isSuccessExe);
  const hasError = useSelector(ExcelPluginSelectors.hasError);
  const error = useSelector(ExcelPluginSelectors.getError);
  const excelVersion = useSelector(ExcelPluginSelectors.excelVersion);

  useEffect(() => {
    if (isOpened) {
      dispatch(actions.getExcelVersionLatest());
    }
  }, [isSuccessMsi, isSuccessExe, dispatch, isOpened]);

  return (
    <ExcelPluginPopup
      isLoadingMsi={isLoadingMsi}
      error={error}
      isOpened={isOpened}
      hasError={hasError}
      isSuccessMsi={isSuccessMsi}
      isSuccessExe={isSuccessExe}
      onSubmitMsi={(version) => dispatch(actions.getDownloadMsiToken(version))}
      onClose={onClose}
      excelVersion={excelVersion}
    />
  );
};

export default ExcelPlugin;
