import React, { useEffect } from 'react';
import { LiveModelPreviewTable } from './LiveModelPreviewTable';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import useStyles from './style';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import Mixpanel from '../../mixPanel';
import { useQuery } from '@tanstack/react-query';
import { getCompany } from './service';
import { routes } from 'main/router';

const LiveModelPreview = () => {
  const navigate = useNavigate();

  const styles = useStyles();
  const { companyId } = useParams<{ companyId: string }>();
  const { data: company, isLoading } = useQuery(
    ['company', companyId],
    () => getCompany(companyId),
    {
      enabled: !!companyId,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  );

  useEffect(() => {
    Mixpanel.track('marketplace:live_preview:visited', { companyId });
    if (!companyId) {
      navigate(routes.ERROR_404, { replace: true });
    }
  }, [companyId, navigate]);

  useEffect(() => {
    if (company) {
      Mixpanel.track('marketplace:live_preview:company_loaded', { company });
    }
  }, [company]);

  return (
    <Box className={styles.pageContainer}>
      {isLoading ? (
        <Box className={styles.progressWrapper}>
          <CircularProgress variant={'indeterminate'} />
        </Box>
      ) : (
        <>
          {!!company?.name && !!company?.ticker && (
            <Typography variant={'h5'}>
              {company.name} ({company.ticker})
            </Typography>
          )}
          <LiveModelPreviewTable companyId={companyId} />
        </>
      )}
    </Box>
  );
};

export default LiveModelPreview;
