import { useSelector } from 'react-redux';
import { TagViewerSelectors } from './duck';
import useStyles from './style';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper
} from '@material-ui/core';
import React from 'react';
import { Tooltip } from '@mui/material';

export const RestatedFundamentalMappingTable = () => {
  const classes = useStyles();

  const fundamentalInfo = useSelector(TagViewerSelectors.getFundamentlInfo);
  const referenceFundamental = fundamentalInfo?.referenceFundamental;
  const restatedFundamentals = fundamentalInfo?.restatedFundamentals ?? [];
  const fundamentalsToRender = [referenceFundamental, ...restatedFundamentals];

  return (
    <div className={classes.restatementTableContainer}>
      <div className={classes.restatementHistoryTitle}>Restatement History</div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Original</TableCell>
              <TableCell>Restatements</TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell>Context</TableCell>
              {fundamentalsToRender?.map((f) => (
                <TableCell key={`${f?.id}-${f?.period}`}>{f?.period}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>
                <Tooltip title={fundamentalInfo?.fullSeriesName ?? ''}>
                  <span>{fundamentalInfo?.contextSummary}</span>
                </Tooltip>
              </TableCell>
              {fundamentalsToRender?.map((f) => (
                <TableCell key={f?.id}>
                  <a href={`/src/${f?.id}`} target={'_blank'} rel={'noreferrer'}>
                    <span className={f?.id === fundamentalInfo?.id ? classes.highlight : undefined}>
                      {f?.value}
                    </span>
                  </a>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
