const actionTypes = {
  GET_USER_DETAILS: 'marketplace/UserDetail/GET_USER_DETAILS',
  SET_USER_DETAILS_PENDING: 'marketplace/UserDetail/SET_USER_DETAILS_PENDING',
  SET_USER_DETAILS_ERROR: 'marketplace/UserDetail/SET_USER_DETAILS_ERROR',
  SET_USER_DETAILS: 'marketplace/UserDetail/SET_USER_DETAILS'
};
export type userDetail = {
  tier: string | null;
  maxSubscriptions: number;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: number | null;
  subscribedCount: number;
  allowedSectors: [string] | null;
  receiveModelUpdateNotification: boolean;
};
export type UserDetailState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  tier: string | null;
  maxSubscriptions: number;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: number | null;
  subscribedCount: number;
  allowedSectors: [string] | null;
  receiveModelUpdateNotification: boolean;
};

export type getUserDetailsAction = () => {
  type: typeof actionTypes.GET_USER_DETAILS;
};

export type setUserDetailsPendingAction = () => {
  type: typeof actionTypes.SET_USER_DETAILS_PENDING;
};

export type setUserDetailsErrorAction = (
  error: string
) => {
  type: typeof actionTypes.SET_USER_DETAILS_ERROR;
  error: string;
};

export type setUserDetailsAction = (
  userDetail: userDetail
) => {
  type: typeof actionTypes.SET_USER_DETAILS;
  userDetail: userDetail;
};

export type actionCreatorTypes = {
  type: string;
  error?: string;
  userTier?: string;
  userDetail?: userDetail;
};

export default actionTypes;
