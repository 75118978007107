const actionTypes = {
  VERIFY_EMAIL: 'marketplace/ThankyouVerifyEmail/VERIFY_EMAIL',
  VERIFY_EMAIL_PENDING: 'marketplace/ThankyouVerifyEmail/VERIFY_EMAIL_PENDING',
  VERIFY_EMAIL_ERROR: 'marketplace/ThankyouVerifyEmail/VERIFY_EMAIL_ERROR',
  VERIFY_EMAIL_SUCCESS: 'marketplace/ThankyouVerifyEmail/VERIFY_EMAIL_SUCCESS'
};
export type ThankyouVerifyEmailProps = {
  // Add Props type here
};
export type ThankyouVerifyEmailState = {
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  isSuccess: boolean;
};

export type verifyEmailActionType = (
  uid: string | null,
  token: string | null
) => {
  type: typeof actionTypes.VERIFY_EMAIL;
  uid: string | null;
  token: string | null;
};

export type verifyEmailPendingActionType = () => {
  type: typeof actionTypes.VERIFY_EMAIL_PENDING;
};

export type verifyEmailErrorActionType = (
  error: string | null
) => {
  type: typeof actionTypes.VERIFY_EMAIL_ERROR;
  error: string | null;
};

export type verifyEmailSuccessActionType = () => {
  type: typeof actionTypes.VERIFY_EMAIL_SUCCESS;
};

export type actionCreatorTypes = {
  type: string;
  error?: string | null;
};
export default actionTypes;
