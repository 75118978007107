import { styled } from '@mui/material';
import React from 'react';
import { authState } from 'store/slices';
import { useAppSelector } from 'store/hooks';
import { getUserInitialAndColor } from '../utils';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  marginBottom: '36px',
  alignItems: 'center',
  fontSize: '16px',
  color: 'black',

  [theme.breakpoints.up('sm')]: {
    gap: '20px',
    fontSize: '34px'
  }
}));

const UserBadge = styled('div', { shouldForwardProp: (prop) => prop !== 'background' })<{
  background: string;
}>(({ background, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  minWidth: '32px',
  height: '32px',
  background,
  borderRadius: '100%',
  textTransform: 'uppercase',
  fontSize: '16px',
  color: 'white',

  [theme.breakpoints.up('sm')]: {
    width: '70px',
    minWidth: '70px',
    height: '70px',
    fontSize: '34px'
  }
}));

export const UserBanner = () => {
  const { userData } = useAppSelector(authState);
  const { color, initials } = getUserInitialAndColor(userData?.email ?? '');

  const user = (userData?.email ?? '').split('@')[0];

  return (
    <Container>
      <UserBadge background={color}>{initials}</UserBadge>
      Welcome back, {user}!
    </Container>
  );
};
