import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Navigate, Route, useNavigate } from 'react-router';
import { routes } from './routes';
import { useDispatch } from 'react-redux';
import { setFeatureFlags } from 'store';
import { userIPValidationService } from 'application/services/user/user-ip-validation';
import { CircularProgress } from '@mui/material';
import { isLoggedIn, redirectToSSOUrl, USE_SSO_LOGIN } from 'infra/http';

const SSORedirect = () => {
  useLayoutEffect(() => {
    redirectToSSOUrl();
  }, []);
  return <></>;
};

type Props = {
  children: React.ReactNode;
  redirectToV1?: boolean;
};

export const ProtectedRoute = ({ children, redirectToV1 }: Props) => {
  const dispatch = useDispatch();
  const isAuthenticated = isLoggedIn().isAuthenticated;

  const path = window.location.pathname;
  const from = path.concat(window.location.search);

  if (redirectToV1) {
    dispatch(setFeatureFlags({ isMarketplaceV2Enabled: false, marketplaceV2Validated: true }));
  }

  if (!isAuthenticated) {
    if (USE_SSO_LOGIN) {
      return <SSORedirect />;
    }

    return <Navigate to={routes.LOGIN} state={{ from }} />;
  }

  return <>{children}</>;
};

type WhitelistedRouteProps = {
  from: string;
  element: JSX.Element;
};

const WhitelistedRoute = ({ element, from }: WhitelistedRouteProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    userIPValidationService.execute().then((res) => {
      if (res.isError()) {
        navigate(routes.LOGIN, { state: { from } });
      } else {
        setIsLoading(false);
      }
    });
  }, [from, navigate]);

  if (isLoading) return <CircularProgress sx={{ margin: '24px auto' }} />;

  return <>{element}</>;
};

type GetProtectedRouteProps = {
  path: string;
  element: JSX.Element;
  ipWhitelisted?: boolean;
};

export const getProtectedRoute = ({ element, path, ipWhitelisted }: GetProtectedRouteProps) => {
  const isAuthenticated = isLoggedIn().isAuthenticated;

  const from = window.location.pathname.concat(window.location.search);

  if (!isAuthenticated) {
    if (ipWhitelisted) {
      return <Route element={<WhitelistedRoute element={element} from={from} />} path={path} />;
    }

    if (USE_SSO_LOGIN) {
      return <SSORedirect />;
    }

    return <Route element={<Navigate to={routes.LOGIN} state={{ from }} />} path={path} />;
  }

  return <Route element={element} path={path} />;
};
