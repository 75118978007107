import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  tableContainer: {
    marginTop: 16,
    height: '100%'
    // maxHeight: '80vh'
  },
  tooltip: {
    whiteSpace: 'pre-wrap'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12
  },
  search: {
    width: 300
  },
  documentsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  documentUrl: {
    marginLeft: '6px !important',
    cursor: 'pointer'
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  filingTypeFilterContainer: {
    padding: 16,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  filingTypeFilterButton: {
    marginLeft: 20
  },
  filingTypeClearFilters: {
    fontSize: 10,
    position: 'absolute',
    top: 6,
    right: 6
  },
  filingTypeFormGroup: {
    marginTop: 12,
    display: 'grid',
    gridGap: 10,
    gridTemplateColumns: '1fr 1fr 1fr'
  },
  pageContainer: {
    marginTop: 72,
    padding: theme.spacing(3),
    overflowY: 'auto',
    maxHeight: '100vh',
    height: '100vh'
  },
  progressWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  dataPoint: {
    backgroundColor: `${theme.palette.success.light} !important`,
    color: `${theme.palette.success.dark} !important`,
    fontWeight: 700
  },
  fundamentalCount: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: `${theme.palette.primary.dark} !important`,
    fontWeight: 700,
    marginLeft: 12
  },
  dataPointIcon: {
    color: `${theme.palette.success.dark} !important`
  },
  fundamentalCountIcon: {
    color: `${theme.palette.primary.dark} !important`
  }
}));
