import React, { createContext, useContext, useMemo } from 'react';
import { IconProps, ToastContainer, ToastOptions, toast as toastifyToast } from 'react-toastify';

import { Button, styled } from '@mui/material';
import { CloseIcon } from 'components/Icons';

import 'react-toastify/dist/ReactToastify.css';

type Toaster = {
  (message: string, handleUndo?: () => void): void;
  success(message: string, handleUndo?: () => void): void;
  error(message?: string, onRetry?: () => void): void;
};

const ToastContext = createContext<Toaster | null>(null);

const ErrorIcon = (
  <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.084 13.7502H11.9173V15.5835H10.084V13.7502ZM10.084 6.41683H11.9173V11.9168H10.084V6.41683ZM10.9915 1.8335C5.93148 1.8335 1.83398 5.94016 1.83398 11.0002C1.83398 16.0602 5.93148 20.1668 10.9915 20.1668C16.0607 20.1668 20.1673 16.0602 20.1673 11.0002C20.1673 5.94016 16.0607 1.8335 10.9915 1.8335ZM11.0007 18.3335C6.94898 18.3335 3.66732 15.0518 3.66732 11.0002C3.66732 6.9485 6.94898 3.66683 11.0007 3.66683C15.0523 3.66683 18.334 6.9485 18.334 11.0002C18.334 15.0518 15.0523 18.3335 11.0007 18.3335Z"
      fill="white"
    />
  </svg>
);

const CloseButton = styled('button')(() => ({
  height: '24px',
  width: '24px',
  color: '#FFFFFF',
  opacity: 1,
  margin: 'auto 0',

  '& svg': {
    width: '24px',
    height: '24px'
  }
}));

const StyledToastContainer = styled(ToastContainer)(() => ({
  width: 'fit-content',
  minWidth: '354px',

  '& .Toastify__toast': {
    minHeight: 'unset',
    borderRadius: '4px',
    background: '#292B2E',
    color: '#FFFFFF'
  },

  '& .Toastify__toast-body': {
    alignItems: 'start',
    fontSize: '14px',
    fontWeight: 'bold',

    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },

  '& .Toastify__toast-icon': {
    '& svg': {
      height: '20px',
      width: '20px'
    }
  },

  '& .Toastify__toast--error': {
    background: '#D32F2F',
    color: 'white',

    '& .Toastify__toast-icon': {
      color: 'white',

      '& svg': {
        height: '20px',
        width: '20px'
      }
    }
  }
}));

const ToastTryAgainButton = styled('a')(() => ({
  marginLeft: '4px',
  color: '#FFFFFF',

  '&:hover': {
    color: '#397194'
  },

  '&:first-of-type': {
    marginRight: '4px'
  }
}));

const ToastUndoButton = styled(Button)(() => ({
  margin: '0 0 auto auto',
  color: '#FFFFFF',
  padding: '0',
  background: 'transparent',

  '&:hover': {
    color: '#8FC4C2'
  }
}));

const ErrorMessageWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '4px',
  maxWidth: '322px'
}));

export const ToastMessage = ({ message, onUndo }: { message: string; onUndo?: () => void }) => (
  <>
    {message}
    {onUndo && <ToastUndoButton onClick={onUndo}>UNDO</ToastUndoButton>}
  </>
);

const ToastError = ({ message, onRetry }: { message?: string; onRetry?: () => void }) => {
  const onRefresh = () => location.reload();

  if (message) {
    return (
      <ErrorMessageWrapper>
        {message}
        {onRetry && <ToastTryAgainButton onClick={onRetry}>Try again</ToastTryAgainButton>}
      </ErrorMessageWrapper>
    );
  }

  return (
    <div>
      Something went wrong. Please
      <ToastTryAgainButton onClick={onRefresh}>refresh</ToastTryAgainButton>
      or
      {onRetry && <ToastTryAgainButton onClick={onRetry}>try again</ToastTryAgainButton>}
      {!onRetry && ' try again'}.
    </div>
  );
};

type Props = {
  children: React.ReactNode;
};

export const ToastContextProvider = ({ children }: Props) => {
  const config: ToastOptions = {
    autoClose: 3000,
    hideProgressBar: true,
    closeButton() {
      return (
        <CloseButton className="Toastify__close-button" aria-label="close">
          <CloseIcon />
        </CloseButton>
      );
    }
  };

  const getIcon = ({ type }: IconProps) => {
    if (type === 'error') return ErrorIcon;
  };

  const toast = (message: string, handleUndo?: () => void) => {
    toastifyToast(<ToastMessage message={message} onUndo={handleUndo} />, config);
  };

  toast.success = (message: string, handleUndo?: () => void) => {
    toastifyToast.success(<ToastMessage message={message} onUndo={handleUndo} />, { ...config });
  };

  toast.error = (message?: string, onRetry?: () => void) => {
    toastifyToast.error(<ToastError message={message} onRetry={onRetry} />, { ...config });
  };

  return (
    <ToastContext.Provider value={toast}>
      {children}
      <StyledToastContainer icon={getIcon} />
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToastContext is only available within ToastContext Provider');
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => context, []);
};
