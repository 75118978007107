import React from 'react';
import Header from 'components/Header/Header';
import { ProviderComposition } from 'main/providers/ProviderComposition';
import { RouterV1 } from 'main/router/RouterV1';

export const OldLayout = () => {
  return (
    <ProviderComposition>
      <Header />
      <RouterV1 />
    </ProviderComposition>
  );
};
