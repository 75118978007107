import { setUserDataReducer } from './actions/setUserData';
import {
  AUTH_REDUCER_ACTIONS,
  AUTH_REDUCER_INITIAL_STATE,
  AuthActionTypes,
  AuthState
} from './authTypes';
import { authStateClearedReducer } from './actions/authStateCleared';
import { CaseReducer } from 'store/types';

export const authReducer: CaseReducer<AuthState, AuthActionTypes> = (
  state: AuthState = AUTH_REDUCER_INITIAL_STATE,
  action: AuthActionTypes
) => {
  switch (action.type) {
    case AUTH_REDUCER_ACTIONS.AUTH_STATE_CLEARED:
      return authStateClearedReducer(state, action);
    case AUTH_REDUCER_ACTIONS.SET_USER_DATA:
      return setUserDataReducer(state, {
        payload: action.value?.userData,
        type: action.type
      });
    default:
      return state;
  }
};
