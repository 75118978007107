import { gql } from '@apollo/client';
import { UserSettings } from 'domain/models/user-models';

export const GET_USER_SETTINGS_SERVICE = gql`
  query userSettings {
    userSettings {
      notifications {
        receiveNewClientViewUpdates
        receiveIntraQuarterModificationUpdates
        receiveIntraQuarterNewRowsUpdates
        receiveDocumentIngestedUpdates
        receiveAutotaggingCompletedUpdates
      }
    }
  }
`;

export const UPDATE_USER_SETTINGS_SERVICE = gql`
  mutation userSettings($notifications: NotificationsInputType) {
    userSettings(notifications: $notifications) {
      success
      userSettings {
        notifications {
          receiveAutotaggingCompletedUpdates
          receiveDocumentIngestedUpdates
          receiveIntraQuarterModificationUpdates
          receiveIntraQuarterNewRowsUpdates
          receiveNewClientViewUpdates
        }
      }
    }
  }
`;

export type UserSettingsResponse = {
  userSettings: UserSettings;
};
