import actionTypes, {
  getIndustryModelsAction,
  getIndustryModelsErrorAction,
  getIndustryModelsPendingAction,
  getIndustryModelsSuccessAction
} from './types';

/**
 * Industries
 */
const getIndustryModels: getIndustryModelsAction = (query) => ({
  type: actionTypes.GET_INDUSTRY_MODELS,
  query
});

const getIndustryModelsPending: getIndustryModelsPendingAction = () => ({
  type: actionTypes.GET_INDUSTRY_MODELS_PENDING
});

const getIndustryModelsError: getIndustryModelsErrorAction = (error) => ({
  type: actionTypes.GET_INDUSTRY_MODELS_ERROR,
  error
});

const setIndustryModelsSuccess: getIndustryModelsSuccessAction = (industryModels) => ({
  type: actionTypes.GET_INDUSTRY_MODELS_SUCCESS,
  industryModels
});

export default {
  getIndustryModelsError,
  getIndustryModelsPending,
  setIndustryModelsSuccess,
  getIndustryModels
};
