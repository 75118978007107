import { ModelsTypes } from 'pages/Models/duck';
type Prop = {
  sectorList: ModelsTypes.sectorList;
  hasSectorError: boolean;
  sectorError: string | null;
  isSectorLoading: boolean;
  selectFilter: (sectorId: string) => void;
  selectedSector: string;
  isFreeTier?: boolean;
  allowedSectors?: [string] | null;
  isEnterpriseTier: boolean;
  isTrialTier: boolean;
};
export const allModelId = 'all_model';
export const myModelId = 'my_model';

export const industryModelsId = 'industry-models';
export default Prop;
