import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  ServerError
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ACCESS_TOKEN_COOKIE_NAME, AuthTokenError, CSRFTOKEN } from 'application/protocols';
import { logout, USE_SSO_LOGIN } from '../utils';
import Cookies from 'js-cookie';
import { router, routerV1 } from 'main/router';

const getHeaders = () => {
  const csrfToken = Cookies.get(CSRFTOKEN);
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  const headers: { 'X-CSRFToken'?: string; Authorization?: string } = {};

  if (csrfToken) {
    headers['X-CSRFToken'] = csrfToken;
  }

  if (!USE_SSO_LOGIN && accessToken) {
    headers.Authorization = `JWT ${accessToken}`;
  }

  return headers;
};

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: any) => {
    return {
      headers: {
        ...headers,
        ...getHeaders()
      }
    };
  });

  return forward(operation);
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (
        message === AuthTokenError.TOKEN_EXPIRATION_ERROR ||
        message === AuthTokenError.AUTHENTICATION_REQUIRED_ERROR ||
        message === AuthTokenError.ERROR_DECODING_SIGNATURE
      ) {
        logout();
      }
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);

    // TODO: Check if this error validation is correct
    if (networkError.name === 'ServerError' || networkError.name === 'ServerParseError') {
      const code = (networkError as ServerError).statusCode;

      switch (code) {
        case 404:
          router.navigate('/404');
          routerV1.navigate('/404');
          break;
        case 401:
          router.navigate('/401');
          routerV1.navigate('/401');
          break;
        case 403:
          router.navigate('/403');
          routerV1.navigate('/403');
          break;
        case 500:
          router.navigate('/500');
          routerV1.navigate('/500');
          break;
      }
    }
  }
});

const link = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/gql`,
  credentials: 'include'
});

const httpLinks = from([errorLink, authLink.concat(link)]);

export const gqlClient = new ApolloClient({
  link: httpLinks,
  cache: new InMemoryCache(),
  credentials: 'include'
});
