import React, { useMemo } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Mixpanel from '../../mixPanel';
import Props from './types';
import useStyles from './style';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import useLocal from 'localization';

const ModelFilter: React.FC<Props> = ({
  title,
  list,
  selectFilter,
  seletedFilterId,
  showIcon,
  isFreeTier,
  allowedSectors
}: Props) => {
  const classes = useStyles();
  const sectorToolTip = useLocal('sector_tool_tip_available_model');
  const isAllowedSectorEnabled = useMemo(() => {
    const sectorIds = new Set(list.map((s) => s.id));
    return allowedSectors?.every((s) => sectorIds.has(s));
  }, [allowedSectors, list]);
  return (
    <>
      <Box
        color="white.main"
        p={1}
        bgcolor="primary.main"
        fontWeight="fontWeightBold"
        data-testid="filter-title"
      >
        {title}
      </Box>
      {list?.length && (
        <List>
          {list.map(({ id, name, modelCount }) => (
            <Tooltip
              title={isFreeTier && allowedSectors?.includes(id) ? sectorToolTip : ''}
              key={id}
            >
              <ListItem
                data-testid="filter-item"
                onClick={() => {
                  selectFilter(id);
                  Mixpanel.track('marketplace:models:sector_filter:click', {
                    sectorName: name,
                    sectorId: id
                  });
                }}
                disableGutters
                dense
                className={clsx(
                  classes.listText,
                  {
                    [classes.availableIndustry]:
                      isFreeTier && !isAllowedSectorEnabled && allowedSectors?.includes(id)
                  },
                  {
                    [classes.selected]: id === seletedFilterId
                  }
                )}
                key={id}
                button
              >
                <span className={classes.leftArea}>
                  {isFreeTier && allowedSectors?.includes(id) && (
                    <InfoIcon className={classes.infoIcon} />
                  )}
                  {name} {modelCount ? `(${modelCount})` : ''}
                </span>
                {showIcon && (
                  <ListItemIcon className={classes.icon}>
                    <ArrowRightIcon />
                  </ListItemIcon>
                )}
              </ListItem>
            </Tooltip>
          ))}
        </List>
      )}
    </>
  );
};

export default React.memo(ModelFilter);
