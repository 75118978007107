export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  SERVER_ERROR = 500
}

export const ACCESS_TOKEN_COOKIE_NAME = 'accessToken';
export const REFRESH_TOKEN_COOKIE_NAME = 'refreshToken';
export const CSRFTOKEN = 'csrftoken';

export enum AuthTokenError {
  TOKEN_EXPIRATION_ERROR = 'Signature has expired',
  AUTHENTICATION_REQUIRED_ERROR = 'AUTHENTICATION_REQUIRED',
  ERROR_DECODING_SIGNATURE = 'Error decoding signature'
}

export interface HttpClient<R = unknown, T = unknown> {
  request: (params: HttpRequest<T>) => Promise<HttpResponse<R>>;
}

export type HttpRequest<T = unknown> = {
  body?: T;
  headers?: Record<string, string>;
  queryParams?: Record<string, string>;
  method: HttpMethod;
  url: string;
};

export type HttpResponse<T = unknown> = HttpSuccess<T> | HttpError;

export type HttpSuccess<T = unknown> = {
  body: T;
  error?: never;
  message?: never;
  statusCode: HttpStatusCode;
};

export type HttpError = {
  body: never;
  error?: unknown;
  message: string;
  statusCode: HttpStatusCode;
};

export enum HttpMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT'
}
