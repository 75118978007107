import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import useLocal from 'localization';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Props from './types';

const ModelRqStatus: React.FC<Props> = ({ requestedModelList }: Props) => {
  const modelRequestStatus = useLocal('model_request_status');
  const companyName = useLocal('company_name');
  const dateText = useLocal('date');
  const noReqModel = useLocal('no_req_model');

  const StyledTableCell = withStyles((theme) => ({
    head: {
      paddingLeft: theme.spacing(3)
    },
    body: {
      paddingLeft: theme.spacing(3)
    }
  }))(TableCell);

  return (
    <>
      <Box border={1} mb={2} borderColor="border.main">
        <Box
          fontWeight="fontWeightBold"
          fontSize={16}
          p={1}
          m={0}
          py={2}
          pl={3}
          bgcolor="headingBG.main"
        >
          {modelRequestStatus}
        </Box>
        <Box m={0} p={0}>
          {requestedModelList?.length ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{companyName}</StyledTableCell>
                    <StyledTableCell>{dateText}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestedModelList.map(({ companyName, date, id }) => (
                    <TableRow key={`req-mode-${id}`}>
                      <StyledTableCell>{companyName}</StyledTableCell>
                      <StyledTableCell>{date}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box fontSize={32} p={3}>
              {noReqModel}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default React.memo(ModelRqStatus);
