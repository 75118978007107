import { gql } from '@apollo/client';
import { MODEL_SUBSCRIPTION_ERRORS } from 'domain/models';

export const MODEL_UNSUBSCRIBE_SERVICE = gql`
  mutation unsubscribeModelMutation($companyId: String!) {
    unsubscribeModel(companyId: $companyId) {
      success
      errors
    }
  }
`;

export type ModelUnsubscribeServiceResponse = {
  unsubscribeModel: {
    success: boolean;
    errors: Array<keyof typeof MODEL_SUBSCRIPTION_ERRORS>;
  };
};

export type ModelUnsubscribeServiceVariables = {
  companyId: number;
};
