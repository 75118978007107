import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  menuLink: {
    height: theme.spacing(9),
    padding: 0,
    textTransform: 'none'
  },
  iconLink: {
    minWidth: '20px',
    height: theme.spacing(9)
  },
  apiSettingsButton: {
    color: theme.palette.white.main,
    opacity: 0.7
  },
  link: {
    color: theme.palette.secondary.dark,
    margin: theme.spacing(1)
  }
}));
