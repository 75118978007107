import React from 'react';
import { CircularProgress } from '@mui/material';
import {
  GET_USER_SETTINGS_SERVICE,
  UPDATE_USER_SETTINGS_SERVICE,
  UserSettingsResponse
} from 'application/services';
import {
  Container,
  ContentHeader,
  ContentWrapper,
  OptionDescription,
  OptionTitle,
  OptionWrapper,
  PageHeader,
  SectionContentHeader,
  SectionContentWrapper,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  StyledCheckbox
} from './SettingsPage.styles';
import { settingsPageNotificationsContent } from './utils';
import { UserSettings } from 'domain/models/user-models';
import { useToastContext } from 'presentation/contexts';
import Mixpanel from 'mixPanel';
import { DeepPartial } from 'main/types/utils';
import { useStatsigClient } from '@statsig/react-bindings';
import { Navigate } from 'react-router';
import { routes } from 'main/router';
import { useMutation, useQuery } from '@apollo/client';

export const SettingsPage = () => {
  const toast = useToastContext();
  const statsig = useStatsigClient().client;

  const { data, loading } = useQuery<UserSettingsResponse>(GET_USER_SETTINGS_SERVICE);
  const [updateSetting] = useMutation(UPDATE_USER_SETTINGS_SERVICE, {
    refetchQueries: ['userSettings']
  });

  const { notifications } = data?.userSettings ?? {};

  const handleUpdateSetting = (
    sectionKey: keyof UserSettings,
    key: keyof UserSettings[keyof UserSettings]
  ) => (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    const variables: DeepPartial<UserSettings> = {};
    variables[sectionKey] = { [key]: value };

    const eventId = 'marketplaceV2:settings:update';

    let error: string | undefined = undefined;

    void updateSetting({ variables })
      .then(() => {
        toast('Settings updated successfully');
      })
      .catch((e) => {
        error = e.message;
        toast.error();
      })
      .finally(() => {
        Mixpanel.track(eventId, {
          type: 'notification',
          option: key,
          value,
          error
        });
      });
  };

  if (!statsig.checkGate('enable-marketplace-v2-settings')) {
    return <Navigate to={routes.HOME} />;
  }

  if (loading) return <CircularProgress style={{ margin: '0 auto' }} />;

  return (
    <Container>
      <PageHeader>Settings</PageHeader>
      <SectionHeader>
        <SectionTitle>Notifications</SectionTitle>
        <SectionDescription>Manage the types of notifications you receive.</SectionDescription>
      </SectionHeader>
      {settingsPageNotificationsContent.map(({ title, content }, i) => (
        <SectionContentWrapper key={i}>
          <SectionContentHeader>{title}</SectionContentHeader>
          {content.map((c, j) => (
            <ContentWrapper key={j}>
              <ContentHeader>{c.title}</ContentHeader>
              {c.options.map((opt, k) => (
                <OptionWrapper key={k}>
                  <OptionTitle>{opt.title}</OptionTitle>
                  <OptionDescription>{opt.description}</OptionDescription>
                  <StyledCheckbox
                    color="default"
                    checked={notifications?.[opt.dataKey]}
                    onChange={handleUpdateSetting('notifications', opt.dataKey)}
                  />
                </OptionWrapper>
              ))}
            </ContentWrapper>
          ))}
        </SectionContentWrapper>
      ))}
    </Container>
  );
};

export default SettingsPage;
