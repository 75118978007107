import { put, takeLatest } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import Mixpanel from 'mixPanel';
import actionTypes, { signupTrialAction } from './types';
import actions from './actions';
import { getLocalValue } from 'localization';
import { selectState } from 'store/helpers';
import { ACCESS_TOKEN_COOKIE_NAME, REFRESH_TOKEN_COOKIE_NAME } from 'application/protocols';
import { routes } from 'main/router';
import { getTokenExpiration, gqlClient } from 'infra/http';
import { gql } from '@apollo/client';

export function* signupTrialSaga({ email, password, hashcode }: ReturnType<signupTrialAction>) {
  yield put(actions.signupTrialPending());

  try {
    const {
      data: {
        createNewUser: { accessToken, refreshToken, success, errors }
      }
    } = yield gqlClient.mutate({
      mutation: gql`
        mutation createNewUser($email: String!, $password: String!, $hashcode: String!) {
          createNewUser(email: $email, password: $password, hashcode: $hashcode) {
            success
            errors
            accessToken
            refreshToken
          }
        }
      `,
      variables: { email, password, hashcode }
    });

    if (success) {
      const signupSccussful: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'signup_sccussful')
      );
      yield put(actions.signupTrialSuccess(signupSccussful));
      setTimeout(() => {
        if (accessToken && refreshToken) {
          const cookieExpireTime = getTokenExpiration(accessToken);
          Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken, { expires: cookieExpireTime });
          Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken, { expires: cookieExpireTime });
          Mixpanel.identify(email);
          Mixpanel.people.set({
            $email: email,
            $last_login: new Date()
          });
          window.location.assign(routes.HOME);
        }
      }, 500);
    } else if (errors && errors.length > 0) {
      switch (errors[0]) {
        case 'DUPLICATE_EMAIL':
          const signupDuplicateEmail: string = yield selectState<string | undefined>((state) =>
            getLocalValue(state, 'signup_duplicate_email')
          );
          yield put(actions.signupTrialError(signupDuplicateEmail));
          alert(signupDuplicateEmail);
          setTimeout(() => {
            window.location.assign(routes.HOME);
          }, 500);
          break;
        case 'WRONG_LINK':
          const signupWrongLink: string = yield selectState<string | undefined>((state) =>
            getLocalValue(state, 'signup_wrong_link')
          );
          yield put(actions.signupTrialError(signupWrongLink));
          break;
        default:
          yield put(actions.signupTrialError(errors[0]));
          break;
      }
    }
  } catch (error) {
    yield put(actions.signupTrialError((error as { message: string })?.message));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.SIGNUP_TRIAL, signupTrialSaga);
}
