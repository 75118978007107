import { put, takeLatest } from 'redux-saga/effects';
import actionTypes from './types';
import actions from './actions';
import { gqlClient } from 'infra/http';
import { gql } from '@apollo/client';

export function* getApiSettingsSaga() {
  yield put(actions.setApiSettingsLoading());

  try {
    const {
      data: { user, apiKeys }
    } = yield gqlClient.query({
      query: gql`
        query getApiToken {
          user {
            email
          }
          apiKeys {
            token
          }
        }
      `,
      fetchPolicy: 'cache-first'
    });

    const email = user?.email ?? null;
    const apiKey = apiKeys && apiKeys.length ? apiKeys[0]['token'] : null;
    yield put(actions.setApiSettings(email, apiKey));
  } catch (error) {
    yield put(actions.setApiSettingsError(error));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.GET_API_SETTINGS, getApiSettingsSaga);
}
