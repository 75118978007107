import actionTypes, { ForgetPasswordState, actionCreatorTypes } from './types';

const initialState: ForgetPasswordState = {
  isLoading: false,
  isError: false,
  error: null,
  success: false,
  failure: false
};

const reducer = (
  state: ForgetPasswordState = initialState,
  action: actionCreatorTypes
): ForgetPasswordState => {
  switch (action.type) {
    case actionTypes.REQUES_RESET_PASSWORD_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null,
        success: false,
        failure: false
      };
    case actionTypes.REQUES_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null,
        success: true,
        failure: false
      };
    case actionTypes.REQUES_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null,
        success: false,
        failure: true
      };
    case actionTypes.REQUES_RESET_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error ?? null,
        success: false,
        failure: false
      };
    case actionTypes.REQUES_RESET_PASSWORD_CLEAR:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null,
        success: false,
        failure: false
      };
    default:
      return state;
  }
};

export default reducer;
