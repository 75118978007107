import React from 'react';

export const MailIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.3334 7.99992C29.3334 6.53325 28.1334 5.33325 26.6667 5.33325H5.33341C3.86675 5.33325 2.66675 6.53325 2.66675 7.99992M29.3334 7.99992V23.9999C29.3334 25.4666 28.1334 26.6666 26.6667 26.6666H5.33341C3.86675 26.6666 2.66675 25.4666 2.66675 23.9999V7.99992M29.3334 7.99992L16.0001 17.3333L2.66675 7.99992"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
