import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { actions, modelsSelectors } from 'pages/Models/duck';
import Alert from '@material-ui/lab/Alert';

import useLocal from 'localization';

import Props from './types';
import useStyles from './style';

const UnSubscribeModelPopup: React.FC<Props> = ({ onClose }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isUnsubscribeLoading = useSelector(modelsSelectors.isUnsubscribeLoading);
  const isUnsubscribeError = useSelector(modelsSelectors.isUnsubscribeError);
  const unsubscrbieError = useSelector(modelsSelectors.unsubscrbieError);
  const unsubscribed = useSelector(modelsSelectors.unsubscribed);
  const selectedCompany = useSelector(modelsSelectors.selectedCompany);

  const wantToUnsubscribe = useLocal('want_to_unsubscribe');
  const wantToUnsubscribeModel = useLocal('want_to_unsubscribe_model');
  const unsubscribedMsg = useLocal('unsubscrbed_success_msg');
  const yes = useLocal('yes');
  const no = useLocal('no');

  useEffect(() => {
    if (unsubscribed) {
      onClose();
      dispatch(actions.resetState());
    }
  }, [dispatch, unsubscribed, onClose]);

  const handleUnsubscribe = () => {
    dispatch(actions.unSubscribeModel(selectedCompany?.id as string));
  };

  const handleClose = () => {
    onClose();
    dispatch(actions.resetState());
  };

  return (
    <>
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={true}>
        {isUnsubscribeError && (
          <Alert severity="error" data-testid="server-error">
            {unsubscrbieError}
          </Alert>
        )}

        {unsubscribed && (
          <Alert severity="success" data-testid="server-error">
            {unsubscribedMsg}
          </Alert>
        )}
        <DialogContent className={classes.contant}>
          {wantToUnsubscribe}
          {` `}
          <strong>{selectedCompany?.name}</strong> {` `}
          {wantToUnsubscribeModel}
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button onClick={handleUnsubscribe} variant="contained" color="primary">
            {isUnsubscribeLoading && <CircularProgress size={20} className={classes.spinner} />}
            {yes}
          </Button>
          <Button onClick={handleClose} variant="contained" color="primary">
            {no}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(UnSubscribeModelPopup);
