const actionTypes = {
  GET_COMPANY_LIST: 'marketplace/RequestModel/GET_COMPANY_LIST',
  SET_COMPANY_LIST: 'marketplace/RequestModel/SET_COMPANY_LIST',
  SET_COMPANY_LOADING: 'marketplace/RequestModel/SET_COMPANY_LIST_LOADING',
  SET_COMPANY_LIST_ERROR: 'marketplace/RequestModel/SET_COMPANY_LIST_ERROR',
  SET_SELECTED_COMPANY_ID: 'marketplace/RequestModel/SET_SELECTED_COMPANY_ID',
  MODEL_REQUEST: 'marketplace/RequestModel/MODEL_REQUEST'
};
export type companyObj = { id: string; ticker: string; name: string };
export type RequestModelProps = {
  isOpened: boolean;
  onClose: () => void;
};
export type RequestModelState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  companyList: companyObj[] | [];
  selectedCompanyId: string | null;
};

export type getCompanyListAction = (
  keyword: string
) => {
  type: typeof actionTypes.GET_COMPANY_LIST;
  keyword: string;
};
export type setCompanyListLoadingAction = () => {
  type: typeof actionTypes.SET_COMPANY_LOADING;
};
export type setCompanyListErrorAction = (
  error: string
) => {
  type: typeof actionTypes.SET_COMPANY_LIST_ERROR;
  error: string;
};

export type setCompanyListAction = (
  companyList: companyObj[] | []
) => {
  type: typeof actionTypes.SET_COMPANY_LIST;
  companyList: companyObj[] | [];
};

export type setSelectedCompanyIdAction = (
  selectedCompanyId: string | null
) => {
  type: typeof actionTypes.SET_SELECTED_COMPANY_ID;
  selectedCompanyId: string | null;
};

export type modelRequestAction = (
  companyId: string
) => {
  type: typeof actionTypes.MODEL_REQUEST;
  companyId: string;
};

export type actionCreatorTypes = {
  type: string;
  companyList?: companyObj[] | [];
  selectedCompanyId?: string;
  keyword?: string;
  error?: string;
};
export default actionTypes;
