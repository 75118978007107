import actionTypes, { ApiSettingsState, actionCreatorTypes } from './types';

const initialState: ApiSettingsState = {
  isLoading: false,
  isError: false,
  error: null,
  user: null,
  apiKeyById: null
};

const reducer = (
  state: ApiSettingsState = initialState,
  action: actionCreatorTypes
): ApiSettingsState => {
  switch (action.type) {
    case actionTypes.SET_API_SETTINGS:
      return {
        ...state,
        isLoading: false,
        error: null,
        isError: false,
        user: action.user ?? null,
        apiKeyById: action.apiKeyById ?? null
      };
    case actionTypes.SET_API_SETTINGS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case actionTypes.SET_API_SETTINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error ?? null
      };
    default:
      return state;
  }
};

export default reducer;
