import useStyles from './style';
import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import VerifyEmailFailed from 'components/VerifyEmailFailed';
import VerifyEmailSuccess from 'components/VerifyEmailSuccess';
import CircularProgress from '@material-ui/core/CircularProgress';
import useGetParameter from 'components/Popup/hooks/useGetParameter';
import { actions, ThankyouVerifyEmailSelectors, ThankyouVerifyEmailTypes } from './duck';

const ThankyouVerifyEmail: React.FC<ThankyouVerifyEmailTypes.ThankyouVerifyEmailProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const uid = useGetParameter('uid');
  const token = useGetParameter('token');

  const errorMsg = useSelector(ThankyouVerifyEmailSelectors.errorMsg);
  const hasError = useSelector(ThankyouVerifyEmailSelectors.hasError);
  const isLoading = useSelector(ThankyouVerifyEmailSelectors.isLoading);
  const isSuccess = useSelector(ThankyouVerifyEmailSelectors.isSuccess);

  useEffect(() => {
    dispatch(actions.verifyEmail(uid, token));
  }, [uid, token, dispatch]);

  return (
    <Dialog maxWidth="sm" open={true} aria-labelledby="form-dialog-title">
      <DialogContent>
        <DialogActions className={classes.dialogActions}>
          {isLoading ? (
            <>
              <CircularProgress size={24} className={classes.buttonProgress} />
              <Typography>We are verifying your email</Typography>
            </>
          ) : (
            <>
              {isSuccess && <VerifyEmailSuccess />}

              {hasError && <VerifyEmailFailed error={errorMsg} />}
            </>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ThankyouVerifyEmail;
