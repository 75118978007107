import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  h2: {
    margin: '0',
    textTransform: 'capitalize'
  },
  columnBox: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    background: theme.palette.primary.contrastText
  },
  rightBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      gap: '1em'
    }
  },
  columnBox2: {
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      gap: '0.5em'
    }
  },
  availableModels: {
    fontSize: '3em',
    padding: '0 0.5em',
    fontWeight: 600
  },
  btn: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderColor: 'darkblue',
    borderWidth: '0.1em',
    color: 'blue',
    padding: '0.7em',
    cursor: 'pointer'
  }
}));
