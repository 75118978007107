import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    background: theme.palette.headingBG.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'lighter',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: theme.spacing(5)
  },
  dialogContent: {
    textAlign: 'center'
  },
  dialogActions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    padding: 0,
    justifyContent: 'center'
  },
  messagesWrap: {
    fontSize: '0.8em',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));
