import { CaseReducer } from 'store/types';
import { setMenuStateReducer } from './actions';
import { generalStateClearedReducer } from './actions/generalStateCleared';
import { setFeatureFlagsReducer } from './actions/setFeatureFlags';
import { setV2BannerClosedReducer } from './actions/setV2BannerClosed';
import {
  GENERAL_REDUCER_ACTIONS,
  GENERAL_REDUCER_INITIAL_STATE,
  GeneralActionTypes,
  GeneralState
} from './generalTypes';

export const generalReducer: CaseReducer<GeneralState, GeneralActionTypes> = (
  state: GeneralState = GENERAL_REDUCER_INITIAL_STATE,
  action: GeneralActionTypes
) => {
  switch (action.type) {
    case GENERAL_REDUCER_ACTIONS.GENERAL_STATE_CLEARED:
      return generalStateClearedReducer(state, action);
    case GENERAL_REDUCER_ACTIONS.SET_FEATURE_FLAG:
      return setFeatureFlagsReducer(state, {
        payload: action.value?.featureFlags ?? { marketplaceV2Validated: false },
        type: action.type
      });
    case GENERAL_REDUCER_ACTIONS.SET_MENU_STATE:
      return setMenuStateReducer(state, {
        payload: action.value?.menuExpanded,
        type: action.type
      });
    case GENERAL_REDUCER_ACTIONS.SET_V2_BANNER_CLOSED:
      return setV2BannerClosedReducer(state, {
        payload: action.value?.v2BannerClosed,
        type: action.type
      });
    default:
      return state;
  }
};
