import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';

import { routes } from 'main/router';
import useLocal from 'localization';
import useStyles from './style';

import { UserDetailSelectors } from 'userDetail';
import { InquireHerePopupTypes } from './duck';
import { useNavigate } from 'react-router';

const InquireHerePopup: React.FC<InquireHerePopupTypes.InquireHerePopupProps> = () => {
  const navigate = useNavigate();

  const classes = useStyles();
  const isLoading = false;
  const userData = useSelector(UserDetailSelectors.getUserDetails);

  return (
    <form>
      <Dialog maxWidth="xs" fullWidth open={true} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography className={classes.title} id="form-dialog-title">
          <h2>{useLocal('free_user_inquire')}</h2>
          <IconButton data-testid="api-settings-close-btn" onClick={() => navigate(routes.HOME)}>
            <CancelIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <FormControl className={classes.formFirstName} variant="outlined">
              <InputLabel htmlFor="text" error={false}>
                {useLocal('first_name')}
              </InputLabel>
              <OutlinedInput
                id="name"
                inputProps={{ 'data-testid': 'register-email' }}
                name="firstName"
                type="text"
                labelWidth={78}
                value={userData.firstName}
              />
            </FormControl>

            <FormControl className={classes.formLastName} variant="outlined">
              <InputLabel htmlFor="text" error={false}>
                {useLocal('last_name')}
              </InputLabel>
              <OutlinedInput
                id="lastname"
                inputProps={{ 'data-testid': 'register-email' }}
                name="lastName"
                type="text"
                labelWidth={78}
                value={userData.lastName}
              />
            </FormControl>
          </Box>

          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="email" error={false}>
              {useLocal('register_email')}
            </InputLabel>
            <OutlinedInput
              id="email"
              inputProps={{ 'data-testid': 'register-email' }}
              name="email"
              type="text"
              labelWidth={111}
              value={userData.email}
            />
          </FormControl>

          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="number" error={false}>
              {useLocal('phone_number')}
            </InputLabel>
            <OutlinedInput
              id="phoneNumber"
              inputProps={{ 'data-testid': 'phone_number' }}
              name="phoneNumber"
              type="text"
              labelWidth={103}
              value={userData.phoneNumber}
            />
          </FormControl>

          <DialogActions className={classes.dialogActions}>
            <Button
              disabled={isLoading}
              type="submit"
              color="primary"
              data-testid="signup-submit"
              variant="contained"
            >
              {useLocal('free_user_inquire_now')}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default InquireHerePopup;
