import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';
import useStyles from './style';
import CircularProgress from '@material-ui/core/CircularProgress';
import { reportFundamentalErrorType } from './types';
import { isEmpty } from 'lodash';
import Mixpanel from 'mixPanel';
import { gqlClient } from 'infra/http';
import { gql } from '@apollo/client';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  fundamentalID: string | undefined;
};

const REPORT_FUNDAMENTAL_ERROR_MUTATION = gql`
  mutation reportFundamentalError($fundamentalId: Int, $errorType: String, $description: String) {
    reportFundamentalError(
      fundamentalId: $fundamentalId
      errorType: $errorType
      description: $description
    ) {
      success
      error
      id
    }
  }
`;
const WRONG_DATA_TAGGED = 'wrong data tagged';

export const ReportFundamentalErrorModal = ({ isOpen, handleClose, fundamentalID }: Props) => {
  const [description, setDescription] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      setDescription(null);
      setIsSubmitting(false);
    }
  }, [isOpen]);

  const reportFundamentalError = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await gqlClient.mutate<{
        reportFundamentalError: reportFundamentalErrorType;
      }>({
        mutation: REPORT_FUNDAMENTAL_ERROR_MUTATION,
        variables: {
          fundamentalId: fundamentalID,
          errorType: WRONG_DATA_TAGGED,
          description
        }
      });

      if (data && data?.reportFundamentalError?.success && !!data.reportFundamentalError?.id) {
        Mixpanel.track('marketplace:tag_viewer:error_report', {
          description,
          fundamentalId: fundamentalID,
          errorType: WRONG_DATA_TAGGED
        });
        handleClose();
        setIsSubmitting(false);
      }
    } catch {}
    setIsSubmitting(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Report Error</DialogTitle>
      <DialogContent>
        <DialogContentText>Please add error description and then submit error</DialogContentText>
        <TextField
          autoFocus
          variant="outlined"
          className={classes.field}
          label={'Comment'}
          inputProps={{ className: classes.input }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
          Cancel
        </Button>
        <Button
          onClick={reportFundamentalError}
          variant={'contained'}
          color={'primary'}
          disabled={isEmpty(description) || isSubmitting}
        >
          Submit
          {isSubmitting && (
            <CircularProgress size={24} classes={{ colorPrimary: classes.progressBar }} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
