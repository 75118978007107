import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AddNewModelPopup from 'components/AddNewModelPopup';

import { RequestNewModelSelectors } from './duck';
import { RequestNewModelTypes } from './duck';
import { actions } from './duck';

const RequestModel: React.FC<RequestNewModelTypes.RequestModelProps> = ({
  isOpened,
  onClose
}: RequestNewModelTypes.RequestModelProps) => {
  const dispatch = useDispatch();
  const hasError = useSelector(RequestNewModelSelectors.hasError);
  const error = useSelector(RequestNewModelSelectors.getError);
  const isLoading = useSelector(RequestNewModelSelectors.isLoading);

  return (
    <AddNewModelPopup
      isOpened={isOpened}
      hasError={hasError}
      error={error}
      isLoading={isLoading}
      onSubmit={(ticker, companyName) => dispatch(actions.requestNewModel(ticker, companyName))}
      onClose={onClose}
    />
  );
};

export default RequestModel;
