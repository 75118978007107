import actionTypes, {
  getFundamentalInfoAction,
  setFundamentalInfoAction,
  setFundamentalInfoPendingAction,
  setFundamentalInfoErrorAction,
  getCompositeFundamentalAction,
  setCompositeFundamentalAction
} from './types';

const getFundamentalInfo: getFundamentalInfoAction = (fundamentalId, isDocument = false) => ({
  type: actionTypes.GET_FUNDAMENTAL_INFO,
  fundamentalId,
  isDocument
});

const setFundamentalInfo: setFundamentalInfoAction = (fundamentalInfo) => ({
  type: actionTypes.SET_FUNDAMENTAL_INFO,
  fundamentalInfo
});

const setFundamentalInfoPending: setFundamentalInfoPendingAction = () => ({
  type: actionTypes.SET_FUNDAMENTAL_INFO_PENDING
});

const setFundamentalInfoError: setFundamentalInfoErrorAction = (error) => ({
  type: actionTypes.SET_FUNDAMENTAL_INFO_ERROR,
  error
});

const getCompositeFundamental: getCompositeFundamentalAction = (fundamentalId) => ({
  type: actionTypes.GET_COMPOSITE_FUNDAMENTAL,
  fundamentalId
});

const setCompositeFundamental: setCompositeFundamentalAction = (compositeFundamentals) => ({
  type: actionTypes.SET_COMPOSITE_FUNDAMENTAL,
  compositeFundamentals
});

export default {
  getFundamentalInfo,
  setFundamentalInfo,
  setFundamentalInfoPending,
  setFundamentalInfoError,
  getCompositeFundamental,
  setCompositeFundamental
};
