import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import Props from './types';

const ExcelIcon: React.FC<Props> = () => {
  return (
    <SvgIcon>
      <path
        d="M2.05664 0.387695C0.956641 0.387695 0.0666409 1.2877 0.0666409 2.3877L0.0566406 18.3877C0.0566406 19.4877 0.94664 20.3877 2.04664 20.3877H14.0566C15.1566 20.3877 16.0566 19.4877 16.0566 18.3877V6.3877L10.0566 0.387695H2.05664ZM9.05664 7.3877V1.8877L14.5566 7.3877H9.05664Z"
        fill="white"
      />
      <path
        d="M8.05319 11.7016L9.25319 9.54297H11.467L9.38423 13.1912L11.5566 17.005H9.33595L8.05319 14.7154L6.79112 17.005H4.55664L6.73595 13.1912L4.65319 9.54297H6.88078L8.05319 11.7016Z"
        fill="#064CA7"
      />
    </SvgIcon>
  );
};

export default ExcelIcon;
