import actionTypes, { InquireHerePopupState, actionCreatorTypes } from './types';

const initialState: InquireHerePopupState = {
  isLoading: false,
  isError: false,
  error: null,
  data: {}
};

const reducer = (
  state: InquireHerePopupState = initialState,
  action: actionCreatorTypes
): InquireHerePopupState => {
  switch (action.type) {
    case actionTypes.TEST3_ACTION:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.TEST4_ACTION:
      return {
        ...state,
        isLoading: false,
        error: null
      };

    default:
      return state;
  }
};

export default reducer;
