import actionTypes, {
  getCompanyListAction,
  setCompanyListLoadingAction,
  setCompanyListErrorAction,
  setCompanyListAction,
  setSelectedCompanyIdAction,
  modelRequestAction
} from './types';

const getCompanyList: getCompanyListAction = (keyword) => ({
  type: actionTypes.GET_COMPANY_LIST,
  keyword
});

const setCompanyListLoading: setCompanyListLoadingAction = () => ({
  type: actionTypes.SET_COMPANY_LOADING
});

const setCompanyListError: setCompanyListErrorAction = (error) => ({
  type: actionTypes.SET_COMPANY_LIST_ERROR,
  error
});
const setCompanyList: setCompanyListAction = (companyList) => ({
  type: actionTypes.SET_COMPANY_LIST,
  companyList
});

const setSelectedCompanyId: setSelectedCompanyIdAction = (selectedCompanyId) => ({
  type: actionTypes.SET_SELECTED_COMPANY_ID,
  selectedCompanyId
});

const modelRequest: modelRequestAction = (companyId) => ({
  type: actionTypes.MODEL_REQUEST,
  companyId
});

export default {
  getCompanyList,
  setCompanyListLoading,
  setCompanyListError,
  setCompanyList,
  setSelectedCompanyId,
  modelRequest
};
