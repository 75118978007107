import { useEffect, useRef, useState } from 'react';

export const useDebounce = <T extends string | number>(value: T, delay = 500) => {
  const timer = useRef<NodeJS.Timeout>();

  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    timer.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer.current);
    };
  }, [value, delay]);
  return debouncedValue;
};
