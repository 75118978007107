import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  title: {
    background: theme.palette.headingBG.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'lighter',
    paddingTop: 0,
    paddingBottom: 0
  },
  contant: {
    padding: theme.spacing(4)
  },
  formControl: {
    marginBottom: theme.spacing(3)
  }
}));
