import { gql } from '@apollo/client';
import {
  SectionById,
  ModelLivePreviewSeries,
  SectionWithSeries,
  PaginatedModelLivePreviewSeries
} from './types';
import uuid from 'react-uuid';
import { company } from '../Models/duck/types';
import { gqlClient } from 'infra/http';

export const getModelLivePreviewSections = async (
  companyId: string,
  query?: string,
  filingTypes?: string[],
  publishedOnly?: boolean
): Promise<SectionById> => {
  const {
    data: { modelLivePreviewSections }
  } = (await gqlClient.query({
    query: gql`
      query modelLivePreviewQuery(
        $companyId: String!
        $query: String
        $filingTypes: [String]
        $publishedOnly: Boolean
      ) {
        modelLivePreviewSections(
          companyId: $companyId
          query: $query
          filingTypes: $filingTypes
          publishedOnly: $publishedOnly
        ) {
          name
          seriesIds
        }
      }
    `,
    variables: {
      companyId,
      query,
      filingTypes,
      publishedOnly
    },
    fetchPolicy: 'no-cache'
  })) as { data: { modelLivePreviewSections: SectionWithSeries[] } };
  return modelLivePreviewSections.reduce((collector, section) => {
    const id = uuid();
    collector[id] = { ...section, id, series: [] };
    return collector;
  }, {} as SectionById);
};

export const getSeries = async (
  companyId: string,
  seriesIds: string[],
  page: number
): Promise<PaginatedModelLivePreviewSeries> => {
  const {
    data: { modelLivePreviewSeries }
  } = (await gqlClient.query({
    query: gql`
      query modelLivePreviewSeriesQuery($seriesIds: [Int], $companyId: String) {
        modelLivePreviewSeries(seriesIds: $seriesIds, companyId: $companyId) {
          series {
            id
            name
            rowNumber
            fundamentals {
              id
              value
              normalizedValue
              period
              context
              status
              publishedAt
              publishedIn
              createdAt
              __typename
              document {
                filing {
                  acceptedDate
                }
                filingType
                url
                createdAt
                __typename
              }
              type
            }
            document {
              filingType
              url
              createdAt
              __typename
            }
          }
          periods
          updatedPeriods
        }
      }
    `,
    variables: {
      seriesIds,
      companyId
    },
    fetchPolicy: 'no-cache'
  })) as { data: { modelLivePreviewSeries: ModelLivePreviewSeries } };
  return { data: modelLivePreviewSeries, page };
};

export const getCompany = async (companyId: string): Promise<company> => {
  const {
    data: { company }
  } = await gqlClient.query<{ company: company }>({
    query: gql`
      query company($companyId: String) {
        company(companyId: $companyId) {
          id
          ticker
          name
          favicon
          disabled
        }
      }
    `,
    variables: {
      companyId
    },
    fetchPolicy: 'no-cache'
  });
  return company;
};
