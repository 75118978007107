const actionTypes = {
  GET_INDUSTRY_MODELS_PENDING: 'marketplace/IndustryModels/GET_INDUSTRY_MODELS_PENDING',
  GET_INDUSTRY_MODELS_SUCCESS: 'marketplace/IndustryModels/GET_INDUSTRIES_PENDING',
  GET_INDUSTRY_MODELS_ERROR: 'marketplace/IndustryModels/GET_INDUSTRY_MODELS_ERROR',
  GET_INDUSTRY_MODELS: 'marketplace/IndustryModels/GET_INDUSTRY_MODELS'
};

export interface IndustryModal {
  id: string;
  industry: string;
  latestQuarter: string;
  description: string;
}

export type IndustryModelsState = {
  isIndustryModelsLoading: boolean;
  industryModelsError: string | undefined;
  industryModels: IndustryModal[];
  isIndustryModelsSelected: boolean;
};

/**
 * Industries
 */

export type getIndustryModelsAction = (
  query: string
) => {
  type: typeof actionTypes.GET_INDUSTRY_MODELS;
  query: string;
};
export type getIndustryModelsPendingAction = () => {
  type: typeof actionTypes.GET_INDUSTRY_MODELS_PENDING;
};
export type getIndustryModelsErrorAction = (
  error: string
) => {
  type: typeof actionTypes.GET_INDUSTRY_MODELS_ERROR;
  error: string;
};

export type getIndustryModelsSuccessAction = (
  industryModels: IndustryModal[]
) => {
  type: typeof actionTypes.GET_INDUSTRY_MODELS_SUCCESS;
  industryModels: IndustryModal[];
};

export default actionTypes;

export type actionCreatorTypes = {
  type: string;
  industryModels?: IndustryModal[];
  error?: string;
};
