import actionTypes, { SignUpState, actionCreatorTypes } from './types';

const initialState: SignUpState = {
  isLoading: false,
  isError: false,
  msg: null,
  success: false
};

export const SignUpReducer = (
  state: SignUpState = initialState,
  action: actionCreatorTypes
): SignUpState => {
  switch (action.type) {
    case actionTypes.SIGNUP_TRIAL_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case actionTypes.SIGNUP_TRIAL_ERROR:
      return {
        ...state,
        isLoading: false,
        msg: action.msg ?? null,
        isError: true
      };
    case actionTypes.SIGNUP_TRIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        msg: action.msg ?? null,
        isError: false,
        success: true
      };
    default:
      return state;
  }
};
