import { ActionCreatorPayload, ReducerFunction } from 'store/types';
import { AUTH_REDUCER_ACTIONS, AuthState } from '../authTypes';

type Props = AuthState['userData'];

export const setUserData = (userData: Props): ActionCreatorPayload<AuthState> => ({
  type: AUTH_REDUCER_ACTIONS.SET_USER_DATA,
  value: { userData }
});

export const setUserDataReducer: ReducerFunction<AuthState, Props> = (state, action) => ({
  ...state,
  userData: action.payload
});
