import React from 'react';
import { ItemCount } from './ModelsTable.styles';
import { Button, styled } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  margin: '12px 0 12px auto',
  width: 'fit-content',

  [theme.breakpoints.up('md')]: {
    gap: '20px',
    margin: '24px 0 24px auto'
  }
}));

const PageButton = styled(Button)(({ disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  minWidth: 'unset',
  color: disabled ? '#B8B8BA' : '#121217'
}));

type Props = {
  itemCount: string;
  page: number;
  pageCount: number;
  totalCount: number;
  onPageChange: (page: number) => void;
};

export const ModelsTablePagination = ({
  itemCount,
  onPageChange,
  page,
  pageCount,
  totalCount
}: Props) => {
  const handleFirstPage = () => {
    onPageChange(1);
  };
  const handleReturnPage = () => {
    onPageChange(page - 1);
  };
  const handleAdvancePage = () => {
    onPageChange(page + 1);
  };
  const handleLastPage = () => {
    onPageChange(pageCount);
  };
  return (
    <Container>
      <PageButton disabled={page <= 1} onClick={handleFirstPage}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.33333 11.3333L4 8.00001L7.33333 4.66667M12 11.3333L8.66667 8.00001L12 4.66667"
            stroke="currentColor"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </PageButton>
      <PageButton disabled={page <= 1} onClick={handleReturnPage}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 12L6 8L10 4"
            stroke="currentColor"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </PageButton>
      <ItemCount>
        {itemCount} of {totalCount}
      </ItemCount>
      <PageButton disabled={page >= pageCount} onClick={handleAdvancePage}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="currentColor"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </PageButton>
      <PageButton disabled={page >= pageCount} onClick={handleLastPage}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.66667 11.3333L12 8.00001L8.66667 4.66667M4 11.3333L7.33333 8.00001L4 4.66667"
            stroke="currentColor"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </PageButton>
    </Container>
  );
};
