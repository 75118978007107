import React from 'react';
import { routes } from '../routes';
import { RouteObject } from 'react-router';
import { ProtectedRoute } from '../ProtectedRoute';
import { SettingsPage } from 'presentation/pages';
import LiveModelPreview from 'pages/LiveModelPreview/LiveModelPreview';
import TagViewer from 'pages/TagViewer/TagViewer';
import TextFundamental from 'pages/TextFundamental/TextFundamental';
import Dashboard from 'pages/Dashboard/Dashboard';
import RolledUpNumbers from 'pages/RolledUpNumbers/RolledUpNumbers';

export const protectedRoutes: RouteObject[] = [
  {
    path: routes.SETTINGS,
    element: (
      <ProtectedRoute>
        <SettingsPage />
      </ProtectedRoute>
    )
  },
  {
    path: routes.LIVE_PREVIEW,
    element: (
      <ProtectedRoute redirectToV1>
        <LiveModelPreview />
      </ProtectedRoute>
    )
  },
  {
    path: routes.TAG_VIEWER,
    element: (
      <ProtectedRoute redirectToV1>
        <TagViewer />
      </ProtectedRoute>
    )
  },
  {
    path: routes.DOC_VIEWER,
    element: (
      <ProtectedRoute redirectToV1>
        <TagViewer isDocument />
      </ProtectedRoute>
    )
  },
  {
    path: routes.TEXT_FUNDAMENTAL,
    element: (
      <ProtectedRoute redirectToV1>
        <TextFundamental />
      </ProtectedRoute>
    )
  },
  {
    path: routes.DASHBOARD,
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    )
  },
  {
    path: routes.ROLLUP_NUMBERS,
    element: (
      <ProtectedRoute>
        <RolledUpNumbers />
      </ProtectedRoute>
    )
  }
];
