import { Checkbox, styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '48px 0'
});

export const PageHeader = styled('div')({
  width: '100%',
  fontSize: '34px',
  fontWeight: 700
});

export const SectionHeader = styled('div')({
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  width: '100%',
  marginTop: '42px'
});
export const SectionTitle = styled('div')({
  fontSize: '24px',
  fontWeight: 700
});
export const SectionDescription = styled('div')({
  fontSize: '14px',
  color: '#00000099'
});

export const SectionContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '42px',
  borderTop: '1px solid #292B2E'
});

export const SectionContentHeader = styled('div')({
  width: '100%',
  marginTop: '20px',
  fontWeight: 700
});
export const SectionContentTitle = styled('div')({
  fontSize: '24px',
  fontWeight: 700
});

export const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  width: '100%',
  marginTop: '32px',
  marginLeft: 'auto',
  borderTop: '1px solid #292B2E',

  [theme.breakpoints.up('md')]: {
    maxWidth: '60%'
  }
}));

export const ContentHeader = styled('div')({
  width: '100%',
  paddingTop: '20px',
  fontWeight: 500,
  fontSize: '16px'
});

export const OptionWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  width: '100%',
  paddingRight: '48px'
});
export const OptionTitle = styled('div')({
  fontSize: '16px'
});
export const OptionDescription = styled('div')({
  fontSize: '14px',
  color: '#00000099'
});

export const StyledCheckbox = styled(Checkbox)({
  position: 'absolute',
  right: 0,
  top: 0
});
