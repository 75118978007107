import { put, takeLatest } from 'redux-saga/effects';
import actionTypes, { requestResetPasswordAction } from './types';
import actions from './actions';
import { gqlClient } from 'infra/http';
import { gql } from '@apollo/client';

export function* resetPasswordRequestSaga({ email }: ReturnType<requestResetPasswordAction>) {
  yield put(actions.requestResetPasswordPending());

  try {
    const {
      data: { resetUserPassword }
    } = yield gqlClient.mutate({
      mutation: gql`
        mutation sendPasswordResetLinkMutation($email: String!) {
          resetUserPassword(email: $email) {
            success
          }
        }
      `,
      variables: {
        email
      },
      fetchPolicy: 'no-cache'
    });
    if (resetUserPassword?.success) {
      yield put(actions.requestResetPasswordSuccess());
    } else {
      yield put(actions.requestResetPasswordFailure());
    }
  } catch (error) {
    yield put(actions.requestResetPasswordError((error as { message: string })?.message));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.REQUES_RESET_PASSWORD, resetPasswordRequestSaga);
}
