import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Props from './types';

const ModelListSkeleton: React.FC<Props> = ({
  showList,
  numberOfIndustry,
  numberOfModel
}: Props) => {
  return (
    <>
      {[...Array(numberOfIndustry)].map((data, index) => (
        <Box key={`skeleton-parent-${index}`} border={1} mb={2} borderColor="border.main">
          <Box
            fontWeight="fontWeightBold"
            fontSize={16}
            p={1}
            m={0}
            pl={3}
            bgcolor="headingBG.main"
            key="skeleton-heading"
          >
            <Skeleton variant="rect" />
          </Box>
          <Box m={0} p={3} key="skeleton-contaner">
            {showList ? (
              <>
                {[...Array(numberOfModel)].map((dataItem, index) => (
                  <Skeleton key={`skeleton-table-${dataItem}-${index}`} height={44} />
                ))}
              </>
            ) : (
              <Grid container spacing={3}>
                {[...Array(numberOfModel)].map((dataItem, index) => (
                  <Grid key={`skeleton-grid-item-${dataItem}-${index}`} item xs={12} sm={12} md={4}>
                    <Box pb={1} key="skeleton-box1">
                      <Skeleton variant="rect" height={20} />
                    </Box>
                    <Box pb={1} key="skeleton-box2">
                      <Skeleton variant="rect" height={65} />
                    </Box>
                    <Box key="skeleton-box3">
                      <Skeleton variant="rect" height={36} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default React.memo(ModelListSkeleton);
