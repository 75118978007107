import actionTypes, {
  requestNewModelAction,
  setRequestNewModelLoadingAction,
  setRequestNewModelErrorAction
} from './types';

const requestNewModel: requestNewModelAction = (ticker, companyName) => ({
  type: actionTypes.MODEL_NEW_REQUEST,
  ticker,
  companyName
});

const setRequestNewModelLoading: setRequestNewModelLoadingAction = () => ({
  type: actionTypes.SET_MODEL_NEW_REQUES_LOADING
});

const setRequestNewModelError: setRequestNewModelErrorAction = (error) => ({
  type: actionTypes.SET_MODEL_NEW_REQUES_ERROR,
  error
});

export default {
  requestNewModel,
  setRequestNewModelLoading,
  setRequestNewModelError
};
