import { put, takeLatest } from 'redux-saga/effects';
import { selectState } from 'store/helpers';
import { getLocalValue } from 'localization';
import actionTypes, { getIndustryModelsAction, IndustryModal } from './types';
import actions from './actions';
import { gqlClient } from 'infra/http';
import { gql } from '@apollo/client';

export function* getIndustryModelsSaga({ query }: ReturnType<getIndustryModelsAction>) {
  yield put(actions.getIndustryModelsPending());
  try {
    const {
      data: { liveIndustryModels }
    } = yield gqlClient.query<{ liveIndustryModels: IndustryModal[] }>({
      query: gql`
        query liveIndustryModels($query: String) {
          liveIndustryModels(query: $query) {
            id
            description
            industry
            latestQuarter
          }
        }
      `,
      fetchPolicy: 'cache-first',
      variables: {
        query
      }
    });
    yield put(actions.setIndustryModelsSuccess(liveIndustryModels));
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getIndustryModelsError(errorMsg));
  }
}

export default function* IndustryModelsSaga() {
  yield takeLatest(actionTypes.GET_INDUSTRY_MODELS, getIndustryModelsSaga);
}
