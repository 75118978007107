import actionTypes, { RequestModelState, actionCreatorTypes } from './types';

const initialState: RequestModelState = {
  isLoading: false,
  isError: false,
  error: null,
  companyList: [],
  selectedCompanyId: null
};

const reducer = (
  state: RequestModelState = initialState,
  action: actionCreatorTypes
): RequestModelState => {
  switch (action.type) {
    case actionTypes.SET_COMPANY_LOADING:
      return {
        ...state,
        isLoading: true,
        companyList: []
      };
    case actionTypes.SET_COMPANY_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        companyList: [],
        error: action?.error ?? null
      };
    case actionTypes.SET_COMPANY_LIST:
      return {
        ...state,
        isLoading: false,
        error: null,
        companyList: action.companyList ?? []
      };
    case actionTypes.SET_SELECTED_COMPANY_ID:
      return {
        ...state,
        selectedCompanyId: action.selectedCompanyId ?? null
      };

    default:
      return state;
  }
};

export default reducer;
