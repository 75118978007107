import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import LookupField from 'components/LookupField';
import useLocal from 'localization';
import { routes } from 'main/router';

import Props from './types';
import useStyles from './style';

const RequestModelPopup: React.FC<Props> = ({
  isOpened,
  searchCompany,
  isLoading,
  companyList,
  hasError,
  error,
  onSelect,
  onSubmit,
  selectedCompanyId,
  onClose
}: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const requestModel = useLocal('request_model_btn');
  const requestModelText = useLocal('request_model_text');
  const submitBtnLabel = useLocal('submit_btn_label');
  const connotFindModelText = useLocal('cannot_find_model_text');
  const addManually = useLocal('add_manually');

  return (
    <Dialog
      fullWidth
      open={isOpened}
      onClose={onClose}
      maxWidth="sm"
      data-testid="request-model-popup"
    >
      <DialogTitle disableTypography className={classes.title}>
        <h2 data-testid="request-model-title">{requestModel}</h2>
        <IconButton data-testid="request-model-close-btn" onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contant} data-testid="request-model-containt">
        {hasError && (
          <Alert severity="error" data-testid="request-model-error">
            {error}
          </Alert>
        )}
        <p>{requestModelText}</p>
        <LookupField
          isLoading={isLoading}
          options={companyList}
          doSearch={searchCompany}
          onSelect={onSelect}
          throttle
        />
        <DialogActions className={classes.action}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            data-testid="request-model-submit-btn"
            onClick={() => {
              if (selectedCompanyId) {
                onSubmit(selectedCompanyId);
                setLoading(true);
              }
            }}
            disabled={selectedCompanyId === null || loading}
          >
            {submitBtnLabel}
            {loading && <CircularProgress size={24} />}
          </Button>
        </DialogActions>
        <Box mt={4} paddingTop={4} borderTop={1} borderColor="headingBG.main">
          {connotFindModelText}{' '}
          <Link to={routes.ADD_NEW_MODEL} data-testid="add-manually-link">
            {addManually}
          </Link>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(RequestModelPopup);
