import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('xl')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2em'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '5em'
    }
  },
  iframe: {
    width: '100%',
    height: '80vh'
  }
}));
