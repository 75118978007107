import actionTypes, { RequestNewModelState, actionCreatorTypes } from './types';

const initialState: RequestNewModelState = {
  isLoading: false,
  isError: false,
  error: null
};

const reducer = (
  state: RequestNewModelState = initialState,
  action: actionCreatorTypes
): RequestNewModelState => {
  switch (action.type) {
    case actionTypes.SET_MODEL_NEW_REQUES_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case actionTypes.SET_MODEL_NEW_REQUES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action?.error ?? null
      };

    default:
      return state;
  }
};

export default reducer;
