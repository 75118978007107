import React from 'react';

export const SadFaceIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.3" cx="24.5" cy="25" r="23" stroke="#707073" strokeWidth="3" />
    <circle cx="16.5" cy="19.5" r="2.5" fill="#D9D9D9" />
    <circle cx="33.5" cy="19.5" r="2.5" fill="#D9D9D9" />
    <path
      d="M17 33C20.208 29 29.8556 29 32.9997 33"
      stroke="#CFD1D1"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
