import React from 'react';

export const DaloopaLogoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="111"
    height="34"
    viewBox="0 0 111 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.519 23.5933C10.2841 23.5933 9.16166 23.312 8.17931 22.7201C7.19696 22.156 6.41164 21.3677 5.82195 20.3538C5.26001 19.3677 4.97974 18.2409 4.97974 17.0014C4.97974 15.7618 5.26001 14.6351 5.82195 13.649C6.38389 12.663 7.14146 11.8747 8.09467 11.2827C9.04927 10.7187 10.144 10.4095 11.3497 10.4095C12.3876 10.4095 13.3422 10.6351 14.2122 11.085C15.0544 11.5362 15.7564 12.1267 16.2615 12.8593V5.92897C16.2615 5.64763 16.3461 5.42201 16.514 5.25348C16.6819 5.08496 16.8789 5 17.1592 5C17.4395 5 17.6365 5.08496 17.8321 5.25348C18 5.42201 18.0847 5.64763 18.0847 5.92897V17.085C18.0569 18.3245 17.7475 19.4234 17.187 20.4095C16.625 21.3955 15.8397 22.1838 14.8573 22.7479C13.8472 23.312 12.7525 23.5933 11.5176 23.5933H11.519ZM11.519 21.9304C12.4167 21.9304 13.2312 21.7047 13.961 21.2827C14.6631 20.8607 15.2528 20.2688 15.6454 19.5084C16.0659 18.7758 16.2629 17.9304 16.2629 16.9721C16.2629 16.0139 16.0659 15.1978 15.6454 14.4652C15.225 13.7047 14.6631 13.1407 13.961 12.6908C13.2589 12.2688 12.4459 12.0432 11.519 12.0432C10.5922 12.0432 9.80685 12.2688 9.10477 12.6908C8.37495 13.1128 7.81301 13.7047 7.3926 14.4652C6.97219 15.2256 6.74741 16.0432 6.74741 16.9721C6.74741 17.9011 6.97219 18.7758 7.3926 19.5084C7.81301 20.2409 8.4027 20.8329 9.10477 21.2827C9.8346 21.7047 10.6199 21.9304 11.519 21.9304Z"
      fill="white"
    />
    <path
      d="M28.1344 23.5933C26.9273 23.5933 25.8326 23.312 24.8793 22.72C23.9247 22.156 23.1672 21.3677 22.6066 20.3537C22.0447 19.3677 21.7644 18.2409 21.7644 17.0014C21.7644 15.7618 22.0447 14.6072 22.6344 13.6211C23.1963 12.6351 23.9816 11.8468 24.9917 11.2549C25.9741 10.6908 27.0966 10.3816 28.3314 10.3816C29.5663 10.3816 30.6888 10.6629 31.6712 11.2549C32.6535 11.8189 33.4388 12.6072 34.0008 13.6211C34.5627 14.6072 34.8707 15.734 34.8707 17.0014L34.1409 17.5654C34.1409 18.6922 33.8884 19.7354 33.3556 20.6365C32.8228 21.5376 32.1207 22.2702 31.223 22.7771C30.2961 23.312 29.286 23.5947 28.1358 23.5947L28.1344 23.5933ZM28.3314 21.9303C29.2292 21.9303 30.0436 21.7047 30.7734 21.2827C31.4755 20.8607 32.0652 20.2688 32.4579 19.5083C32.8783 18.7479 33.0753 17.9303 33.0753 17.0014C33.0753 16.0724 32.8783 15.1977 32.4579 14.4652C32.0375 13.7326 31.4755 13.1406 30.7734 12.6908C30.0714 12.2688 29.2583 12.0432 28.3314 12.0432C27.4046 12.0432 26.6193 12.2688 25.9172 12.6908C25.1874 13.1128 24.6254 13.7047 24.205 14.4652C23.7846 15.1977 23.5598 16.0432 23.5598 17.0014C23.5598 17.9596 23.7846 18.7757 24.205 19.5083C24.6254 20.2688 25.2151 20.8328 25.9172 21.2827C26.6193 21.7047 27.4323 21.9303 28.3314 21.9303ZM33.9453 23.4805C33.665 23.4805 33.468 23.3955 33.2723 23.227C33.1044 23.0585 33.0198 22.8328 33.0198 22.5794V18.5223L33.4694 16.6629L34.8721 17.0292V22.5794C34.8721 22.8607 34.7875 23.0585 34.6196 23.227C34.4517 23.3955 34.2269 23.4805 33.9467 23.4805H33.9453Z"
      fill="white"
    />
    <path
      d="M41.3266 23.4804C40.7092 23.4804 40.1472 23.3119 39.6422 22.9735C39.1371 22.635 38.7722 22.1559 38.4919 21.5361C38.2117 20.9164 38.0715 20.2116 38.0715 19.3955V5.89965C38.0715 5.61831 38.1562 5.42054 38.3241 5.25202C38.4919 5.08349 38.7167 4.99854 38.9692 4.99854C39.2495 4.99854 39.4465 5.08349 39.6144 5.25202C39.7823 5.42054 39.867 5.64617 39.867 5.89965V19.3662C39.867 20.0417 40.0071 20.6058 40.2874 21.0278C40.5676 21.4498 40.9048 21.6754 41.3252 21.6754H41.9149C42.1397 21.6754 42.3353 21.7604 42.4768 21.9289C42.617 22.0974 42.7016 22.323 42.7016 22.5765C42.7016 22.8579 42.617 23.0556 42.4213 23.2242C42.2243 23.3927 41.9718 23.4776 41.6638 23.4776H41.3266V23.4804Z"
      fill="white"
    />
    <path
      d="M50.5036 23.5934C49.241 23.5934 48.1185 23.3121 47.1362 22.748C46.1538 22.1839 45.3685 21.3956 44.8066 20.4096C44.2446 19.4235 43.9644 18.2968 43.9644 17.0293C43.9644 15.7619 44.2446 14.606 44.8066 13.6199C45.3685 12.6338 46.1538 11.8455 47.1362 11.2814C48.1185 10.7174 49.241 10.436 50.5036 10.436C51.7663 10.436 52.8888 10.7174 53.8711 11.2814C54.8535 11.8455 55.6388 12.6338 56.2007 13.6199C56.7627 14.606 57.0429 15.7605 57.0707 17.0293C57.0707 18.2968 56.7904 19.4235 56.2007 20.4096C55.6388 21.3956 54.8535 22.1839 53.8711 22.748C52.861 23.3121 51.7385 23.5934 50.5036 23.5934ZM50.5036 21.9305C51.4014 21.9305 52.2436 21.7048 52.9456 21.2828C53.6755 20.8608 54.2374 20.2689 54.6301 19.5363C55.0505 18.8037 55.2475 17.9583 55.2475 17.0001C55.2475 16.0419 55.0505 15.1965 54.6301 14.4639C54.2097 13.7034 53.6477 13.1394 52.9456 12.6895C52.2158 12.2675 51.4014 12.0419 50.5036 12.0419C49.6059 12.0419 48.7637 12.2675 48.0616 12.6895C47.3318 13.1115 46.7699 13.7034 46.3495 14.4639C45.9291 15.2243 45.732 16.0697 45.732 17.0001C45.732 17.9305 45.9291 18.8037 46.3495 19.5363C46.7699 20.2689 47.3318 20.8608 48.0616 21.2828C48.7637 21.7048 49.5768 21.9305 50.5036 21.9305Z"
      fill="white"
    />
    <path
      d="M66.5862 23.5934C65.3235 23.5934 64.2011 23.3121 63.2187 22.748C62.2364 22.1839 61.451 21.3956 60.8891 20.4096C60.3272 19.4235 60.0469 18.2968 60.0469 17.0293C60.0469 15.7619 60.3272 14.606 60.8891 13.6199C61.451 12.6338 62.2364 11.8455 63.2187 11.2814C64.2011 10.7174 65.3235 10.436 66.5862 10.436C67.8488 10.436 68.9713 10.7174 69.9536 11.2814C70.936 11.8455 71.7213 12.6338 72.2832 13.6199C72.8452 14.606 73.1254 15.7605 73.1532 17.0293C73.1532 18.2968 72.8729 19.4235 72.2832 20.4096C71.7213 21.3956 70.936 22.1839 69.9536 22.748C68.9713 23.3121 67.8488 23.5934 66.5862 23.5934ZM66.5862 21.9305C67.4839 21.9305 68.3261 21.7048 69.0282 21.2828C69.758 20.8608 70.3199 20.2689 70.7126 19.5363C71.133 18.8037 71.33 17.9583 71.33 17.0001C71.33 16.0419 71.133 15.1965 70.7126 14.4639C70.2922 13.7034 69.7302 13.1394 69.0282 12.6895C68.2983 12.2675 67.4839 12.0419 66.5862 12.0419C65.6885 12.0419 64.8462 12.2675 64.1442 12.6895C63.4143 13.1115 62.8524 13.7034 62.432 14.4639C62.0116 15.2243 61.8146 16.0697 61.8146 17.0001C61.8146 17.9305 62.0116 18.8037 62.432 19.5363C62.8524 20.2689 63.4143 20.8608 64.1442 21.2828C64.8462 21.7048 65.6593 21.9305 66.5862 21.9305Z"
      fill="white"
    />
    <path
      d="M77.7292 29.0013C77.4489 29.0013 77.2241 28.9163 77.0562 28.7478C76.8883 28.5793 76.8037 28.3536 76.8037 28.0723V16.9163C76.8315 15.6768 77.1131 14.5779 77.6737 13.5918C78.2342 12.6057 79.0209 11.8174 80.0033 11.2534C80.9856 10.6893 82.0804 10.408 83.3152 10.408C84.5501 10.408 85.7004 10.6893 86.6827 11.2812C87.6651 11.8453 88.4504 12.6336 89.0401 13.6475C89.602 14.6336 89.91 15.7603 89.91 17.0277C89.91 18.2951 89.6298 19.394 89.0678 20.3801C88.5059 21.3662 87.7483 22.1545 86.7937 22.7464C85.8391 23.3105 84.7444 23.6197 83.5386 23.6197C82.5008 23.6197 81.5462 23.394 80.6762 22.9442C79.8063 22.4929 79.1319 21.9024 78.6269 21.1698V28.128C78.6269 28.4094 78.5422 28.635 78.3744 28.8035C78.2065 28.9163 77.9817 29.0013 77.7292 29.0013ZM83.343 21.9302C84.2407 21.9302 85.0829 21.7046 85.785 21.2826C86.5148 20.8606 87.0768 20.2687 87.4972 19.5082C87.9176 18.7478 88.1146 17.9302 88.1146 17.0013C88.1146 16.0723 87.9176 15.1977 87.4972 14.4651C87.0768 13.7325 86.5148 13.1405 85.785 12.6907C85.0552 12.2687 84.2407 12.0431 83.343 12.0431C82.4453 12.0431 81.6308 12.2687 80.9288 12.6907C80.1989 13.1127 79.637 13.7046 79.2443 14.4651C78.8239 15.1977 78.6269 16.0431 78.6269 17.0013C78.6269 17.9595 78.8239 18.7756 79.2443 19.5082C79.6647 20.2687 80.2267 20.8328 80.9288 21.2826C81.6308 21.7046 82.4439 21.9302 83.343 21.9302Z"
      fill="white"
    />
    <path
      d="M99.284 23.5933C98.0769 23.5933 96.9821 23.312 96.0289 22.72C95.0743 22.156 94.3167 21.3677 93.7548 20.3537C93.1929 19.3677 92.9126 18.2409 92.9126 17.0014C92.9126 15.7618 93.1929 14.6072 93.7826 13.6211C94.3445 12.6351 95.1298 11.8468 96.1399 11.2549C97.1223 10.6908 98.2448 10.3816 99.4796 10.3816C100.715 10.3816 101.837 10.6629 102.819 11.2549C103.802 11.8189 104.587 12.6072 105.149 13.6211C105.711 14.6072 106.019 15.734 106.019 17.0014L105.289 17.5654C105.289 18.6922 105.037 19.7354 104.504 20.6365C103.971 21.5376 103.269 22.2702 102.371 22.7771C101.444 23.312 100.434 23.5947 99.284 23.5947V23.5933ZM99.481 21.9303C100.379 21.9303 101.193 21.7047 101.923 21.2827C102.625 20.8607 103.215 20.2688 103.607 19.5083C104.028 18.7479 104.225 17.9303 104.225 17.0014C104.225 16.0724 104.028 15.1977 103.607 14.4652C103.187 13.7326 102.625 13.1406 101.923 12.6908C101.221 12.2688 100.408 12.0432 99.481 12.0432C98.5542 12.0432 97.7688 12.2688 97.0668 12.6908C96.3369 13.1128 95.775 13.7047 95.3546 14.4652C94.9342 15.1977 94.7094 16.0432 94.7094 17.0014C94.7094 17.9596 94.9342 18.7757 95.3546 19.5083C95.775 20.2688 96.3647 20.8328 97.0668 21.2827C97.7688 21.7047 98.5819 21.9303 99.481 21.9303ZM105.095 23.4805C104.815 23.4805 104.618 23.3955 104.422 23.227C104.254 23.0585 104.169 22.8328 104.169 22.5794V18.5223L104.619 16.6629L106.022 17.0292V22.5794C106.022 22.8607 105.937 23.0585 105.769 23.227C105.601 23.3955 105.377 23.4805 105.096 23.4805H105.095Z"
      fill="white"
    />
  </svg>
);
