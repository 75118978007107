import React from 'react';

export const BookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.33325 26.0001C5.33325 25.116 5.68444 24.2682 6.30956 23.6431C6.93468 23.0179 7.78253 22.6667 8.66659 22.6667H26.6666M5.33325 26.0001C5.33325 26.8841 5.68444 27.732 6.30956 28.3571C6.93468 28.9822 7.78253 29.3334 8.66659 29.3334H26.6666V2.66675H8.66659C7.78253 2.66675 6.93468 3.01794 6.30956 3.64306C5.68444 4.26818 5.33325 5.11603 5.33325 6.00008V26.0001Z"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
