import React from 'react';

export const MenuExpandIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 9H21M6 16H21M6 23H21"
      stroke="#F2F2F2"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27 18.7929V13.2071C27 12.7617 27.5386 12.5386 27.8536 12.8536L30.6464 15.6464C30.8417 15.8417 30.8417 16.1583 30.6464 16.3536L27.8536 19.1464C27.5386 19.4614 27 19.2383 27 18.7929Z"
      fill="#F2F2F2"
    />
  </svg>
);
