import en from './local/en.json';
import type { localizationReducerType } from './type';
const language: { [index: string]: { [index: string]: string } } = {
  en: en
};
const initialState: localizationReducerType = {
  local: {},
  lang: 'en',
  showTranslationKeys: false
};

export const SET_LANGUAGE = 'localization/SET_LANGUAGE';
export const SET_SHOW_TRANSLATION_KEYS = 'localization/SET_SHOW_TRANSLATION_KEYS';

export default function localizationReducer(
  state = initialState,
  action:
    | { type: typeof SET_LANGUAGE; lang: string }
    | { type: typeof SET_SHOW_TRANSLATION_KEYS; showTranslationKeys: boolean }
) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.lang,
        local: language[action.lang]
      };
    case SET_SHOW_TRANSLATION_KEYS:
      return {
        ...state,
        showTranslationKeys: action.showTranslationKeys
      };

    default:
      return state;
  }
}
