import React from 'react';

export const HamburgerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.75 6H2.25V3H15.75V6ZM15.75 7.5H2.25V10.5H15.75V7.5ZM15.75 12H2.25V15H15.75V12Z"
      fill="#404245"
    />
  </svg>
);
