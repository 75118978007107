import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  unSubscribe: {
    color: theme.palette.error.main
  },
  modelPreviewLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    verticalAlign: 'text-top',
    marginRight: theme.spacing(1)
  }
}));
