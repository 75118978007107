import UserDetailReducer from './reducer';
import UserDetailSelectors from './selector';
import UserDetailSaga from './saga';
import * as UserDetailTypes from './types';
import actionTypes from './types';
import actions from './actions';

export { UserDetailSelectors, UserDetailSaga, UserDetailTypes, actionTypes, actions };

export default UserDetailReducer;
