/* global clearTimeout */
import { useState, useEffect, useMemo, useRef } from 'react';

import { PARAMS } from '../constants';
import useGetParameter from './useGetParameter';

export default () => {
  const popupName = useGetParameter(PARAMS.popup);
  const [mountedPopup, setMountedPopup] = useState(popupName);
  const ref = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (popupName) {
      ref.current && clearTimeout(ref.current);
      setMountedPopup(popupName);
    } else {
      ref.current = setTimeout(() => {
        setMountedPopup(null);
      }, 300);
    }
  }, [popupName]);

  useEffect(() => {
    return () => {
      ref.current && clearTimeout(ref.current);
    };
  }, []);

  const isOpened: boolean = useMemo(() => Boolean(popupName), [popupName]);

  return {
    mountedPopup,
    isOpened
  };
};
