import { put, takeLatest } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { selectState } from 'store/helpers';
import actionTypes, {
  LoginRequestActionCreatorType,
  LoginResponseType,
  actionQueueType,
  ResendEmailRequestActionCreatorType,
  ResendEmailResponseType
} from './types';
import selector from './selector';
import actions from './actions';
import { routes } from 'main/router';
import Mixpanel from 'mixPanel';
import InitializeIntercom from 'intercom';
import { getLocalValue } from 'localization';
import { logout as authLogout } from 'core/modules/auth.utils';
import { actions as userDetailAction } from 'userDetail';
import { getTokenExpiration, gqlClient } from 'infra/http';
import { gql } from '@apollo/client';
import { ACCESS_TOKEN_COOKIE_NAME, REFRESH_TOKEN_COOKIE_NAME } from 'application/protocols';

export function* logout() {
  Mixpanel.track('marketplace:models:log_out');
  yield authLogout(routes.HOME);
}

export function* loginRequest({ email, password, redirectTo }: LoginRequestActionCreatorType) {
  try {
    yield put(actions.loginRequestPending());
    const {
      data: { tokenAuth }
    }: LoginResponseType = yield gqlClient.mutate({
      mutation: gql`
        mutation tokenAuthMutation($email: String!, $password: String!) {
          tokenAuth(email: $email, password: $password) {
            token
            refreshToken
          }
        }
      `,
      variables: {
        email,
        password
      },
      fetchPolicy: 'no-cache'
    });

    if (tokenAuth?.token && tokenAuth?.refreshToken) {
      const accessToken = tokenAuth.token;
      const refreshToken = tokenAuth.refreshToken;
      const cookieExpireTime = getTokenExpiration(accessToken);
      Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken, { expires: cookieExpireTime });
      Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken, { expires: cookieExpireTime });
      yield put(actions.loginRequestSuccess(accessToken, refreshToken, email));

      yield put(userDetailAction.getUserDetails());

      Mixpanel.identify(email);
      Mixpanel.people.set({
        $email: email,
        $last_login: new Date()
      });
      if (process.env.REACT_APP_ENABLE_INTERCOM === 'true') {
        InitializeIntercom();
      }

      const actionQueue: actionQueueType = yield selectState<actionQueueType>((state) =>
        selector.getActionQueue(state)
      );
      if (actionQueue) {
        yield put(actionQueue);
      }

      window.location.assign(redirectTo ?? routes.HOME);
    }
  } catch (error) {
    const acNotVerifiedError: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'account_not_verified')
    );

    const geniricError: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );

    const acNotRegisteredErr: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'email_not_registered')
    );

    const acNotRegistered: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'account_not_registered')
    );
    const resetPasswordExpired: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'reset_password_expired')
    );
    const errorMsg = (error as { message: string })?.message ?? geniricError;
    if (errorMsg === acNotVerifiedError) {
      yield put(actions.loginRequestErorr(errorMsg, true));
    } else if (errorMsg === acNotRegisteredErr) {
      const errorMsg = acNotRegistered ?? geniricError;
      yield put(actions.loginRequestErorr(errorMsg));
    } else if (errorMsg === resetPasswordExpired) {
      yield put(actions.loginRequestErorr(errorMsg, false, true));
    } else {
      yield put(actions.loginRequestErorr(errorMsg));
    }
  }
}

export function* resendEmailRequest({ email }: ResendEmailRequestActionCreatorType) {
  try {
    yield put(actions.resendEmailRequestPending());
    const {
      data: {
        resendVerificationEmail: { success, errors }
      }
    }: ResendEmailResponseType = yield gqlClient.mutate({
      mutation: gql`
        mutation resendVerificationEmailMutation($email: String!) {
          resendVerificationEmail(email: $email) {
            success
            errors
          }
        }
      `,
      variables: {
        email
      },
      fetchPolicy: 'no-cache'
    });

    if (success) {
      yield put(actions.resendEmailRequestSuccess());
      // window.location.assign(`${routes.REGISTER_THANKYOU_POPUP}?email=${email}`);
    } else {
      const acNotRegisteredErr: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'email_not_registered')
      );

      const geniricError: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'error_msg')
      );

      const acNotRegistered: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'account_not_registered')
      );

      if (errors[0] === acNotRegisteredErr) {
        const errorMsg = acNotRegistered;
        yield put(actions.resendEmailRequestErorr(errorMsg));
      } else {
        const errorMsg = geniricError;
        yield put(actions.resendEmailRequestErorr(errorMsg));
      }
    }
  } catch (error) {
    const errorMsg = (error as { message: string })?.message;
    yield put(actions.resendEmailRequestErorr(errorMsg));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.LOGIN_REQUEST, loginRequest);
  yield takeLatest(actionTypes.RESEND_EMAIL_REQUEST, resendEmailRequest);
  yield takeLatest(actionTypes.LOGOUT, logout);
}
