import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  StatsigProvider as SProvider,
  StatsigOptions,
  StatsigUser,
  useStatsigClient,
  useStatsigUser
} from '@statsig/react-bindings';
import { UserDetailSelectors } from 'userDetail';
import { setFeatureFlags } from 'store';
import { generalState, setUserData, setV2BannerClosed } from 'store/slices';
import { useLocation, useNavigate } from 'react-router';
import { isLoggedIn } from 'infra/http';

const STATSIG_CLIENT_SDK_KEY = 'client-6AfgU0iJZZ53gLe7FEshXvAX1pPqXDwLqJSa8uQNwT3';

const options: StatsigOptions = {
  environment: { tier: process.env.REACT_APP_ENV ?? 'development' }
};

const StatsigInner = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { updateUserAsync } = useStatsigUser();
  const { isAuthenticated, tokenData } = isLoggedIn();
  const { client } = useStatsigClient();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const v2StatsigEnabled = client.checkGate('enable-marketplace-v2');

  const isMarketplaceV2Enabled = useMemo(() => !params.get('v1') && v2StatsigEnabled, [
    params,
    v2StatsigEnabled
  ]);

  const email = useSelector(UserDetailSelectors.getUserDetails)?.email ?? tokenData?.email ?? '';
  const { marketplaceV2Validated, isMarketplaceV2Enabled: isV2Enabled } = useSelector(
    generalState
  ).featureFlags;

  const user: StatsigUser = useMemo(() => {
    if (!isAuthenticated) {
      return {
        userID: 'anonymous',
        email: ''
      };
    }

    return {
      userID: email,
      email
    };
  }, [email, isAuthenticated]);

  useEffect(() => {
    void updateUserAsync(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    dispatch(setUserData(email ? { email } : undefined));
  }, [dispatch, email, isMarketplaceV2Enabled]);

  useEffect(() => {
    if (client.loadingStatus === 'Ready') {
      dispatch(setFeatureFlags({ isMarketplaceV2Enabled, marketplaceV2Validated: true }));
      localStorage.setItem('isMarketplaceV2Enabled', isMarketplaceV2Enabled.toString());
      dispatch(setV2BannerClosed(!v2StatsigEnabled));
    }
  }, [client.loadingStatus, dispatch, isMarketplaceV2Enabled, v2StatsigEnabled]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (marketplaceV2Validated && !isV2Enabled && searchParams.get('v1') !== 'true') {
      searchParams.set('v1', 'true');
      navigate(`${location.pathname}?${searchParams.toString()}`, { state: location.state });
    }
  }, [isV2Enabled, location, marketplaceV2Validated, navigate]);

  // Can't add this loader because it breaks the theme provider by overwritting the theme styles
  // To fix it the login form needs to be updated so the dependency with mui core on V2 is removed

  // if (client.loadingStatus === 'Loading') {
  //   return <CircularProgress style={{ position: 'absolute', left: '50%' }} />;
  // }
  return <>{children}</>;
};

type Props = {
  children: React.ReactNode;
};

export const StatsigProvider = ({ children }: Props) => {
  const user: StatsigUser = useMemo(
    () => ({
      userID: 'anonymous',
      email: ''
    }),
    []
  );

  return (
    <SProvider sdkKey={STATSIG_CLIENT_SDK_KEY} options={options} user={user}>
      <StatsigInner>{children}</StatsigInner>
    </SProvider>
  );
};
