import actionTypes, { IndustryModelsState, actionCreatorTypes } from './types';

const initialState: IndustryModelsState = {
  industryModelsError: undefined,
  isIndustryModelsLoading: false,
  industryModels: [],
  isIndustryModelsSelected: false
};

const reducer = (
  state: IndustryModelsState = initialState,
  action: actionCreatorTypes
): IndustryModelsState => {
  switch (action.type) {
    case actionTypes.GET_INDUSTRY_MODELS_PENDING:
      return {
        ...state,
        isIndustryModelsLoading: true
      };
    case actionTypes.GET_INDUSTRY_MODELS_ERROR:
      return {
        ...state,
        isIndustryModelsLoading: false,
        industryModelsError: action.error
      };
    case actionTypes.GET_INDUSTRY_MODELS_SUCCESS:
      return {
        ...state,
        isIndustryModelsLoading: false,
        industryModelsError: undefined,
        industryModels: action.industryModels ?? []
      };
    default:
      return state;
  }
};

export default reducer;
