const actionTypes = {
  SIGNUP_TRIAL: 'marketplace/SignUp/SIGNUP_TRIAL',
  SIGNUP_TRIAL_PENDING: 'marketplace/SignUp/SIGNUP_TRIAL_PENDING',
  SIGNUP_TRIAL_ERROR: 'marketplace/SignUp/SIGNUP_TRIAL_ERROR',
  SIGNUP_TRIAL_SUCCESS: 'marketplace/SignUp/SIGNUP_TRIAL_SUCCESS'
};

export type signupField = {
  email: string;
  password: string;
};
export type SignUpProps = {
  // Add Props type here
};
export type SignUpState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  success: boolean;
  msg: string | null;
};

export type signupTrialAction = (
  email: string,
  password: string,
  hashcode: string
) => {
  type: typeof actionTypes.SIGNUP_TRIAL;
  email: string;
  password: string;
  hashcode: string;
};

export type signupTrialPendingAction = () => {
  type: typeof actionTypes.SIGNUP_TRIAL_PENDING;
};

export type signupTrialErrorAction = (
  msg: string
) => {
  type: typeof actionTypes.SIGNUP_TRIAL_ERROR;
  msg: string;
};

export type signupTrialSuccessAction = (
  msg: string
) => {
  type: typeof actionTypes.SIGNUP_TRIAL_SUCCESS;
  msg: string;
};

export type actionCreatorTypes = {
  type: string;
  msg?: string;
};

export default actionTypes;
