const actionTypes = {
  GET_FUNDAMENTAL_INFO: 'marketplace/TagViewer/GET_FUNDAMENTAL_INFO',
  SET_FUNDAMENTAL_INFO: 'marketplace/TagViewer/SET_FUNDAMENTAL_INFO',
  SET_FUNDAMENTAL_INFO_ERROR: 'marketplace/TagViewer/SET_FUNDAMENTAL_INFO_ERROR',
  SET_FUNDAMENTAL_INFO_PENDING: 'marketplace/TagViewer/SET_FUNDAMENTAL_INFO_PENDING',
  GET_COMPOSITE_FUNDAMENTAL: 'marketplace/TagViewer/GET_COMPOSITE_FUNDAMENTAL',
  SET_COMPOSITE_FUNDAMENTAL: 'marketplace/TagViewer/SET_COMPOSITE_FUNDAMENTAL'
};
export type TagViewerProps = {
  isDocument?: boolean;
};
export type Company = {
  ticker: string;
  name: string;
};
export type Document = {
  reportedDate: string;
  filingType: string;
  documentType: string;
  url: string;
};

export type FundamentalInfo = {
  id?: string;
  type: string;
  period?: string;
  value?: string;
  contextSummary?: string;
  fullSeriesName?: string;
  document: Document;
  company: Company;
  referenceFundamental?: FundamentalInfo;
  restatedFundamentals?: FundamentalInfo[];
};
export type CompositeFundamentalOperatorType = {
  id: string;
  period: string;
  value: string;
  operator: string;
};

export type Result = {
  value: string;
  period: string;
};
export type CompositeFundamentals = {
  attributeName: string;
  operations: CompositeFundamentalOperatorType[];
  result: Result;
};
export type TagViewerState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  fundamentalInfo: FundamentalInfo | null;
  compositeFundamentals: CompositeFundamentals | null;
};

export type getFundamentalInfoAction = (
  fundamentalId: string,
  isDocument?: boolean
) => {
  type: typeof actionTypes.GET_FUNDAMENTAL_INFO;
  fundamentalId: string;
  isDocument: boolean;
};

export type setFundamentalInfoAction = (
  fundamentalInfo: FundamentalInfo
) => {
  type: typeof actionTypes.SET_FUNDAMENTAL_INFO;
  fundamentalInfo: FundamentalInfo;
};

export type setFundamentalInfoPendingAction = () => {
  type: typeof actionTypes.SET_FUNDAMENTAL_INFO_PENDING;
};

export type setFundamentalInfoErrorAction = (
  error: string
) => {
  type: typeof actionTypes.SET_FUNDAMENTAL_INFO_ERROR;
  error: string;
};

export type getCompositeFundamentalAction = (
  fundamentalId: string
) => {
  type: typeof actionTypes.GET_COMPOSITE_FUNDAMENTAL;
  fundamentalId: string;
};

export type setCompositeFundamentalAction = (
  compositeFundamentals: CompositeFundamentals
) => {
  type: typeof actionTypes.SET_FUNDAMENTAL_INFO;
  compositeFundamentals: CompositeFundamentals;
};

export type actionCreatorTypes = {
  type: string;
  error?: string;
  fundamentalInfo?: FundamentalInfo;
  compositeFundamentals?: CompositeFundamentals;
};

export default actionTypes;
