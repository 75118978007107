const actionTypes = {
  TEST1_ACTION: 'marketplace/InquireHerePopup/TEST1_ACTION',
  TEST2_ACTION: 'marketplace/InquireHerePopup/TEST2_ACTION',
  TEST3_ACTION: 'marketplace/InquireHerePopup/TEST3_ACTION',
  TEST4_ACTION: 'marketplace/InquireHerePopup/TEST4_ACTION',
  TEST5_ACTION: 'marketplace/InquireHerePopup/TEST5_ACTION'
};
export type InquireHerePopupProps = {
  // Add Props type here
};
export type InquireHerePopupState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  data: {
    // add data types here
  };
};

export type actionCreatorTypes = {
  type: typeof actionTypes.TEST4_ACTION;
  payload: {
    data?: {
      [key: string]: string;
    };
  };
};

export default actionTypes;
