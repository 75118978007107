import { useDispatch } from 'react-redux';
import { updateNotification } from '../../pages/Models/duck/types';
import useLocal from '../../localization';
import React from 'react';
import modelsActions from '../../pages/Models/duck/actions';
import { Box, Button, ListItem, ListItemText } from '@material-ui/core';
import moment from 'moment';
import useStyles from './style';
import Mixpanel from '../../mixPanel';

interface EarningsUpdateActionNotificationListProps {
  notificationForCompany: updateNotification;
  style: React.CSSProperties;
}

export const EarningsUpdateActionNotificationList = ({
  notificationForCompany,
  style
}: EarningsUpdateActionNotificationListProps) => {
  const preview = useLocal('preview');
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDetails = (event: React.MouseEvent, notificationForCompany: updateNotification) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (!notificationForCompany.seen) {
      dispatch(modelsActions.markCompanyNotificationSeen(notificationForCompany.company.id));
    }
    Mixpanel.track('marketplace:notifications:item:click', {
      item: notificationForCompany
    });
    window.open(`/preview/${notificationForCompany.company.id}`);
  };

  const updatedAt = notificationForCompany.updatedAt
    ? moment(notificationForCompany.updatedAt).format('DD, MMM, YYYY HH:mm A')
    : undefined;

  return (
    <Box className={classes.summaryContainer}>
      <ListItem
        className={notificationForCompany.seen ? classes.seenNotification : undefined}
        style={style}
      >
        <ListItemText
          classes={{
            multiline: classes.heading,
            primary: !notificationForCompany.seen ? classes.unSeenNotificationTitle : undefined
          }}
          primary={`${notificationForCompany.company.ticker} - ${notificationForCompany.company.name} has ${notificationForCompany.notificationCount} updates`}
          secondary={updatedAt}
        />
        <Button
          variant={'text'}
          color={'primary'}
          onClick={(event) => onDetails(event, notificationForCompany)}
        >
          {preview}
        </Button>
      </ListItem>
    </Box>
  );
};
