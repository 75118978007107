import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  position: 'sticky',
  left: 0,
  top: 0,
  right: '14px',
  display: 'flex',
  gap: '32px',
  padding: '20px 0',
  alignItems: 'center',
  width: '100%',
  minHeight: 'fit-content',
  background: '#FFFFFF',
  zIndex: 1010,
  overflowX: 'hidden',

  '& svg': {
    cursor: 'pointer'
  },
  '& a': {
    height: '32px'
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: '32px'
  }
}));

export const InputWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '0 16px 0 24px',
  border: '1px solid #CFD1D1',
  borderRadius: '28px',

  '& svg': {
    minWidth: '24px'
  }
});

export const Input = styled('input')({
  width: '100%',
  padding: '14px 4px',
  fontSize: '16px',
  color: '#000000DE',
  border: 'none',
  outline: 'none',
  background: 'transparent',

  '&::placeholder': {
    fontSize: '14px',
    color: '#49454Fh'
  },

  '&:placeholder-shown': {
    textOverflow: 'ellipsis'
  }
});
