import React from 'react';

export const BellIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3067 28.0001C18.0723 28.4042 17.7358 28.7396 17.331 28.9728C16.9261 29.206 16.4672 29.3287 16 29.3287C15.5328 29.3287 15.0739 29.206 14.669 28.9728C14.2642 28.7396 13.9277 28.4042 13.6933 28.0001M24 10.6667C24 8.54502 23.1571 6.51019 21.6569 5.00989C20.1566 3.5096 18.1217 2.66675 16 2.66675C13.8783 2.66675 11.8434 3.5096 10.3431 5.00989C8.84286 6.51019 8 8.54502 8 10.6667C8 20.0001 4 22.6667 4 22.6667H28C28 22.6667 24 20.0001 24 10.6667Z"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
