import React, { useState, ChangeEvent, useRef } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import useLocal from 'localization';
import SearchField from 'components/SearchField';
import Props from './types';

const LookupField: React.FC<Props> = ({
  isLoading,
  options,
  doSearch,
  deleyTime = 300,
  onSelect,
  throttle
}: Props) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const companySearchLabel = useLocal('company_search_label');
  const [keyword, setKeyword] = useState('');
  const [open, setOpen] = useState(false);

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setKeyword(value);
    if (value.length) {
      onSelect(null);
      if (!open) {
        setOpen(true);
      }
      if (throttle) {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
          doSearch(value);
        }, deleyTime);
      } else {
        doSearch(value);
      }
    }
  };

  const handleSelect = (
    event: React.ChangeEvent<unknown>,
    value: { id: string; name: string; ticker: string }
  ) => {
    setOpen(false);
    onSelect(value.id);
  };

  return (
    <Autocomplete
      open={open || isLoading}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.ticker} - ${option.name}`}
      renderOption={(props) => (
        <Box key={props.id} component="span" data-testid="autocomplete-option-item">
          {props.ticker} - {props.name}
        </Box>
      )}
      options={options}
      loading={isLoading}
      onChange={handleSelect}
      renderInput={(params) => (
        <SearchField
          {...params}
          value={keyword}
          onChange={handleSearchInput}
          fullWidth
          placeholder={companySearchLabel}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
};

export default React.memo(LookupField);
