export const PARAMS = {
  popup: 'popup',
  notificationId: 'notification-id',
  modelId: 'modelId',
  modelName: 'modelName',
  ticker: 'ticker'
};

export const ENUMS = {
  popup: {
    demoPopup: 'demo-popup',
    requestModel: 'request-a-model',
    addNewModel: 'add-new-model',
    apiSettings: 'api-settings',
    excelPlugin: 'excel-plugin',
    downloadModel: 'download-model',
    unsubscribeModel: 'unsubscribe-model'
  }
};
