import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  listText: {
    paddingLeft: '.5em',
    justifyContent: 'space-between'
  },
  selected: {
    fontWeight: 'bold'
  },
  icon: {
    minWidth: 'auto'
  },
  leftArea: {
    display: 'flex',
    alignItems: 'center'
  },
  infoIcon: {
    marginRight: theme.spacing(1)
  },
  availableIndustry: {
    color: theme.palette.warning.dark,
    fontWeight: 500,
    background: theme.palette.warning.light,
    borderColor: theme.palette.warning.light
  }
}));
