import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import useLocal from 'localization';

import Props from './types';

const CompositeFundamental: React.FC<Props> = ({ compositeFundamental }: Props) => {
  return (
    <>
      <Box
        fontWeight="fontWeightBold"
        fontSize={16}
        p={2}
        m={0}
        pl={3}
        bgcolor="headingBG.main"
        data-testid="industry-title"
      >
        {compositeFundamental?.attributeName}
      </Box>
      <TableContainer component={Paper}>
        <Table data-testid="model-table">
          <TableHead>
            <TableRow>
              <TableCell width="80%">{useLocal('src_period')}</TableCell>
              <TableCell width="20%" align="center">
                {useLocal('src_values')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {compositeFundamental?.operations?.map((data, index) => (
              <TableRow key={`${data.period}-${data.value}`}>
                <TableCell>{data.period}</TableCell>
                <TableCell>
                  <Box display="flex" justifyContent="space-evenly">
                    <Box color="error.main" fontWeight="bold">
                      {index !== 0 && data?.operator}
                    </Box>

                    <Box>
                      <Link to={`/src/${data.id}?snippet=true`}>{data.value}</Link>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableRow>
            <TableCell>{compositeFundamental.result.period}</TableCell>
            <TableCell>
              <Box display="flex" justifyContent="space-evenly">
                <Box color="error.main" fontWeight="bold">
                  =
                </Box>
                <Box>{compositeFundamental.result.value}</Box>
              </Box>
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </>
  );
};

export default CompositeFundamental;
