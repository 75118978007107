import { createSelector } from 'reselect';

import * as LoginPageTypes from './types';
import { RootState } from 'store';
const getLoginState = (state: RootState): LoginPageTypes.LoginPageState => state.LoginPageReducer;
const hasError = createSelector(
  [getLoginState],
  (loginState: LoginPageTypes.LoginPageState): boolean => loginState.isError
);
const error = createSelector(
  [getLoginState],
  (loginState: LoginPageTypes.LoginPageState): string | null => loginState.erorr
);
const isLoading = createSelector(
  [getLoginState],
  (loginState: LoginPageTypes.LoginPageState): boolean => loginState.isLoading
);
const getActionQueue = createSelector(
  [getLoginState],
  (loginState: LoginPageTypes.LoginPageState): LoginPageTypes.actionQueueType =>
    loginState.actionQueue
);

const showReSendEmail = createSelector(
  [getLoginState],
  (loginState: LoginPageTypes.LoginPageState): boolean => loginState.showReSendEmail
);

const shouldResetPassword = createSelector(
  [getLoginState],
  (loginState: LoginPageTypes.LoginPageState): boolean => loginState.shouldResetPassword
);

const isUserLoggedIn = createSelector(
  [getLoginState],
  (loginState: LoginPageTypes.LoginPageState): boolean => loginState.isLoggedIn
);
export default {
  hasError,
  error,
  isLoading,
  getActionQueue,
  showReSendEmail,
  isUserLoggedIn,
  shouldResetPassword
};
