import RequestNewModelReducer from './reducer';
import RequestNewModelSelectors from './selector';
import RequestNewModelSaga from './saga';
import * as RequestNewModelTypes from './types';
import actionTypes from './types';
import actions from './actions';

export {
  RequestNewModelSelectors,
  RequestNewModelSaga,
  RequestNewModelTypes,
  actionTypes,
  actions
};

export default RequestNewModelReducer;
