import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import Alert from '@material-ui/lab/Alert';
import useGetParameter from 'components/Popup/hooks/useGetParameter';
import PasswordStrengthBar from 'react-password-strength-bar';

import useLocal from 'localization';
import { routes } from 'main/router';
import Mixpanel from '../../mixPanel';

import { InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import { actions, SignUpSelectors, SignUpTypes } from './duck';
import useStyles from './style';

const signupObjectShape = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string()
    .required('Password is required.')
    .min(8, 'Password needs to have at least 8 characters.')
});

export const SignUp: React.FC<SignUpTypes.SignUpProps> = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const email = useGetParameter('email');
  const channel = useGetParameter('channel');
  const hashcode = useGetParameter('hashcode');

  const msg = useSelector(SignUpSelectors.getMsg);
  const hasError = useSelector(SignUpSelectors.hasError);
  const isLoading = useSelector(SignUpSelectors.isLoading);

  const isSuccess = useSelector(SignUpSelectors.isSuccess);
  const wrongLinkError = useLocal('signup_wrong_link');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const initialValues = {
    email: email ?? '',
    password: ''
  };
  const formikObj = useFormik<SignUpTypes.signupField>({
    initialValues,
    validateOnBlur: true,
    validationSchema: signupObjectShape,
    onSubmit: ({ email, password }) => {
      Mixpanel.track('marketplace:account:signup', {
        email,
        channel
      });
      if (hashcode) {
        dispatch(actions.signupTrial(email, password, hashcode));
      } else {
        dispatch(actions.signupTrialError(wrongLinkError));
      }
    }
  });

  return (
    <form onSubmit={formikObj.handleSubmit}>
      <Dialog maxWidth="xs" fullWidth open={true} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography className={classes.title} id="form-dialog-title">
          <h2>{useLocal('signup')}</h2>
          <IconButton data-testid="api-settings-close-btn" onClick={() => navigate(routes.LOGIN)}>
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        {(hasError || isSuccess) && (
          <Alert severity={hasError ? 'error' : 'success'} data-testid="server-error">
            {msg}
          </Alert>
        )}
        <DialogContent>
          <Box py={2}>
            <Typography color="textSecondary">{useLocal('signup_text')}</Typography>
          </Box>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="email" error={false}>
              {useLocal('signup_email')}
            </InputLabel>
            <OutlinedInput
              id="email"
              inputProps={{ 'data-testid': 'signup-email' }}
              name="email"
              type="text"
              labelWidth={103}
              onChange={formikObj.handleChange}
              value={formikObj.values.email}
              onBlur={formikObj.handleBlur}
              error={Boolean(formikObj.errors.email && formikObj.touched.email)}
            />
            <FormHelperText
              data-testid="email-error"
              error={Boolean(formikObj.errors.email && formikObj.touched.email)}
            >
              {formikObj.touched.email && formikObj.errors.email}
            </FormHelperText>
          </FormControl>

          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="password" error={false}>
              {useLocal('signup_password')}
            </InputLabel>
            <OutlinedInput
              id="password"
              inputProps={{ 'data-testid': 'signup-password' }}
              name="password"
              type={passwordVisible ? '' : 'password'}
              labelWidth={70}
              onChange={formikObj.handleChange}
              value={formikObj.values.password}
              onBlur={formikObj.handleBlur}
              error={Boolean(formikObj.errors.password && formikObj.touched.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText
              data-testid="email-error"
              error={Boolean(formikObj.errors.password && formikObj.touched.password)}
            >
              {formikObj.touched.password && formikObj.errors.password}
            </FormHelperText>
            {formikObj?.values?.password && (
              <PasswordStrengthBar password={formikObj.values.password} />
            )}
          </FormControl>

          <DialogActions className={classes.dialogActions}>
            <Button
              disabled={isLoading}
              onClick={formikObj.submitForm}
              type="submit"
              color="primary"
              data-testid="signup-submit"
              variant="contained"
            >
              {useLocal('signup')}
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default SignUp;
