import { ActionCreatorPayload, ReducerFunction } from 'store/types';
import { GENERAL_REDUCER_ACTIONS, GeneralState, MENU_STATE_KEY } from '../generalTypes';

type Props = GeneralState['menuExpanded'];

export const setMenuState = (menuExpanded: Props): ActionCreatorPayload<GeneralState> => {
  localStorage.setItem(MENU_STATE_KEY, menuExpanded.toString());

  return {
    type: GENERAL_REDUCER_ACTIONS.SET_MENU_STATE,
    value: { menuExpanded }
  };
};

export const setMenuStateReducer: ReducerFunction<GeneralState, Props> = (state, action) => ({
  ...state,
  menuExpanded: !!action.payload
});
