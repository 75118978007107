import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  errorCode: {
    fontSize: '5em',
    fontWeight: 300,
    margin: 0,
    color: theme.palette.primary.main
  },
  errorHeading: {
    fontSize: '30px'
  },
  errorText: {
    paddingBottom: '2em'
  }
}));
