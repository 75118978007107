import { Button, styled, TableBody, TableHead } from '@mui/material';
import { SadFaceIcon } from 'components/Icons';
import { HomePagePlaceholder } from 'presentation/pages/home/components';

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '16px',
  width: '100%',
  minHeight: 'fit-content',

  '& > div:last-of-type': {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center'
  }
}));

export const HeaderLabel = styled('div')(({ theme }) => ({
  minWidth: 'fit-content',
  fontSize: '16px',
  fontWeight: 'bold',

  [theme.breakpoints.up('md')]: {
    fontSize: '20px'
  }
}));
export const ItemCount = styled('div')({
  minWidth: 'fit-content',
  fontSize: '16px',
  color: 'rgba(0, 0, 0, 0.6)',
  marginRight: 'auto'
});

export const TableWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  overflow: 'auto',

  '& table': {
    borderCollapse: 'separate'
  },
  '& .MuiTablePagination-root': {
    display: 'none'
  },

  '& th': {
    [theme.breakpoints.up('sm')]: {
      '&.company-name, &.company-ticker': {
        left: 0,
        zIndex: 1001
      },
      '&.company-ticker': {
        left: 220,
        borderRight: '1px solid',
        borderColor: '#E8E8E8'
      }
    }
  },
  '& td': {
    [theme.breakpoints.up('sm')]: {
      '&.company-name, &.company-ticker': {
        position: 'sticky',
        left: 0,
        zIndex: 999
      },
      '&.company-ticker': {
        left: 220,
        borderRight: '1px solid',
        borderColor: '#E8E8E8'
      }
    }
  }
}));

export const StyledTableHead = styled(TableHead)(() => ({
  background: '#FAFAFA',
  borderTop: 'solid 1px',
  borderColor: '#E8E8E8',

  '&, & th': {
    position: 'sticky',
    top: 0,
    borderTop: 'solid 1px',
    borderColor: '#E8E8E8',
    zIndex: 1000,
    background: '#FAFAFA',

    '&.company-name': {
      width: '220px',
      maxWidth: '220px',
      minWidth: '220px'
    },
    '&.company-ticker': {
      width: '132px',
      maxWidth: '132px',
      minWidth: '132px'
    }
  },

  '& > tr': {
    height: '35px'
  },

  '& th': {
    padding: '4px 16px 0',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}));

export const StyledTableBody = styled(TableBody)(() => ({
  '& > tr': {
    height: '54px',
    maxHeight: '54px',

    '&.loading': {
      opacity: 0.5,
      pointerEvents: 'none',

      '& td:first-of-type::before': {
        content: '""',
        position: 'absolute',
        top: 12,
        left: '50%',
        width: '24px',
        height: '24px',
        zIndex: 2000,
        border: '5px solid #80808047',
        borderBottomColor: 'transparent',
        borderRadius: '50%',
        display: 'inline-block',
        animation: 'rotation 1s linear infinite'
      }
    },

    '@keyframes rotation': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    }
  },

  '& td': {
    padding: '0 16px',
    maxWidth: '170px',
    background: 'white',
    whiteSpace: 'nowrap',

    '&:last-of-type': {
      maxWidth: 'unset',
      paddingRight: '0'
    },
    '&.company-name': {
      width: '220px',
      maxWidth: '220px',
      minWidth: '220px',
      paddingLeft: '8px'
    },
    '&.company-ticker': {
      width: '132px',
      maxWidth: '132px',
      minWidth: '132px',

      '& > div': {
        justifyContent: 'space-between'
      }
    },
    '&.actions div': {
      gap: '10px',
      paddingRight: '8px',

      '& a > button': {
        background: '#FFFFFF',
        width: '30px',

        '&:hover': {
          background: '#F2F2F2'
        }
      }
    },

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      color: '#000000DE',
      fontSize: '14px',

      '& > span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }
    }
  }
}));

export const SubscribeButton = styled(Button)({
  minWidth: 'unset',
  width: 'fit-content'
});

export const CompanyBadge = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  minWidth: '24px',
  height: '24px',
  background: '#CFD1D1',
  borderRadius: '100%',
  textTransform: 'uppercase',
  fontSize: '12px',
  color: 'white',
  marginRight: '8px'
}));

export const PriorityLabel = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '44px',
  height: '16px',
  lineHeight: '10px',
  marginLeft: '4px',
  background: '#EF5350',
  padding: '3px 6px',
  borderRadius: '4px',
  fontSize: '10px',
  color: 'white'
}));

export const SplashText = styled('span')<{ noResults?: boolean; hasFilters?: boolean }>(
  ({ noResults, hasFilters }) => ({
    fontSize: '16px',
    textAlign: 'center',
    maxWidth: '816px',
    margin: hasFilters ? 'auto' : '10px auto',
    color: noResults ? '#00000099' : '#59595C'
  })
);

export const StyledHomePagePlaceholder = styled(HomePagePlaceholder)({
  margin: '0 auto'
});

export const StyledSadFaceIcon = styled(SadFaceIcon)({
  margin: '81px auto 0'
});

export const ActionButton = styled(Button)(() => ({
  gap: '8px',
  minWidth: 'unset',
  width: '115px',
  height: '30px',
  border: '1px solid #E8E8E8',
  borderRadius: '4px',
  background: '#E8E8E8',
  color: '#000000DE',
  fontSize: '12px',
  fontWeight: 'bold',

  '&:hover': {
    background: '#F8F8F8'
  },

  '& svg': {
    minWidth: '18px',
    minHeight: '18px'
  }
}));
