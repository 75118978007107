import { ActionCreatorPayload, ReducerFunction } from 'store/types';
import { GENERAL_REDUCER_ACTIONS, GeneralState } from '../generalTypes';

type Props = GeneralState['menuExpanded'];

export const setV2BannerClosed = (v2BannerClosed = true): ActionCreatorPayload<GeneralState> => {
  return {
    type: GENERAL_REDUCER_ACTIONS.SET_V2_BANNER_CLOSED,
    value: { v2BannerClosed }
  };
};

export const setV2BannerClosedReducer: ReducerFunction<GeneralState, Props> = (state, action) => ({
  ...state,
  v2BannerClosed: !!action.payload
});
