import actionTypes, {
  requestResetPasswordAction,
  requestResetPasswordPendingAction,
  requestResetPasswordSuccessAction,
  requestResetPasswordFailureAction,
  requestResetPasswordErrorAction,
  requestResetPasswordClearAction
} from './types';

const requestResetPassword: requestResetPasswordAction = (email) => ({
  type: actionTypes.REQUES_RESET_PASSWORD,
  email
});

const requestResetPasswordPending: requestResetPasswordPendingAction = () => ({
  type: actionTypes.REQUES_RESET_PASSWORD_PENDING
});

const requestResetPasswordSuccess: requestResetPasswordSuccessAction = () => ({
  type: actionTypes.REQUES_RESET_PASSWORD_SUCCESS
});

const requestResetPasswordFailure: requestResetPasswordFailureAction = () => ({
  type: actionTypes.REQUES_RESET_PASSWORD_FAILURE
});

const requestResetPasswordError: requestResetPasswordErrorAction = (error) => ({
  type: actionTypes.REQUES_RESET_PASSWORD_ERROR,
  error
});

const requestResetPasswordClear: requestResetPasswordClearAction = () => ({
  type: actionTypes.REQUES_RESET_PASSWORD_CLEAR
});

export default {
  requestResetPassword,
  requestResetPasswordPending,
  requestResetPasswordSuccess,
  requestResetPasswordFailure,
  requestResetPasswordError,
  requestResetPasswordClear
};
