import React from 'react';
import { RouteObject } from 'react-router';
import { authRoutes } from './auth-routes';
import { errorRoutes } from './error-routes';
import { openRoutes } from './open-routes';
import { protectedRoutes } from './protected-routes';
import { DefaultLayout, OldLayout } from 'main/layout';

export const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <DefaultLayout />,
    children: [...errorRoutes, ...authRoutes, ...protectedRoutes, ...openRoutes]
  }
];

export const appRoutesV1: RouteObject[] = [
  {
    path: '*',
    element: <OldLayout />
  }
];
