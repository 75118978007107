import ThankyouVerifyEmailReducer from './reducer';
import ThankyouVerifyEmailSelectors from './selector';
import ThankyouVerifyEmailSaga from './saga';
import * as ThankyouVerifyEmailTypes from './types';
import actionTypes from './types';
import actions from './actions';

export {
  ThankyouVerifyEmailSelectors,
  ThankyouVerifyEmailSaga,
  ThankyouVerifyEmailTypes,
  actionTypes,
  actions
};

export default ThankyouVerifyEmailReducer;
