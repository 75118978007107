import { put, takeLatest } from 'redux-saga/effects';
import actionTypes, { getFundamentalInfoAction, getCompositeFundamentalAction } from './types';
import actions from './actions';
import Mixpanel from '../../../mixPanel';
import { gqlClient } from 'infra/http';
import { gql } from '@apollo/client';

export function* getFundamentalInfoSaga({
  fundamentalId,
  isDocument
}: ReturnType<getFundamentalInfoAction>) {
  yield put(actions.setFundamentalInfoPending());
  try {
    const {
      data: { fundamentalInfo }
    } = yield gqlClient.query({
      query: gql`
        query getFundamentalInfo($fundamentalId: Int!, $isDocument: Boolean!) {
          fundamentalInfo(fundamentalId: $fundamentalId, isDocument: $isDocument) {
            id @skip(if: $isDocument)
            type
            period @skip(if: $isDocument)
            value @skip(if: $isDocument)
            contextSummary @skip(if: $isDocument)
            fullSeriesName @skip(if: $isDocument)
            document {
              reportedDate
              filingType
              documentType
              url
            }
            company {
              ticker
              name
            }
            referenceFundamental @skip(if: $isDocument) {
              id
              value
              period
            }
            restatedFundamentals @skip(if: $isDocument) {
              id
              value
              period
            }
          }
        }
      `,
      variables: { fundamentalId: parseInt(fundamentalId), isDocument },
      fetchPolicy: 'cache-first'
    });

    if (fundamentalInfo) {
      Mixpanel.track('marketplace:reader:view', fundamentalInfo?.company);
      if (fundamentalInfo.type === 'COMPOSITE') {
        yield put(actions.getCompositeFundamental(fundamentalId));
      }
      yield put(actions.setFundamentalInfo(fundamentalInfo));
    }
  } catch (error) {
    yield put(actions.setFundamentalInfoError((error as { message: string })?.message));
  }
}

export function* getCompositeFundamentalSaga({
  fundamentalId
}: ReturnType<getCompositeFundamentalAction>) {
  yield put(actions.setFundamentalInfoPending());
  try {
    const {
      data: { compositeFundamentals }
    } = yield gqlClient.query({
      query: gql`
        query getCompositeFundamentals($fundamentalId: Int!) {
          compositeFundamentals(fundamentalId: $fundamentalId) {
            attributeName
            operations {
              value
              period
              operator
              id
            }
            result {
              value
              period
            }
          }
        }
      `,
      variables: { fundamentalId: parseInt(fundamentalId) },
      fetchPolicy: 'cache-first'
    });

    if (compositeFundamentals) {
      yield put(actions.setCompositeFundamental(compositeFundamentals));
    }
  } catch (error) {
    yield put(actions.setFundamentalInfoError((error as { message: string })?.message));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.GET_FUNDAMENTAL_INFO, getFundamentalInfoSaga);
  yield takeLatest(actionTypes.GET_COMPOSITE_FUNDAMENTAL, getCompositeFundamentalSaga);
}
