const actionTypes = {
  SET_MODEL_NEW_REQUES_LOADING: 'marketplace/RequestNewModel/SET_MODEL_NEW_REQUES_LOADING',
  SET_MODEL_NEW_REQUES_ERROR: 'marketplace/RequestNewModel/SET_MODEL_NEW_REQUES_ERROR',
  MODEL_NEW_REQUEST: 'marketplace/RequestNewModel/MODEL_NEW_REQUEST'
};

export type RequestModelProps = {
  isOpened: boolean;
  onClose: () => void;
};

export type RequestNewModelState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  error: string | null;
};

export type requestNewModelAction = (
  ticker: string,
  companyName: string
) => {
  type: typeof actionTypes.MODEL_NEW_REQUEST;
  ticker: string;
  companyName: string;
};

export type setRequestNewModelLoadingAction = () => {
  type: typeof actionTypes.SET_MODEL_NEW_REQUES_LOADING;
};

export type setRequestNewModelErrorAction = (
  error: string
) => {
  type: typeof actionTypes.SET_MODEL_NEW_REQUES_ERROR;
  error: string;
};

export type actionCreatorTypes = {
  type: string;
  error?: string;
};
export default actionTypes;
