import React, { useMemo, useState } from 'react';

import Props from './types';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import notificationSelector from '../../pages/Models/duck/selector';
import notificationActions from '../../pages/Models/duck/actions';
import { Box, Button, CircularProgress, Popover, Typography } from '@material-ui/core';
import { EarningsUpdateActionNotificationList } from './EarningsUpdateActionNotificationList';
import useLocal from '../../localization';
import notificationsSelector from '../../pages/Models/duck/selector';
import Mixpanel from '../../mixPanel';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import Switch from '@mui/material/Switch';
import { updateNotificationToggle, useUserInfo } from 'utils';
import { useToastContext } from 'presentation/contexts';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const EarningsUpdateActionNotifications: React.FC<Props> = ({
  anchorEl,
  open = true,
  onClose
}: Props) => {
  const toast = useToastContext();

  const { data, refetch } = useUserInfo();

  const [isUpdating, setIsUpdating] = useState(false);

  const classes = useStyles();
  const notificationsByCompanyId = useSelector(notificationSelector.earningsUpdateNotifications);
  const isMarkingAllNotificationsLoading = useSelector(
    notificationSelector.isMarkingAllNotificationsLoading
  );
  const earningsUpdateNotificationsUnSeenCount = useSelector(
    notificationsSelector.earningsUpdateNotificationsUnSeenCount
  );
  const title = useLocal('earning_action_notification_modal_title');
  const markAllSeen = useLocal('mark_all_seen');

  const dispatch = useDispatch();

  const { sortedNotifications, companyIds } = useMemo(() => {
    const sortedNotifications = Object.values(notificationsByCompanyId).sort((a, b) =>
      b?.updatedAt.localeCompare(a?.updatedAt)
    );
    const companyIds = Object.values(notificationsByCompanyId).map(
      (notification) => notification.company.id
    );
    return { sortedNotifications, companyIds };
  }, [notificationsByCompanyId]);

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;

    return (
      <EarningsUpdateActionNotificationList
        style={style}
        notificationForCompany={sortedNotifications[index]}
        key={`${index}`}
      />
    );
  };
  const handleChange = () => {
    setIsUpdating(true);
    updateNotificationToggle(!data.receiveModelUpdateNotification)
      .then(() => {
        refetch();
        Mixpanel.track('marketplace:actions:receive_model_notification:end', {
          success: true,
          receiveModelUpdateNotification: !data.receiveModelUpdateNotification
        });
      })
      .catch(() => {
        toast.error('Failed to update notification settings');
        Mixpanel.track('marketplace:actions:receive_model_notification:end', {
          success: false,
          receiveModelUpdateNotification: data.receiveModelUpdateNotification
        });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <Popover
      open={Boolean(anchorEl) && open}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={{ root: classes.popOverRoot }}
    >
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.title}>{title}</Typography>
          {earningsUpdateNotificationsUnSeenCount > 0 && (
            <Button
              variant={'text'}
              color={'primary'}
              onClick={() => {
                dispatch(notificationActions.markAllNotificationsSeen(companyIds));
                Mixpanel.track('marketplace:notifications:mark_all_as_read:click', {
                  companyIds
                });
              }}
            >
              {isMarkingAllNotificationsLoading ? <CircularProgress /> : markAllSeen}
            </Button>
          )}
        </Box>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            {...label}
            disabled={data.isLoading || isUpdating}
            checked={data.receiveModelUpdateNotification}
            onClick={handleChange}
          />
          <Typography className={classes.toggle}>Notify me by email for model updates</Typography>
        </div>
        <FixedSizeList
          height={0.5 * window.innerHeight}
          width={600}
          itemSize={60}
          itemCount={sortedNotifications.length}
        >
          {renderRow}
        </FixedSizeList>

        {sortedNotifications.length === 0 && (
          <Box className={classes.noNotificationsContainer}>
            <Typography className={classes.noNotifications}>{'No Notifications'}</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  );
};

export default EarningsUpdateActionNotifications;
