import { ActionCreatorPayload, ReducerFunction } from 'store/types';
import {
  GENERAL_REDUCER_ACTIONS,
  GENERAL_REDUCER_INITIAL_STATE,
  GeneralState
} from '../generalTypes';

export const generalStateCleared = (): ActionCreatorPayload => ({
  type: GENERAL_REDUCER_ACTIONS.GENERAL_STATE_CLEARED
});

export const generalStateClearedReducer: ReducerFunction<GeneralState, void> = (state) => ({
  ...state,
  ...GENERAL_REDUCER_INITIAL_STATE
});
