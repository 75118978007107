export enum ProductType {
  DALOOPA = 'daloopa',
  DALOOPA_PLUS = 'daloopa_plus',
  DALOOPA_PLUS_LTD = 'daloopa_plus_time_ltd'
}

export enum UserTier {
  TRIAL = 'trial',
  ENTERPRISE = 'enterprise',
  FREE = 'free',
  STUDENT = 'student'
}
