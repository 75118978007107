import { createSelector } from 'reselect';

import { ForgetPasswordState } from './types';
import { RootState } from 'store';

const getForgetPasswordState = (state: RootState): ForgetPasswordState =>
  state.ForgetPasswordReducer;

const hasError = createSelector(
  [getForgetPasswordState],
  (ForgetPasswordState: ForgetPasswordState): boolean => ForgetPasswordState.isError
);
const getError = createSelector(
  [getForgetPasswordState],
  (ForgetPasswordState: ForgetPasswordState): string | null => ForgetPasswordState.error
);

const isLoading = createSelector(
  [getForgetPasswordState],
  (ForgetPasswordState: ForgetPasswordState): boolean => ForgetPasswordState.isLoading
);
const isSuccess = createSelector(
  [getForgetPasswordState],
  (ForgetPasswordState: ForgetPasswordState): boolean => ForgetPasswordState.success
);
const isFailure = createSelector(
  [getForgetPasswordState],
  (ForgetPasswordState: ForgetPasswordState): boolean => ForgetPasswordState.failure
);
export default {
  hasError,
  getError,
  isLoading,
  isSuccess,
  isFailure
};
