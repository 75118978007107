import actionTypes, { ExcelPluginState, actionCreatorTypes } from './types';

const initialState: ExcelPluginState = {
  isLoadingMsi: false,
  isLoadingExe: false,
  isError: false,
  excelVersion: null,
  isSuccessMsi: false,
  isSuccessExe: false,
  error: null
};

const reducer = (
  state: ExcelPluginState = initialState,
  action: actionCreatorTypes
): ExcelPluginState => {
  switch (action.type) {
    case actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN_PENDING:
      return {
        ...state,
        isLoadingMsi: true,
        isSuccessMsi: false,
        isError: false,
        error: null
      };
    case actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN_SUCCESS:
      return {
        ...state,
        isLoadingMsi: false,
        isSuccessMsi: true,
        isError: false,
        error: null
      };

    case actionTypes.GENERATE_DOWNLOAD_MSI_TOKEN_ERROR:
      return {
        ...state,
        isLoadingMsi: false,
        isSuccessMsi: false,
        isError: true,
        error: action?.error ?? null
      };

    case actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN_PENDING:
      return {
        ...state,
        isLoadingExe: true,
        isSuccessExe: false,
        isError: false,
        error: null
      };
    case actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN_SUCCESS:
      return {
        ...state,
        isLoadingExe: false,
        isSuccessExe: true,
        isError: false,
        error: null
      };

    case actionTypes.GENERATE_EXCEL_VERSION_SUCCESS:
      return {
        ...state,
        excelVersion: action?.version ?? '1.3.2',
        isSuccessMsi: false,
        isSuccessExe: false
      };

    case actionTypes.GENERATE_DOWNLOAD_EXE_TOKEN_ERROR:
      return {
        ...state,
        isLoadingExe: false,
        isSuccessExe: false,
        isError: true,
        error: action?.error ?? null
      };

    default:
      return state;
  }
};

export default reducer;
