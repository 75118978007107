import { HttpClient, HttpRequest, HttpResponse, HttpError } from 'application/protocols';
import axios, { AxiosError, AxiosInstance } from 'axios';

export class AxiosHttpClientAdapter implements HttpClient {
  private readonly axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL
    });
  }

  async request({ method, url, body, headers, queryParams }: HttpRequest): Promise<HttpResponse> {
    try {
      const axiosResponse = await this.axiosInstance.request({
        url,
        data: body,
        headers,
        method,
        params: queryParams
      });
      return {
        statusCode: axiosResponse.status,
        body: axiosResponse.data
      };
    } catch (error) {
      const axiosError = error as AxiosError;
      return {
        error: axiosError.response?.data,
        statusCode: axiosError.response?.status,
        message: axiosError.response?.statusText
      } as HttpError;
    }
  }
}
